import BaseModule from "@/store/modules/baseModule";

export default class BaseAsiat extends BaseModule {
  state() {
    return {
      laskutNykyinenSivu: null,
      laskutSeuraavaSivu: 1,
      lataamassaTapauksia: false,
      tapaukset: [],
      laskutMaksutiedot: [],
      toimeksiannotMaksutiedot: [],
      toimeksiannotNykyinenSivu: null,
      toimeksiannotSeuraavaSivu: 1
    };
  }

  /** Gettereihin voi asettaa myös actioneissa kutsuttavia apufunktiota.
   * Määritä tällöin getter palauttamaan funktio, kuten oletustila tässä moduulissa.
   */
  getters() {
    return {
      ...super.getters(),
      oletustila() {
        return function () {
          return {
            laskutNykyinenSivu: null,
            laskutSeuraavaSivu: 1,
            lataamassaTapauksia: false,
            tapaukset: [],
            laskutMaksutiedot: [],
            toimeksiannotMaksutiedot: [],
            toimeksiannotNykyinenSivu: null,
            toimeksiannotSeuraavaSivu: 1
          };
        };
      },
      /** Toteuta perivässä luokassa.
       *
       * @returns URLSearchParams
       */
      laskutUrlParametrit() {
        throw new Error(
          "getter laskutUrlParametrit tulee määritellä perivässä luokassa!"
        );
      },
      toimeksiannotUrlParametrit() {
        throw new Error(
          "getter toimeksiannotUrlParametrit tulee määritellä perivässä luokassa!"
        );
      }
    };
  }

  mutations() {
    return {
      asetaOletustila(state, payload) {
        state = Object.assign(state, payload);
      },
      laskutAsetaNykyinenSivu(state, payload) {
        state.laskutNykyinenSivu = payload;
      },
      laskutAsetaSeuraavaSivu(state, payload) {
        state.laskutSeuraavaSivu = payload;
      },
      lataamassaTapauksia(state, payload) {
        state.lataamassaTapauksia = payload;
      },
      pushTapaukset(state, payload) {
        state.tapaukset.push(...payload);
      },
      toimeksiannotAsetaNykyinenSivu(state, payload) {
        state.toimeksiannotNykyinenSivu = payload;
      },
      toimeksiannotAsetaSeuraavaSivu(state, payload) {
        state.toimeksiannotSeuraavaSivu = payload;
      }
    };
  }

  actions() {
    return {
      async asetaOletustila({ commit, getters }) {
        commit("asetaOletustila", getters.oletustila());
      },
      async haeTapauksia(
        { commit, dispatch, state },
        suodatus = ["laskut", "toimeksiannot"]
      ) {
        try {
          if (state.lataamassaTapauksia) return;

          commit("lataamassaTapauksia", true);

          if (suodatus.includes("toimeksiannot"))
            await dispatch("haeToimeksiantoja");
          // Haetaan laskut vasta sen jälkeen kun toimeksiannot on haettu, ellei nimenomaan haeta vain laskuja
          if (
            !state.toimeksiannotSeuraavaSivu ||
            !suodatus.includes("toimeksiannot")
          ) {
            if (suodatus.includes("laskut")) await dispatch("haeLaskuja");
          }
        } catch (error) {
          this._vm.$sentryLog(error);
        } finally {
          commit("lataamassaTapauksia", false);
        }
      },
      async haeLaskuja({ commit, getters, state }) {
        if (!state.laskutSeuraavaSivu) return;
        commit("laskutAsetaNykyinenSivu", state.laskutSeuraavaSivu);

        const { data } = await this._vm.$api.withAuth.request({
          method: "GET",
          url: getters.maaritaUrl("laskut/"),
          params: getters.laskutUrlParametrit
        });

        if (data.next) {
          commit("laskutAsetaSeuraavaSivu", state.laskutNykyinenSivu + 1);
        } else {
          commit("laskutAsetaSeuraavaSivu", null);
        }
        commit("pushTapaukset", data.results);
      },
      async haeToimeksiantoja({ commit, getters, state }) {
        if (!state.toimeksiannotSeuraavaSivu) return;
        commit(
          "toimeksiannotAsetaNykyinenSivu",
          state.toimeksiannotSeuraavaSivu
        );

        const { data } = await this._vm.$api.withAuth.request({
          method: "GET",
          url: getters.maaritaUrl("toimeksiannot/"),
          params: getters.toimeksiannotUrlParametrit
        });

        if (data.next) {
          commit(
            "toimeksiannotAsetaSeuraavaSivu",
            state.toimeksiannotNykyinenSivu + 1
          );
        } else {
          commit("toimeksiannotAsetaSeuraavaSivu", null);
        }

        commit("pushTapaukset", data.results);
      },
      async haeKirje({ getters }, id) {
        return await this._vm.$api.withAuth.request({
          method: "GET",
          responseType: "arraybuffer",
          url: getters.maaritaUrl(`kirje/${id}/`)
        });
      },
      async haeLaskuliite({ getters }, id) {
        return await this._vm.$api.withAuth.request({
          method: "GET",
          responseType: "arraybuffer",
          url: getters.maaritaUrl(`laskuliite/${id}/`)
        });
      },
      async haeToimeksiantoLaskuliite({ getters }, id) {
        return await this._vm.$api.withAuth.request({
          method: "GET",
          responseType: "arraybuffer",
          url: getters.maaritaUrl(`toimeksianto/laskuliite/${id}/`)
        });
      }
    };
  }
}
