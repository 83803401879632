var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yhteenveto" },
    [
      _c(
        "ViewLayout",
        { ref: "viewLayout" },
        [
          _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "text-center pt-0 mt-4" },
                [
                  _vm.envMaaritysYhteenvetoText.length
                    ? _vm._l(
                        _vm.envMaaritysYhteenvetoText,
                        function (kappale, index) {
                          return _c(
                            "p",
                            {
                              key: kappale,
                              staticClass: "text-body-1",
                              class: index === 0 ? "font-weight-bold" : "",
                            },
                            [_vm._v(" " + _vm._s(kappale) + " ")]
                          )
                        }
                      )
                    : _vm._e(),
                  !_vm.lataamassaDataa
                    ? [
                        _c("v-divider", { staticClass: "my-8" }),
                        _c("h4", [_vm._v("Velkasi tällä hetkellä")]),
                        _vm.$store.getters["yhteenveto/eiAvoimiaVelkoja"]
                          ? _c("p", { staticClass: "mt-4" }, [
                              _vm._v(
                                " Sinulla ei ole avoimia velkoja tällä hetkellä. "
                              ),
                            ])
                          : !_vm.$vuetify.breakpoint.smAndDown &&
                            _vm.envMaaritysInstanceClass !== "creditvisor"
                          ? _c("v-simple-table", {
                              staticClass: "yhteenveto__table",
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function () {
                                      return [
                                        _c("thead", [
                                          _c("tr", [
                                            _c("th"),
                                            _c(
                                              "th",
                                              { staticClass: "text-center" },
                                              [_vm._v("Kpl")]
                                            ),
                                            _c(
                                              "th",
                                              { staticClass: "text-center" },
                                              [_vm._v("Summa")]
                                            ),
                                            _c("th"),
                                          ]),
                                        ]),
                                        _c("tbody", [
                                          _c("tr", [
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "font-weight-medium text-left",
                                              },
                                              [_vm._v("Avoimet laskut")]
                                            ),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.yhteenveto.laskut_kpl
                                                )
                                              ),
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$n(
                                                    _vm.yhteenveto
                                                      .laskut_avoinna,
                                                    "currency"
                                                  )
                                                )
                                              ),
                                            ]),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "yhteenveto__table__btn",
                                                    attrs: {
                                                      color: "primary",
                                                      rounded: "",
                                                      small: "",
                                                      to: {
                                                        name: "Avoimet asiat",
                                                        params: {
                                                          suodatus: ["laskut"],
                                                        },
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " Katso avoimet laskut "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c("tr", [
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "font-weight-medium text-left",
                                              },
                                              [
                                                _vm._v(
                                                  " Avoimet muistutukset ja perintätoimeksiannot "
                                                ),
                                              ]
                                            ),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.yhteenveto
                                                    .toimeksiannot_kpl
                                                )
                                              ),
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$n(
                                                      _vm.yhteenveto
                                                        .toimeksiannot_avoinna,
                                                      "currency"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "yhteenveto__table__btn",
                                                    attrs: {
                                                      color: "primary",
                                                      rounded: "",
                                                      small: "",
                                                      to: {
                                                        name: "Avoimet asiat",
                                                        params: {
                                                          suodatus: [
                                                            "toimeksiannot",
                                                          ],
                                                        },
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " Katso toimeksiannot "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c("tr", [
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "font-weight-medium text-left",
                                              },
                                              [_vm._v(" Maksettavaa yhteensä ")]
                                            ),
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "font-weight-medium",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.yhteenveto
                                                        .yhteensa_kpl
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "font-weight-medium",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$n(
                                                        _vm.yhteenveto
                                                          .yhteensa_avoinna,
                                                        "currency"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "yhteenveto__table__btn",
                                                    attrs: {
                                                      color: "primary",
                                                      rounded: "",
                                                      small: "",
                                                      to: "avoimet",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " Katso kaikki avoimet "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ]),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                3703558836
                              ),
                            })
                          : [
                              _c(
                                "div",
                                { staticClass: "mt-8" },
                                [
                                  _vm.envMaaritysInstanceClass !== "creditvisor"
                                    ? [
                                        _c(
                                          "h4",
                                          { staticClass: "font-weight-medium" },
                                          [_vm._v("Avoimet laskut")]
                                        ),
                                        _c("p", { staticClass: "mt-2 mb-2" }, [
                                          _vm._v(
                                            _vm._s(_vm.yhteenveto.laskut_kpl) +
                                              " kpl"
                                          ),
                                        ]),
                                        _c("p", { staticClass: "mt-0" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$n(
                                                  _vm.yhteenveto.laskut_avoinna,
                                                  "currency"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "mt-2",
                                            attrs: {
                                              color: "primary",
                                              rounded: "",
                                              to: "avoimet",
                                            },
                                          },
                                          [_vm._v(" Katso avoimet laskut ")]
                                        ),
                                        _c("v-divider", {
                                          staticClass: "my-4",
                                        }),
                                      ]
                                    : _vm._e(),
                                  _c(
                                    "h4",
                                    { staticClass: "font-weight-medium" },
                                    [
                                      _vm._v(
                                        " Avoimet muistutukset ja perintätoimeksiannot "
                                      ),
                                    ]
                                  ),
                                  _c("p", { staticClass: "mt-2 mb-2" }, [
                                    _vm._v(
                                      _vm._s(_vm.yhteenveto.toimeksiannot_kpl) +
                                        " kpl"
                                    ),
                                  ]),
                                  _c("p", { staticClass: "mt-0" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$n(
                                            _vm.yhteenveto
                                              .toimeksiannot_avoinna,
                                            "currency"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mt-2",
                                      attrs: {
                                        color: "primary",
                                        rounded: "",
                                        to: "avoimet",
                                      },
                                    },
                                    [_vm._v(" Katso toimeksiannot ")]
                                  ),
                                  _vm.envMaaritysInstanceClass !== "creditvisor"
                                    ? [
                                        _c("v-divider", {
                                          staticClass: "my-4",
                                        }),
                                        _c("h4", [
                                          _vm._v("Maksettavaa yhteensä"),
                                        ]),
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "mt-2 mb-2 font-weight-medium",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.yhteenveto.yhteensa_kpl
                                                ) +
                                                " kpl "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "mt-0 font-weight-medium",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$n(
                                                    _vm.yhteenveto
                                                      .yhteensa_avoinna,
                                                    "currency"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "mt-2",
                                            attrs: {
                                              color: "primary",
                                              rounded: "",
                                              to: "avoimet",
                                            },
                                          },
                                          [_vm._v(" Katso kaikki avoimet ")]
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ],
                        !_vm.$store.getters["yhteenveto/eiAvoimiaVelkoja"]
                          ? [
                              _c("v-divider", { staticClass: "my-8" }),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mb-4",
                                  attrs: { justify: "center" },
                                },
                                [
                                  _c("PaytrailLomake", {
                                    attrs: {
                                      "lasku-idt":
                                        _vm.laskutMaksutiedotLomakkeelle
                                          .laskuIdt,
                                      "toimeksianto-idt":
                                        _vm.toimeksiannotMaksutiedotLomakkeelle
                                          .toimeksiantoIdt,
                                      avoinna: Number(
                                        _vm.laskutMaksutiedotLomakkeelle
                                          .avoinna +
                                          _vm
                                            .toimeksiannotMaksutiedotLomakkeelle
                                            .avoinna
                                      ),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                      ]
                    : _vm.lataamassaDataa
                    ? [
                        _c(
                          "p",
                          {
                            staticClass:
                              "mt-8 text-body-1 text-center app-animoitu-gradientti",
                          },
                          [_vm._v(" Haetaan tietoja... ")]
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }