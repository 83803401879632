import store from "@/store";

export const tunnistaudu = () => {
  if (process.env.VUE_APP_INSTANCE_CLASS === "ijcapital") {
    alert("Tunnistuspalvelua ei ole vielä käytettävissä");
    return;
  }

  // Tyhjennetään kirjetiedot siltä varalta, että käyttäjä on tullut sisään kirjeellä ennen tunnistautumista.
  store.dispatch("kirjauduKirjeella/tyhjennaKirjetiedot");

  const url = process.env.VUE_APP_LOGIN_URL;
  const next =
    process.env.VUE_APP_ENV === "development"
      ? encodeURI("?next=/portaali/redirect/localhost/8080/")
      : encodeURI("?next=/portaali/redirect/");
  window.location = url + next;
};
