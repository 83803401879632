<template>
  <v-form ref="omatTiedotLomake" v-model="valid" @submit.prevent="submit">
    <v-row class="my-2 mx-0 app-color--grey">
      <div class="mb-4">
        {{ kayttajaNimi }}
      </div>
      <v-text-field
        v-model="omatTiedotLomakeData.puhelin.numero"
        label="Puhelin"
        :rules="[$validointiSaannot.puhelinnumero]"
        required="false" />
      <v-text-field
        v-model="omatTiedotLomakeData.emailosoite.email"
        label="Sähköpostiosoite"
        :rules="[$validointiSaannot.pakollinen, $validointiSaannot.email]" />
    </v-row>
    <v-row class="ma-0 mt-0 py-4" justify="space-between">
      <v-btn
        color="secondary"
        rounded
        :loading="lataamassa"
        @click="$emit('sulje')">
        Peruuta
      </v-btn>
      <v-btn
        type="submit"
        color="primary"
        rounded
        :disabled="!valid || lataamassa"
        :loading="lataamassa">
        Tallenna
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>
import LomakeMixin from "@/mixins/LomakeMixin";
import { mapState } from "vuex";

export default {
  name: "OmatTiedotLomake",
  mixins: [LomakeMixin],
  computed: {
    ...mapState({
      kayttajaNimi: (state) => state.yleinen.tunnistettuKayttajaNimi,
      velallistiedot: (state) => state.yleinen.velallistiedot,
      omatTiedotLomakeData: (state) => state.yleinen.omatTiedotLomakeData
    })
  },
  watch: {
    auki: {
      immediate: true,
      handler(val) {
        if (this.velallistiedot.id) {
          this.omatTiedotLomakeData.puhelin = Object.assign(
            {},
            this.velallistiedot.puhelin
          );
          this.omatTiedotLomakeData.emailosoite = Object.assign(
            {},
            this.velallistiedot.emailosoite
          );
          this.$nextTick(() => {
            if (this.$refs.omatTiedotLomake)
              this.$refs.omatTiedotLomake.validate();
          });
        }
      }
    }
  },
  methods: {
    async submit() {
      try {
        this.lataamassa = true;
        await this.$store.dispatch("yleinen/paivitaVelallistiedot");
        this.$emit("sulje");
        this.$naytaOnnistumisilmoitus("Omat tiedot päivitetty.");
      } catch (error) {
        this.$sentryLog(error);
      } finally {
        this.lataamassa = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
