<template>
  <v-form v-model="valid" @submit.prevent="submit">
    <p class="font-weight-medium text-left">
      Liikasuorituksen palautuksesta veloitamme käsittelykuluina
      {{ $n(lomakeData.liikasuoritus.kulu, "currency") }}
    </p>
    <LiikasuoritusTiliLomake
      :lomake-data="lomakeData"
      :lataamassa="lataamassa"
      tapaustyyppi="tapaustyyppi" />
    <v-btn
      :disabled="!valid || lataamassa"
      :loading="lataamassa"
      color="primary"
      rounded
      large
      @click="lahetaLiikasuoritus">
      Lähetä tiedot
    </v-btn>
  </v-form>
</template>

<script>
import LomakeMixin from "@/mixins/LomakeMixin";
import LiikasuoritusTiliLomake from "@/components/LiikasuoritusTiliLomake";
import { mapState } from "vuex";

export default {
  name: "LiikasuoritusLomake",
  components: {
    LiikasuoritusTiliLomake
  },
  mixins: [LomakeMixin],
  data() {
    return {
      bic: ""
    };
  },
  computed: {
    ...mapState(["yleinen", "liikasuoritukset"])
  },
  async mounted() {
    this.lomakeData.tilinOmistajanNimi =
      this.yleinen.velallistiedot?.nimi || "";
    this.liikasuoritukset.bic = this.lomakeData.bic;
    this.$store.commit("liikasuoritukset/setBic", this.liikasuoritukset.bic);
  },
  methods: {
    lahetaLiikasuoritus() {
      try {
        this.$emit("lahetaLiikasuoritus");
      } catch (e) {
        this.$oletusVirheenkasittely(e);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.v-tooltip__content {
  opacity: 1 !important;
}
</style>
