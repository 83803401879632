var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "formToimeksiantoMaksusopimusLomake",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pl-4 pt-2 pr-0", attrs: { cols: "8" } },
            [
              _c("v-text-field", {
                attrs: {
                  type: "number",
                  label: "Eräpäivä",
                  prefix: "Kuukauden",
                  suffix: ". päivä",
                  outlined: "",
                  rules: [
                    _vm.$validointiSaannot.numero,
                    _vm.$validointiSaannot.suurempiTaiYhtaIsoKuin(
                      _vm.lomakeData.erapaiva,
                      1
                    ),
                    _vm.$validointiSaannot.pienempiTaiYhtaIsoKuin(
                      _vm.lomakeData.erapaiva,
                      31
                    ),
                    _vm.validoiYritysVelallinen,
                  ],
                },
                model: {
                  value: _vm.lomakeData.erapaiva,
                  callback: function ($$v) {
                    _vm.$set(_vm.lomakeData, "erapaiva", $$v)
                  },
                  expression: "lomakeData.erapaiva",
                },
              }),
              _c("v-text-field", {
                attrs: {
                  type: "number",
                  label: "Maksuerien lukumäärä",
                  outlined: "",
                  rules: [
                    _vm.$validointiSaannot.suurempiTaiYhtaIsoKuin(
                      _vm.lomakeData.erat,
                      2
                    ),
                    _vm.$validointiSaannot.pienempiTaiYhtaIsoKuin(
                      _vm.lomakeData.erat,
                      6
                    ),
                  ],
                },
                model: {
                  value: _vm.lomakeData.erat,
                  callback: function ($$v) {
                    _vm.$set(_vm.lomakeData, "erat", $$v)
                  },
                  expression: "lomakeData.erat",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pl-1 pt-2", attrs: { cols: "2" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mt-n2",
                  attrs: { icon: "" },
                  on: { click: _vm.erapaivaKasvata },
                },
                [
                  _c("v-icon", { attrs: { color: "primary" } }, [
                    _vm._v("add_circle"),
                  ]),
                ],
                1
              ),
              _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.erapaivaVahenna } },
                [
                  _c("v-icon", { attrs: { color: "primary" } }, [
                    _vm._v("remove_circle"),
                  ]),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "btn--custom-margin",
                  attrs: { icon: "" },
                  on: { click: _vm.eratKasvata },
                },
                [
                  _c("v-icon", { attrs: { color: "primary" } }, [
                    _vm._v("add_circle"),
                  ]),
                ],
                1
              ),
              _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.eratVahenna } },
                [
                  _c("v-icon", { attrs: { color: "primary" } }, [
                    _vm._v("remove_circle"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mx-0 my-2" },
        [
          _c("ToimeksiantoMaksusopimusEraTaulukko", {
            attrs: { luonnos: true, maksuerat: _vm.maksueralaskelma },
          }),
        ],
        1
      ),
      _vm.kulu !== null && _vm.kulu !== undefined
        ? _c("v-row", { staticClass: "mx-0 mt-4 mb-2" }, [
            _c("p", { staticClass: "text-body-1 text-center" }, [
              _vm._v(
                " Maksueriin sisältyvät kulu " +
                  _vm._s(_vm.$n(Number(_vm.kulu), "currency")) +
                  " sekä viivästyskorot. "
              ),
            ]),
          ])
        : _vm._e(),
      _c(
        "v-row",
        { staticClass: "ma-0 mt-0 py-4", attrs: { justify: "space-between" } },
        [
          _c(
            "v-btn",
            {
              attrs: {
                color: "secondary",
                rounded: "",
                loading: _vm.lataamassa,
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("sulje")
                },
              },
            },
            [_vm._v(" Peruuta ")]
          ),
          _c(
            "v-btn",
            {
              attrs: {
                type: "submit",
                color: "primary",
                rounded: "",
                disabled: !_vm.valid || _vm.lataamassa,
                loading: _vm.lataamassa,
              },
            },
            [_vm._v(" Hyväksy ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }