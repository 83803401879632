export default {
  data() {
    return {
      menuContextNakyvissa: false,
      menuContextNakyvissa2: false,
      menuContextX: 0,
      menuContextY: 0,
      menuContextVaihtoehdot: []
    };
  },
  methods: {
    avaaMenuContext(event) {
      this.menuContextX = event.clientX;
      this.menuContextY = event.clientY;
      this.menuContextNakyvissa = true;
    },
    avaaMenuContext2(event) {
      this.menuContextX = event.clientX;
      this.menuContextY = event.clientY;
      this.menuContextNakyvissa2 = true;
    },
    suljeMenuContext() {
      this.menuContextNakyvissa = false;
      this.menuContextNakyvissa2 = false;
    }
  }
};
