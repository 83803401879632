var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    {
      staticClass: "justify-space-between",
      class: _vm.classNames,
      attrs: {
        color: "primary",
        disabled: _vm.disabled,
        href: _vm.href,
        target: _vm.target,
        rounded: "",
      },
      on: {
        click: function ($event) {
          return _vm.$emit("click", $event)
        },
      },
    },
    [
      _c("span", [_vm._v(_vm._s(_vm.teksti))]),
      _c("v-icon", { staticClass: "ml-2" }, [
        _vm._v(" " + _vm._s(_vm.ikoni) + " "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }