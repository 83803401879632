<template>
  <v-app>
    <NavBar v-if="!piilotaNavBar" />
    <SivupalkkiNav />
    <v-main>
      <v-container
        class="app-min-height-100-pct app-bg--background pa-0 main-container"
        fluid>
        <router-view class="view-base"></router-view>
        <BusinessFooter />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { BusinessFooter, NavBar, SivupalkkiNav } from "@/components";

export default {
  name: "App",
  components: {
    BusinessFooter,
    NavBar,
    SivupalkkiNav
  },
  data: () => ({
    //
  }),
  computed: {
    piilotaNavBar() {
      return typeof this.$route.meta.piilotaNavBar !== "undefined"
        ? this.$route.meta.piilotaNavBar
        : false;
    }
  },
  created() {
    this.alustaCssVhArvonAsettaminen();
  },
  methods: {
    alustaCssVhArvonAsettaminen() {
      // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
      this.asetaCssVhArvoIkkunanKorkeudenPerusteella();

      window.addEventListener("resize", () => {
        this.asetaCssVhArvoIkkunanKorkeudenPerusteella();
      });
    },
    asetaCssVhArvoIkkunanKorkeudenPerusteella() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/styles.scss";

.main-container {
  display: flex;
  flex-direction: column;
}

.view-base {
  flex-grow: 1;
}
</style>
