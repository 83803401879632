<template>
  <v-form v-model="valid" @submit.prevent="submit">
    <v-radio-group
      v-model="lomakeData.paivat"
      class="mt-0"
      label="Siirron pituus">
      <v-radio
        v-for="vaihtoehto in siirtoVaihtoehdot"
        :key="vaihtoehto.index"
        :label="vaihtoehto.selite"
        :value="vaihtoehto.arvo" />
    </v-radio-group>
    <v-row v-if="laskemassaKulua" class="my-2 mx-0 app-color--grey">
      <p class="app-animoitu-gradientti">Lasketaan kulua...</p>
    </v-row>
    <v-row
      v-if="!laskemassaKulua && kuluteksti"
      class="my-2 mx-0 app-color--grey">
      <p>{{ kuluteksti }}</p>
    </v-row>
    <v-row class="ma-0 mt-0 py-4" justify="end">
      <v-btn
        type="submit"
        color="primary"
        rounded
        :disabled="!valid || lataamassa"
        :loading="lataamassa">
        Hyväksy
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>
import LomakeMixin from "@/mixins/LomakeMixin";

export default {
  name: "ErapaivanSiirtoLomake",
  mixins: [LomakeMixin],
  props: {
    tapaustyyppi: {
      type: String,
      required: true,
      validator: (tapaustyyppi) => {
        return ["laskut", "toimeksiannot"].includes(tapaustyyppi);
      }
    }
  },
  data() {
    return {
      kulu: null,
      laskemassaKulua: false,
      siirtoVaihtoehdot: [
        { arvo: 8, selite: "1 viikko" }, // Yritykselle kulun syntyminen vaatii yli 7 päivää
        { arvo: 15, selite: "2 viikkoa" } // Henkilövelallisella kulun syntyminen vaatii yli 14 päivää
      ]
    };
  },
  computed: {
    kuluteksti() {
      let kuluteksti;
      if (this.kulu === null || typeof this.kulu === "object") {
        kuluteksti = "";
      } else if (!this.kulu) {
        kuluteksti = "Eräpäivän siirto ei aiheuta kulua.";
      } else {
        kuluteksti = `Eräpäivän siirrosta aiheutuu kulu: ${this.$n(
          this.kulu,
          "currency"
        )}`;
      }
      return kuluteksti;
    }
  },
  watch: {
    "lomakeData.paivat": {
      handler() {
        this.laskeKulu();
      }
    }
  },
  methods: {
    async laskeKulu() {
      try {
        this.laskemassaKulua = true;
        const kuluKyselyAction = `avoimetAsiat/${this.tapaustyyppi}LaskeErapaivanSiirtopyyntoKulu`;
        const { data } = await this.$store.dispatch(
          kuluKyselyAction,
          this.lomakeData
        );
        this.kulu = data;
      } catch (e) {
        this.$oletusVirheenkasittely(e);
      } finally {
        this.laskemassaKulua = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
