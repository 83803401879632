<template>
  <div class="avoimet-asiat">
    <Tapauslistaus vuex-moduuli="avoimetAsiat" />
  </div>
</template>

<script>
import { Tapauslistaus } from "@/components";
import { mapState } from "vuex";

export default {
  name: "AvoimetAsiat",
  components: {
    Tapauslistaus
  },
  computed: {
    ...mapState(["yleinen"])
  },
  async mounted() {
    this.$store.dispatch("yleinen/chatBotIlmoitus");
    await this.haeLiikasuoritusYhteenveto();
  },
  methods: {
    async haeLiikasuoritusYhteenveto() {
      try {
        await this.$store.dispatch("yleinen/haeLiikasuoritusYhteenveto");
      } catch (e) {
        this.$oletusVirheenkasittely(
          e,
          "Tietojen haku epäonnistui. Yritä hetken kuluttua uudelleen."
        );
      }
    },
    kasitteleLiikasuorituksiinHyppays() {
      this.$store.dispatch(
        "yleinen/kasitteleLiikasuorituksiinHyppays",
        this.$router
      );
    }
  }
};
</script>
