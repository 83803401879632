<template>
  <v-tooltip top>
    <template #activator="{ on }">
      <div v-on="on">
        <v-btn
          color="primary"
          :disabled="disabled"
          rounded
          class="justify-space-between"
          :class="
            $vuetify.breakpoint.xs
              ? 'tapaus-kortti__btn--xs'
              : 'tapaus-kortti__btn--sm-and-up'
          "
          @click="$emit('click', $event)">
          <span>{{ teksti }}</span>
          <v-icon class="ml-2">
            {{ ikoni }}
          </v-icon>
        </v-btn>
      </div>
    </template>
    <template v-if="tooltipTeksti" #default>
      {{ tooltipTeksti }}
    </template>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    ikoni: {
      type: String,
      required: true
    },
    teksti: {
      type: String,
      required: true
    },
    tooltipTeksti: {
      type: String,
      required: false,
      default: ""
    }
  }
};
</script>

<style></style>
