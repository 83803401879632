var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "lataa-lisaa-scroll-btn" },
    [
      _c(
        "p",
        {
          staticClass:
            "text-body-1 text-center mb-0 lataa-lisaa-scroll-btn__teksti",
          on: { click: _vm.ilmoitaPainalluksesta },
        },
        [_vm._v(" Lataa lisää ")]
      ),
      _c(
        "v-btn",
        {
          staticClass: "lataa-lisaa-scroll-btn__btn",
          attrs: { text: "" },
          on: { click: _vm.ilmoitaPainalluksesta },
        },
        [
          _c("v-icon", { attrs: { large: "", color: "primary" } }, [
            _vm._v("expand_more"),
          ]),
        ],
        1
      ),
      _c("div", {
        directives: [
          {
            name: "observe-visibility",
            rawName: "v-observe-visibility",
            value: _vm.ilmoitaNakyvyydesta,
            expression: "ilmoitaNakyvyydesta",
          },
        ],
        staticClass: "pt-1",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }