var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c("p", { staticClass: "font-weight-medium text-left" }, [
        _vm._v(
          " Vaihtoehto 1: Voimme kohdistaa liikasuorituksenne " +
            _vm._s(
              _vm.$n(_vm.lomakeData.liikasuoritus.liikasuoritus, "currency")
            ) +
            " avoimelle saatavallenne " +
            _vm._s(_vm.lomakeData.liikasuoritus.kohdeasia_id) +
            ". Kohdistamisesta ei aiheudu teille kuluja. Annan suostumukseni liikasuorituksen kohdistamiseksi saatavallenne " +
            _vm._s(_vm.lomakeData.liikasuoritus.kohdeasia_id) +
            ". "
        ),
      ]),
      _c(
        "v-btn",
        {
          attrs: {
            disabled: _vm.lataamassa,
            loading: _vm.lataamassa,
            color: "primary",
            rounded: "",
            large: "",
          },
          on: { click: _vm.lahetaKohdistusLiikasuoritus },
        },
        [_vm._v(" Hyväksy ")]
      ),
      _c("p", { staticClass: "font-weight-medium text-left p-top-space" }, [
        _vm._v(
          " Vaihtoehto 2: Liikasuorituksen palautuksesta veloitamme käsittelykuluina " +
            _vm._s(_vm.$n(_vm.lomakeData.liikasuoritus.kulu, "currency")) +
            " "
        ),
      ]),
      _c("LiikasuoritusTiliLomake", {
        attrs: {
          "lomake-data": _vm.lomakeData,
          lataamassa: _vm.lataamassa,
          tapaustyyppi: "tapaustyyppi",
        },
      }),
      _c(
        "v-btn",
        {
          attrs: {
            disabled: !_vm.valid || _vm.lataamassa,
            loading: _vm.lataamassa,
            color: "primary",
            rounded: "",
            large: "",
          },
          on: { click: _vm.lahetaLiikasuoritus },
        },
        [_vm._v(" Lähetä tiedot ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }