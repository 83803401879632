<template>
  <div>
    <v-row>
      <v-col class="font-weight-medium text-left">Liikasuoritus</v-col>
      <v-col>
        {{ $n(lomakeData.liikasuoritus.liikasuoritus, "currency") }}
      </v-col>
    </v-row>
    <v-row>
      <v-col class="font-weight-medium text-left">Käsittelymaksu</v-col>
      <v-col>{{ $n(-lomakeData.liikasuoritus.kulu, "currency") }}</v-col>
    </v-row>
    <v-row>
      <v-col>
        veroton {{ $n(lomakeData.liikasuoritus.veroton, "currency") }} alv 24 %
        {{ $n(lomakeData.liikasuoritus.vero, "currency") }}
      </v-col>
    </v-row>
    <v-row>
      <v-col class="font-weight-medium text-left">Palautettava summa</v-col>
      <v-col>
        {{
          $n(
            lomakeData.liikasuoritus.liikasuoritus -
              lomakeData.liikasuoritus.kulu,
            "currency"
          )
        }}
      </v-col>
    </v-row>
    <v-divider class="mx-4 my-2" />
    <v-row v-if="lomakeData.toimeksianto">
      <v-col class="font-weight-medium text-left">Asianumero</v-col>
      <v-col class="font-weight-medium text-rigth">
        {{ lomakeData.toimeksianto.id }}
      </v-col>
    </v-row>
    <v-row v-if="lomakeData.lasku">
      <v-col class="font-weight-medium text-left">Laskunumero</v-col>
      <v-col class="font-weight-medium text-rigth">
        {{ lomakeData.lasku.nro }}
      </v-col>
    </v-row>
    <v-text-field
      v-model="lomakeData.tilinumero"
      label="IBAN tilinumero"
      :rules="[$validointiSaannot.pakollinen, $validointiSaannot.isValidIBAN]"
      @change="selvitaIBANistaBIC" />
    <v-text-field
      v-model="liikasuoritukset.bic"
      label="BIC pankkitunnus"
      :rules="[$validointiSaannot.pakollinen, $validointiSaannot.isValidBIC]"
      @change="paivitaBIC" />
    <v-text-field
      v-model="lomakeData.tilinOmistajanNimi"
      label="Tilinomistajan nimi"
      :rules="[$validointiSaannot.pakollinen]" />
  </div>
</template>

<script>
import LomakeMixin from "@/mixins/LomakeMixin";
import { mapState } from "vuex";

export default {
  name: "LiikasuoritusTiliLomake",
  mixins: [LomakeMixin],
  data() {
    return {
      bic: ""
    };
  },
  computed: {
    ...mapState(["liikasuoritukset"])
  },
  methods: {
    async selvitaIBANistaBIC() {
      try {
        if (
          this.lomakeData.tilinumero &&
          this.lomakeData.tilinumero.length > 10
        ) {
          if (!this.lomakeData.bic || this.lomakeData.bic.length < 8) {
            const bic = await this.$store.dispatch(
              "liikasuoritukset/selvitaIBANistaBIC",
              this.lomakeData.tilinumero
            );
            if (bic && bic.length > 7) {
              this.bic = bic;
              this.lomakeData.bic = bic;
              this.liikasuoritukset.bic = bic;
              this.$store.commit(
                "liikasuoritukset/setBic",
                this.liikasuoritukset.bic
              );
            }
          }
        }
      } catch (e) {
        this.$oletusVirheenkasittely(e);
      }
    },
    async paivitaBIC() {
      try {
        this.$store.commit(
          "liikasuoritukset/setBic",
          this.liikasuoritukset.bic
        );
        this.lomakeData.bic = this.liikasuoritukset.bic;
      } catch (e) {
        this.$oletusVirheenkasittely(e);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.v-tooltip__content {
  opacity: 1 !important;
}
</style>
