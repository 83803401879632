var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "view-layout pt-6" },
    [
      _c(
        "v-row",
        [
          _c("v-col", {
            staticClass: "pa-0",
            attrs: { cols: "12", sm: "0", md: "2" },
          }),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "12", md: "8" } },
            [
              _c("v-row", { staticClass: "mb-4" }),
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [_c("v-col", [_vm._t("default")], 2)],
                1
              ),
            ],
            1
          ),
          _c("v-col", {
            staticClass: "pa-0",
            attrs: { cols: "12", sm: "0", md: "2" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }