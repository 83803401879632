<template>
  <v-dialog v-model="auki" persistent :width="width" :max-width="maxWidth">
    <v-card ref="wrap" class="card-dialog">
      <div
        v-if="otsikko || naytaSuljePainike"
        class="card-dialog__header"
        :class="otsikkoClass">
        <v-row align="center" class="mx-0 mt-n1">
          <v-col class="px-0">
            <span v-if="otsikko" class="otsikko">
              {{ otsikko }}
            </span>
          </v-col>
          <v-col class="px-0 shrink">
            <v-btn
              v-if="naytaSuljePainike"
              icon
              small
              class="ma-0"
              @click="$emit('sulje')">
              <v-icon>close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div
        v-if="$slots['default']"
        ref="sisalto"
        class="card-dialog__sisalto"
        :class="sisaltoClass">
        <slot />
      </div>
      <div
        v-if="$slots['footer']"
        class="card-dialog__footer"
        :class="footerClass">
        <slot name="footer" />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CardDialog",
  props: {
    auki: {
      type: Boolean,
      required: false,
      default() {
        return false;
      }
    },
    width: {
      type: String,
      required: false,
      default() {
        return "85vw";
      }
    },
    maxWidth: {
      type: String,
      required: false,
      default: "480px"
    },
    otsikko: {
      type: String,
      required: false,
      default() {
        return "";
      }
    },
    naytaSuljePainike: {
      type: Boolean,
      required: false,
      default() {
        return true;
      }
    },
    scrollToBottom: {
      type: Boolean,
      required: false,
      default() {
        return false;
      }
    },
    sisaltoClass: {
      type: [String, Array],
      required: false,
      default() {
        return "pa-4 px-6";
      }
    },
    otsikkoClass: {
      type: String,
      required: false,
      default() {
        return "pa-4 px-6 mt-0";
      }
    },
    footerClass: {
      type: String,
      required: false,
      default() {
        return "pa-4 px-6";
      }
    }
  },
  watch: {
    auki: {
      handler(arvo) {
        if (arvo) {
          this.$nextTick().then(() => {
            this.$refs.wrap.$el.parentElement.scrollTop = 0;
          });
        }
      }
    }
  },
  updated() {
    // Vieritä näkymä alas, kun dialogissa on tehty jokin korkeutta kasvattava toiminto,
    // kuten esim. viestin lähettäminen
    this.$nextTick().then(() => {
      if (this.scrollToBottom && this.$refs.sisalto && this.$refs.wrap) {
        this.$refs.wrap.$el.parentElement.scrollTop =
          this.$refs.sisalto.clientHeight;
      }
    });
  }
};
</script>

<style lang="scss" scoped>
.card-dialog {
  overflow: auto;

  &__wrap {
    position: relative;
  }

  &__header,
  &__footer {
    position: sticky;
    z-index: 5;
    background: #fff;
    overflow: hidden;
  }

  /* Mukailee v-tabsin tyylejä */
  &__header {
    top: 0;
    font-size: 0.9375rem;
    font-weight: 500;
    letter-spacing: 0.075em;
    text-transform: uppercase;
  }

  &__footer {
    bottom: 0;
  }

  &__sisalto {
    z-index: 1;
  }
}
</style>
