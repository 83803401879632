<template>
  <v-form
    ref="formToimeksiantoMaksusopimusLomake"
    v-model="valid"
    @submit.prevent="submit">
    <v-row>
      <v-col cols="8" class="pl-4 pt-2 pr-0">
        <v-text-field
          v-model="lomakeData.erapaiva"
          type="number"
          label="Eräpäivä"
          prefix="Kuukauden"
          suffix=". päivä"
          outlined
          :rules="[
            $validointiSaannot.numero,
            $validointiSaannot.suurempiTaiYhtaIsoKuin(lomakeData.erapaiva, 1),
            $validointiSaannot.pienempiTaiYhtaIsoKuin(lomakeData.erapaiva, 31),
            validoiYritysVelallinen
          ]" />
        <v-text-field
          v-model="lomakeData.erat"
          type="number"
          label="Maksuerien lukumäärä"
          outlined
          :rules="[
            $validointiSaannot.suurempiTaiYhtaIsoKuin(lomakeData.erat, 2),
            $validointiSaannot.pienempiTaiYhtaIsoKuin(lomakeData.erat, 6)
          ]" />
      </v-col>
      <v-col cols="2" class="pl-1 pt-2">
        <v-btn icon class="mt-n2" @click="erapaivaKasvata">
          <v-icon color="primary">add_circle</v-icon>
        </v-btn>
        <v-btn icon @click="erapaivaVahenna">
          <v-icon color="primary">remove_circle</v-icon>
        </v-btn>
        <v-btn icon class="btn--custom-margin" @click="eratKasvata">
          <v-icon color="primary">add_circle</v-icon>
        </v-btn>
        <v-btn icon @click="eratVahenna">
          <v-icon color="primary">remove_circle</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mx-0 my-2">
      <ToimeksiantoMaksusopimusEraTaulukko
        :luonnos="true"
        :maksuerat="maksueralaskelma" />
    </v-row>
    <v-row v-if="kulu !== null && kulu !== undefined" class="mx-0 mt-4 mb-2">
      <p class="text-body-1 text-center">
        Maksueriin sisältyvät kulu {{ $n(Number(kulu), "currency") }} sekä
        viivästyskorot.
      </p>
    </v-row>
    <v-row class="ma-0 mt-0 py-4" justify="space-between">
      <v-btn
        color="secondary"
        rounded
        :loading="lataamassa"
        @click="$emit('sulje')">
        Peruuta
      </v-btn>
      <v-btn
        type="submit"
        color="primary"
        rounded
        :disabled="!valid || lataamassa"
        :loading="lataamassa">
        Hyväksy
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>
import axios from "axios";
import LomakeMixin from "@/mixins/LomakeMixin";
import ToimeksiantoMaksusopimusEraTaulukko from "@/components/ToimeksiantoMaksusopimusEraTaulukko";

export default {
  name: "ToimeksiantoMaksusopimusLomake",
  components: {
    ToimeksiantoMaksusopimusEraTaulukko
  },
  mixins: [LomakeMixin],
  data() {
    return {
      maksueralaskelma: [],
      kulu: null
    };
  },
  watch: {
    lomakeData: {
      immediate: true,
      deep: true,
      handler(lomakeData) {
        try {
          if (
            lomakeData.erat < 2 ||
            lomakeData.erat > 6 ||
            lomakeData.erapaiva < 1 ||
            lomakeData.erapaiva > 31
          )
            return;

          const { maksusopimusTiedotCancelToken } =
            this.$store.state.avoimetAsiat;
          if (
            maksusopimusTiedotCancelToken &&
            maksusopimusTiedotCancelToken.cancel
          )
            maksusopimusTiedotCancelToken.cancel(
              "Peruutettiin edellinen maksuerien laskentapyyntö, koska käyttäjä muutti lomakkeen parametreja."
            );
          this.laskeMaksuerat();
        } catch (e) {
          console.log("maksusopimusTiedotCancelToken e:", e);
          this.$oletusVirheenkasittely(e);
        }
      }
    }
  },
  methods: {
    //Erät kahden viikon välein ja ensimmäinen erä sallitaan oltava viimeistään kahden viikon päässä nykyhetkestä.
    //Lisäksi maksusopimus saa olla maksimissaan kolmen kuukauden pituinen.
    validoiYritysVelallinen() {
      if (this.lomakeData.velallistyyppi === "H") return true;
      let nykypaiva = new Date();
      let erapaiva = new Date();
      const virheEnsErapaivaMax2vko =
        "Yritysvelallisen ensimmäinen eräpäivä on oltava viimeistään kahden viikon päässä nykypäivästä";
      try {
        erapaiva.setDate(this.lomakeData.erapaiva);
        if (erapaiva < nykypaiva) {
          erapaiva = new Date(erapaiva.setMonth(erapaiva.getMonth() + 1));
        }
      } catch (e) {
        try {
          erapaiva = new Date(erapaiva.setMonth(erapaiva.getMonth() + 1));
          erapaiva.setDate(this.lomakeData.erapaiva);
        } catch (e) {
          return virheEnsErapaivaMax2vko;
        }
      }

      if (erapaiva) {
        const ensimmaiseenErapaivaanPaivia = this.$kahdenPaivanEroPaivissa({
          date1: nykypaiva,
          date2: erapaiva
        });
        if (ensimmaiseenErapaivaanPaivia > 14) {
          return virheEnsErapaivaMax2vko;
        }
        return true;
      }
      return virheEnsErapaivaMax2vko;
    },
    erapaivaKasvata() {
      this.lomakeData.erapaiva++;
    },
    erapaivaVahenna() {
      this.lomakeData.erapaiva--;
    },
    eratKasvata() {
      this.lomakeData.erat++;
    },
    eratVahenna() {
      this.lomakeData.erat--;
    },
    async laskeMaksuerat() {
      try {
        const validoiYritysVelallinen = this.validoiYritysVelallinen();
        if (
          validoiYritysVelallinen &&
          validoiYritysVelallinen.toString().length > 10
        )
          return;
        const { data } = await this.$store.dispatch(
          "avoimetAsiat/toimeksiannotLaskeMaksusopimuksenErat",
          this.lomakeData
        );
        this.maksueralaskelma = data.maksusopimusera_erittely;
        this.kulu = data.luonnosmskulu;
      } catch (e) {
        if (axios.isCancel(e)) {
          console.log("laskeMaksuerat e:", e);
        } else {
          this.$oletusVirheenkasittely(e);
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.btn--custom-margin {
  margin-top: 14px;
}
</style>
