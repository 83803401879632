<template>
  <v-tabs
    class="tabs"
    :centered="centered"
    :vertical="vertical"
    :color="envMaaritysColorNavTabs">
    <v-tab v-if="!kirjauduttuKirjeella" to="yhteenveto">Yhteenveto</v-tab>
    <v-tab to="avoimet">Avoimet asiat</v-tab>
    <v-tab to="viestit">
      <v-badge
        v-if="lukemattomatViestitKpl"
        color="info"
        :content="String(lukemattomatViestitKpl)">
        Viestit
      </v-badge>
      <span v-else>Viestit</span>
    </v-tab>
    <!-- Saldotodistus toistaiseksi pois käytöstä -->
    <!-- <v-tab
      v-if="!kirjauduttuKirjeella"
      to="saldotodistus"
    >
      Saldotodistus
    </v-tab> -->
    <v-tab to="arkisto">Arkisto</v-tab>
    <v-tab
      v-if="
        yleinen.liikasuoritusYhteenveto
          .yhteensa_liikasuoritus_myos_palautetut_kpl
      "
      to="liikasuoritukset">
      Liikasuoritukset
      <v-badge
        v-if="yleinen.liikasuoritusYhteenveto.yhteensa_liikasuoritus_kpl"
        color="error"
        :content="
          String(yleinen.liikasuoritusYhteenveto.yhteensa_liikasuoritus_kpl)
        "></v-badge>
    </v-tab>
  </v-tabs>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    centered: {
      type: Boolean,
      required: false,
      default: true
    },
    vertical: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapState(["yleinen"]),
    envMaaritysColorNavTabs() {
      return process.env.VUE_APP_COLOR_NAV_TABS || "";
    },
    iconColor() {
      return this.vertical
        ? "#FFFFFF"
        : process.env.VUE_APP_COLOR_NAV_BAR || "";
    },
    kirjauduttuKirjeella() {
      return this.$store.getters["yleinen/kirjauduttuKirjeella"]();
    },
    lukemattomatViestitKpl() {
      return this.$store.state.viestit.lukemattomatKpl;
    }
  }
};
</script>

<style></style>
