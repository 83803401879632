<template>
  <v-btn
    color="primary"
    :disabled="disabled"
    :href="href"
    :target="target"
    rounded
    class="justify-space-between"
    :class="classNames"
    @click="$emit('click', $event)">
    <span>{{ teksti }}</span>
    <v-icon class="ml-2">
      {{ ikoni }}
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    ikoni: {
      type: String,
      required: true
    },
    teksti: {
      type: String,
      required: true
    },
    href: {
      type: String,
      required: false,
      default: ""
    },
    target: {
      type: String,
      required: false,
      default: ""
    },
    customClasses: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    classNames() {
      if (this.customClasses !== null) return this.customClasses;
      return this.$vuetify.breakpoint.xs
        ? "tapaus-kortti__btn--xs"
        : "tapaus-kortti__btn--sm-and-up";
    }
  }
};
</script>

<style></style>
