var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app-bar",
    {
      attrs: {
        app: "",
        color: _vm.envMaaritysColorNavBar,
        dark: _vm.envMaaritysDarkNavBar,
        "elevate-on-scroll": "",
        "hide-on-scroll": _vm.$vuetify.breakpoint.smAndDown,
      },
      scopedSlots: _vm._u(
        [
          _vm.$vuetify.breakpoint.smAndDown
            ? {
                key: "extension",
                fn: function () {
                  return [
                    _c(
                      "v-row",
                      { attrs: { justify: "center" } },
                      [
                        _c("ViewTitle", {
                          attrs: { teksti: _vm.$route.name || "" },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              }
            : null,
        ],
        null,
        true
      ),
    },
    [
      _vm.envMaaritysInstanceClass === "uuva"
        ? [
            _c(
              "div",
              { staticClass: "d-flex align-center app-bg--white rounded" },
              [
                _c("v-img", {
                  staticClass: "shrink mt-1",
                  attrs: {
                    alt: "Logo",
                    contain: "",
                    "max-height": "48",
                    src: _vm.envMaaritysLogo,
                    width: "50",
                  },
                }),
              ],
              1
            ),
            _c("v-toolbar-title", { staticClass: "mx-4 app-app-bar-title" }, [
              _c("h3", { staticClass: "app-app-bar-title__text" }, [
                _vm._v("Oma UUVA"),
              ]),
            ]),
          ]
        : _c(
            "div",
            { staticClass: "d-flex align-center" },
            [
              _c("v-img", {
                staticClass: "shrink mt-1",
                attrs: {
                  alt: "Logo",
                  contain: "",
                  "max-height": "48",
                  src: _vm.envMaaritysLogo,
                  width: "200",
                },
              }),
            ],
            1
          ),
      _vm.$vuetify.breakpoint.smAndDown
        ? [
            _c("v-spacer"),
            _c(
              "div",
              { staticClass: "d-flex align-end" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "float-right",
                    attrs: { text: "" },
                    on: {
                      click: function ($event) {
                        _vm.naytaSivupalkkiNav = !_vm.naytaSivupalkkiNav
                      },
                    },
                  },
                  [
                    _c("v-icon", { attrs: { large: "" } }, [_vm._v("menu")]),
                    _vm.lukemattomatViestitJaPuuttuvatLiikasuorituksetKpl
                      ? _c("v-badge", {
                          attrs: {
                            color: _vm.palleronTaustaVari,
                            content: _vm.palleronLkm,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : [
            _c("NavTabs"),
            _c("LoggedInUser", {
              attrs: { "mini-variant": "" },
              on: {
                "avaa-omat-tiedot": _vm.avaaOmatTiedot,
                "kirjaudu-ulos": _vm.kirjauduUlos,
                tunnistaudu: _vm.siirryTunnistautumaan,
              },
            }),
          ],
      _c(
        "CardDialog",
        {
          attrs: {
            otsikko: "Omat tiedot",
            auki: _vm.omatTiedotDialogAuki,
            width: _vm.$vuetify.breakpoint.xs ? "85vw" : "320px",
          },
          on: {
            sulje: function ($event) {
              _vm.omatTiedotDialogAuki = false
            },
          },
        },
        [
          _c("OmatTiedotLomake", {
            attrs: { auki: _vm.omatTiedotDialogAuki },
            on: {
              sulje: function ($event) {
                _vm.omatTiedotDialogAuki = false
              },
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }