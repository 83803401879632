import store from "@/store";
import { TYPE as TOAST_TYPE } from "vue-toastification";

export const naytaOnnistumisilmoitus = (viesti) => {
  store.commit("ilmoitukset/naytaIlmoitus", {
    text: viesti,
    config: {
      type: TOAST_TYPE.SUCCESS
    }
  });
};

export const naytaVaroitusilmoitus = (viesti) => {
  store.commit("ilmoitukset/naytaIlmoitus", {
    text: viesti,
    config: {
      type: TOAST_TYPE.WARNING
    }
  });
};

export const naytaVirheilmoitus = (virheviesti) => {
  store.commit("ilmoitukset/naytaIlmoitus", {
    text: virheviesti,
    config: {
      type: TOAST_TYPE.ERROR
    }
  });
};

export const naytaChatBotIlmoitus = (virheviesti) => {
  store.commit("ilmoitukset/naytaIlmoitus", {
    text: virheviesti,
    config: {
      type: TOAST_TYPE.INFO,
      position: "bottom-right"
    }
  });
};

export const muotoileDesimaaliluku = (arvo, pakotaDesimaalipaikat = false) => {
  const liukuluku = parseFloat(arvo);
  if (isNaN(liukuluku)) {
    return ""; // Oletetaan että arvo on string
  } else if (pakotaDesimaalipaikat) {
    return liukuluku.toLocaleString(undefined, { minimumFractionDigits: 2 });
  } else {
    return liukuluku.toLocaleString();
  }
};

export const naytaTiedostonlataamisdialog = (response, filename) => {
  const blob = new Blob([response.data], {
    type: response.headers["content-type"]
  });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.click();
  URL.revokeObjectURL(link.href);
};

export const kahdenPaivanEroPaivissa = (param) => {
  let { date1, date2 } = param;
  if (!date1 || !date2) {
    return 9999999;
  }
  const diffTime = Math.abs(date2.getTime() - date1.getTime());
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};
