<template>
  <div
    class="kirjaudu mb-n4"
    :class="
      envMaaritysInstanceClass !== 'creditvisor' ? 'app-bg--primary' : ''
    ">
    <div class="paasisalto">
      <template v-if="envMaaritysInstanceClass === 'uuva'">
        <div class="d-flex align-center">
          <div class="d-flex align-center app-bg--white rounded">
            <v-img
              alt="Logo"
              class="mx-4"
              contain
              :src="envMaaritysLogo"
              :width="$vuetify.breakpoint.smAndDown ? 75 : 150" />
          </div>
          <h1
            class="white--text mx-4"
            :class="$vuetify.breakpoint.smAndDown ? 'text-h3' : 'text-h1'">
            Oma
            <br />
            UUVA
          </h1>
        </div>
      </template>
      <template v-else>
        <div class="d-flex align-center flex-column">
          <h1 class="ma-4" :class="kirjauduH1Class">Oma</h1>
          <v-img
            alt="Logo"
            class="mx-4"
            contain
            :src="envMaaritysLogo"
            :height="$vuetify.breakpoint.smAndDown ? 40 : 100" />
        </div>
      </template>
      <p
        v-if="nakymaLataamassa"
        class="mt-12 text-body-1 text-center app-animoitu-gradientti">
        Käsitellään...
      </p>
      <KirjauduKirjeellaLomake
        v-else
        class="mt-12"
        :lataamassa="lomakeLataamassa"
        @submit="kirjauduLomakkeella" />
      <p
        class="text-subtitle-1 text-center mt-8 mb-0 app-hover-link"
        :class="
          envMaaritysInstanceClass === 'creditvisor'
            ? 'primary--text'
            : 'white--text'
        "
        @click="$router.push({ path: '/kirjaudu' })">
        Palaa etusivulle
      </p>
    </div>
  </div>
</template>

<script>
import KirjauduKirjeellaLomake from "@/components/KirjauduKirjeellaLomake";

export default {
  name: "KirjauduKirjeella",
  components: {
    KirjauduKirjeellaLomake
  },
  data() {
    return {
      nakymaLataamassa: false,
      lomakeLataamassa: false
    };
  },
  computed: {
    envMaaritysInstanceClass() {
      return process.env.VUE_APP_INSTANCE_CLASS || "";
    },
    envMaaritysLoginButtonColor() {
      return process.env.VUE_APP_LOGIN_BUTTON_COLOR || "";
    },
    envMaaritysLogo() {
      const logo = process.env.VUE_APP_LOGO;
      return logo ? require(`@/assets/${logo}.png`) : "";
    },
    kirjauduH1Class() {
      return [
        this.envMaaritysInstanceClass === "creditvisor" ? "black--text" : "",
        this.$vuetify.breakpoint.smAndDown ? "text-h3" : "text-h1"
      ];
    }
  },
  created() {
    this.kasitteleKirjeTunnuksetJaKirjaaSisaan();
  },
  methods: {
    async kasitteleKirjeTunnuksetJaKirjaaSisaan() {
      this.nakymaLataamassa = true;

      try {
        const { searchParams } = new URL(location.href);
        const kirjeId = searchParams.get("t");
        const kirjeToken = searchParams.get("s");
        const suoraanMaksamaan = searchParams.get("m") === "1";
        const liikasuoritusValilehdelle = searchParams.get("i") === "1";
        // TODO Kieliversiot
        // const kieli = new URL(location.href).searchParams.get('l')

        if (!kirjeId && !kirjeToken) {
          await this.$store.dispatch("kirjauduKirjeella/tyhjennaKirjetiedot");
          this.nakymaLataamassa = false;
          return;
        }

        this.$store.commit("kirjauduKirjeella/asetaKirjeId", kirjeId);
        this.$store.commit("kirjauduKirjeella/asetaKirjeToken", kirjeToken);

        await this.kirjauduKirjeella({
          suoraanMaksamaan,
          liikasuoritusValilehdelle
        });
      } catch (e) {
        this.$sentryLog(e);
        this.$naytaVaroitusilmoitus(
          "Kirjeellä kirjautuminen ei onnistunut. Ole hyvä ja syötä tiedot lomakkeelle."
        );
      } finally {
        this.nakymaLataamassa = false;
      }
    },
    async kirjauduKirjeella(
      { suoraanMaksamaan, liikasuoritusValilehdelle } = {
        suoraanMaksamaan: false,
        liikasuoritusValilehdelle: false
      }
    ) {
      await this.$store.dispatch("kirjauduKirjeella/kirjauduKirjeella", {
        suoraanMaksamaan
      });
      if (liikasuoritusValilehdelle) {
        this.$router.replace({
          name: "Liikasuoritukset"
        });
      } else {
        this.$router.replace({
          name: "Avoimet asiat"
        });
      }
    },
    async kirjauduLomakkeella() {
      try {
        this.lomakeLataamassa = true;
        await this.kirjauduKirjeella();
      } catch (e) {
        this.$sentryLog(e);
        this.$naytaVirheilmoitus(
          "Kirjeellä kirjautuminen ei onnistunut. Ole hyvä ja tarkista syötetyt tiedot."
        );
      } finally {
        this.lomakeLataamassa = false;
      }
    }
  }
};
</script>

<style lang="scss"></style>
