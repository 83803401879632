export default class BaseModule {
  getModule() {
    return {
      namespaced: true,
      state: this.state(),
      getters: this.getters(),
      mutations: this.mutations(),
      actions: this.actions()
    };
  }

  state() {
    return {};
  }

  getters() {
    return {
      maaritaUrl(state, getters, rootState, rootGetters) {
        return function (url) {
          if (rootGetters["yleinen/kirjauduttuKirjeella"]())
            url = `kirje_auth/${url}`;
          return url;
        };
      }
    };
  }

  mutations() {
    return {};
  }

  actions() {
    return {};
  }
}
