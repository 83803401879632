var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ViewLayout",
    { ref: "viewLayout" },
    [
      _vm.$store.getters["yleinen/kirjauduttuKirjeella"]() &&
      _vm.envMaaritysInstanceClass !== "ijcapital"
        ? _c(
            "v-row",
            { staticClass: "mb-8", attrs: { justify: "center" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "elevation-5 pa-5 rounded-lg primary info",
                  attrs: { width: _vm.$vuetify.breakpoint.xs ? "85vw" : 562 },
                },
                [
                  _c(
                    "p",
                    { staticClass: "text-subtitle-1 mb-0" },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-2", attrs: { color: "white" } },
                        [_vm._v("info")]
                      ),
                      _c("span", { staticClass: "white--text" }, [
                        _vm._v(" Olet kirjautunut kirjeellä. "),
                        _c(
                          "a",
                          {
                            staticClass:
                              "white--text app-hover-link font-weight-medium",
                            on: { click: _vm.siirryTunnistautumaan },
                          },
                          [_vm._v(" Tunnistaudu ")]
                        ),
                        _vm._v(" nähdäksesi kaikki asiasi. "),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.maksamassaKirjeenTapausta
        ? _c("v-row", { staticClass: "mb-4", attrs: { justify: "center" } }, [
            _c(
              "p",
              {
                staticClass:
                  "mt-8 text-body-1 text-center app-animoitu-gradientti",
              },
              [_vm._v(" Siirrytään maksamaan... ")]
            ),
          ])
        : [
            _vm.vuexModuuli === "avoimetAsiat" &&
            !_vm.$store.getters["yhteenveto/eiAvoimiaVelkoja"]
              ? [
                  _c(
                    "v-row",
                    { staticClass: "mb-0", attrs: { justify: "center" } },
                    [
                      _c("PaytrailLomake", {
                        attrs: {
                          "lasku-idt":
                            _vm.laskutMaksutiedotLomakkeelle.laskuIdt,
                          "toimeksianto-idt":
                            _vm.toimeksiannotMaksutiedotLomakkeelle
                              .toimeksiantoIdt,
                          avoinna: Number(
                            _vm.laskutMaksutiedotLomakkeelle.avoinna +
                              _vm.toimeksiannotMaksutiedotLomakkeelle.avoinna
                          ),
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-divider", { staticClass: "mt-6 mb-9" }),
                ]
              : _vm._e(),
            _vm._l(_vm.tapaukset, function (tapaus) {
              return _c(
                "v-row",
                {
                  key: tapaus.id,
                  staticClass: "mb-8",
                  attrs: { justify: "center" },
                },
                [
                  tapaus.toimeksiantovelallinen_set
                    ? _c("ToimeksiantoKortti", {
                        attrs: {
                          toimeksianto: tapaus,
                          "vuex-moduuli": _vm.vuexModuuli,
                        },
                        on: {
                          alustaListaus: _vm.alustaListaus,
                          uusiViesti: _vm.siirryViestitValilehdelle,
                        },
                      })
                    : tapaus.asiakas
                    ? _c("LaskuKortti", {
                        attrs: {
                          lasku: tapaus,
                          "vuex-moduuli": _vm.vuexModuuli,
                        },
                        on: {
                          alustaListaus: _vm.alustaListaus,
                          uusiViesti: _vm.siirryViestitValilehdelle,
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            }),
            _vm.lataamassaTapauksia
              ? _c(
                  "v-row",
                  { staticClass: "mb-8", attrs: { justify: "center" } },
                  [_c("ToimeksiantoKorttiSkeletonLoader")],
                  1
                )
              : _vm._e(),
            _vm.lataaLisaaScrollBtnNakyvyysMaaritys
              ? _c(
                  "v-row",
                  { staticClass: "mb-8", attrs: { justify: "center" } },
                  [
                    _c("LataaLisaaScrollBtn", {
                      on: {
                        "btn-click": _vm.haeTapauksia,
                        nakyvyysmuutos: _vm.kasitteleNakyvyysmuutos,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.tapaukset.length && !_vm.lataamassaTapauksia
              ? [
                  _c("p", { staticClass: "mt-8 text-body-1 text-center" }, [
                    _vm._v(" " + _vm._s(_vm.noDataText) + " "),
                  ]),
                ]
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }