var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", width: _vm.width, "max-width": _vm.maxWidth },
      model: {
        value: _vm.auki,
        callback: function ($$v) {
          _vm.auki = $$v
        },
        expression: "auki",
      },
    },
    [
      _c("v-card", { ref: "wrap", staticClass: "card-dialog" }, [
        _vm.otsikko || _vm.naytaSuljePainike
          ? _c(
              "div",
              { staticClass: "card-dialog__header", class: _vm.otsikkoClass },
              [
                _c(
                  "v-row",
                  { staticClass: "mx-0 mt-n1", attrs: { align: "center" } },
                  [
                    _c("v-col", { staticClass: "px-0" }, [
                      _vm.otsikko
                        ? _c("span", { staticClass: "otsikko" }, [
                            _vm._v(" " + _vm._s(_vm.otsikko) + " "),
                          ])
                        : _vm._e(),
                    ]),
                    _c(
                      "v-col",
                      { staticClass: "px-0 shrink" },
                      [
                        _vm.naytaSuljePainike
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "ma-0",
                                attrs: { icon: "", small: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit("sulje")
                                  },
                                },
                              },
                              [_c("v-icon", [_vm._v("close")])],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.$slots["default"]
          ? _c(
              "div",
              {
                ref: "sisalto",
                staticClass: "card-dialog__sisalto",
                class: _vm.sisaltoClass,
              },
              [_vm._t("default")],
              2
            )
          : _vm._e(),
        _vm.$slots["footer"]
          ? _c(
              "div",
              { staticClass: "card-dialog__footer", class: _vm.footerClass },
              [_vm._t("footer")],
              2
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }