<template>
  <div class="yhteenveto">
    <ViewLayout ref="viewLayout">
      <v-row justify="center">
        <v-col class="text-center pt-0 mt-4">
          <template v-if="envMaaritysYhteenvetoText.length">
            <p
              v-for="(kappale, index) in envMaaritysYhteenvetoText"
              :key="kappale"
              class="text-body-1"
              :class="index === 0 ? 'font-weight-bold' : ''">
              {{ kappale }}
            </p>
          </template>
          <template v-if="!lataamassaDataa">
            <v-divider class="my-8" />
            <h4>Velkasi tällä hetkellä</h4>
            <p
              v-if="$store.getters['yhteenveto/eiAvoimiaVelkoja']"
              class="mt-4">
              Sinulla ei ole avoimia velkoja tällä hetkellä.
            </p>
            <v-simple-table
              v-else-if="
                !$vuetify.breakpoint.smAndDown &&
                envMaaritysInstanceClass !== 'creditvisor'
              "
              class="yhteenveto__table">
              <template #default>
                <thead>
                  <tr>
                    <th></th>
                    <th class="text-center">Kpl</th>
                    <th class="text-center">Summa</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="font-weight-medium text-left">Avoimet laskut</td>
                    <td>{{ yhteenveto.laskut_kpl }}</td>
                    <td>{{ $n(yhteenveto.laskut_avoinna, "currency") }}</td>
                    <td>
                      <v-btn
                        class="yhteenveto__table__btn"
                        color="primary"
                        rounded
                        small
                        :to="{
                          name: 'Avoimet asiat',
                          params: { suodatus: ['laskut'] }
                        }">
                        Katso avoimet laskut
                      </v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-medium text-left">
                      Avoimet muistutukset ja perintätoimeksiannot
                    </td>
                    <td>{{ yhteenveto.toimeksiannot_kpl }}</td>
                    <td>
                      {{ $n(yhteenveto.toimeksiannot_avoinna, "currency") }}
                    </td>
                    <td>
                      <v-btn
                        class="yhteenveto__table__btn"
                        color="primary"
                        rounded
                        small
                        :to="{
                          name: 'Avoimet asiat',
                          params: { suodatus: ['toimeksiannot'] }
                        }">
                        Katso toimeksiannot
                      </v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-medium text-left">
                      Maksettavaa yhteensä
                    </td>
                    <td class="font-weight-medium">
                      {{ yhteenveto.yhteensa_kpl }}
                    </td>
                    <td class="font-weight-medium">
                      {{ $n(yhteenveto.yhteensa_avoinna, "currency") }}
                    </td>
                    <td>
                      <v-btn
                        class="yhteenveto__table__btn"
                        color="primary"
                        rounded
                        small
                        to="avoimet">
                        Katso kaikki avoimet
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <template v-else>
              <div class="mt-8">
                <template v-if="envMaaritysInstanceClass !== 'creditvisor'">
                  <h4 class="font-weight-medium">Avoimet laskut</h4>
                  <p class="mt-2 mb-2">{{ yhteenveto.laskut_kpl }} kpl</p>
                  <p class="mt-0">
                    {{ $n(yhteenveto.laskut_avoinna, "currency") }}
                  </p>
                  <v-btn class="mt-2" color="primary" rounded to="avoimet">
                    Katso avoimet laskut
                  </v-btn>
                  <v-divider class="my-4" />
                </template>
                <h4 class="font-weight-medium">
                  Avoimet muistutukset ja perintätoimeksiannot
                </h4>
                <p class="mt-2 mb-2">{{ yhteenveto.toimeksiannot_kpl }} kpl</p>
                <p class="mt-0">
                  {{ $n(yhteenveto.toimeksiannot_avoinna, "currency") }}
                </p>
                <v-btn class="mt-2" color="primary" rounded to="avoimet">
                  Katso toimeksiannot
                </v-btn>
                <template v-if="envMaaritysInstanceClass !== 'creditvisor'">
                  <v-divider class="my-4" />
                  <h4>Maksettavaa yhteensä</h4>
                  <p class="mt-2 mb-2 font-weight-medium">
                    {{ yhteenveto.yhteensa_kpl }} kpl
                  </p>
                  <p class="mt-0 font-weight-medium">
                    {{ $n(yhteenveto.yhteensa_avoinna, "currency") }}
                  </p>
                  <v-btn class="mt-2" color="primary" rounded to="avoimet">
                    Katso kaikki avoimet
                  </v-btn>
                </template>
              </div>
            </template>
            <template v-if="!$store.getters['yhteenveto/eiAvoimiaVelkoja']">
              <v-divider class="my-8" />
              <v-row justify="center" class="mb-4">
                <PaytrailLomake
                  :lasku-idt="laskutMaksutiedotLomakkeelle.laskuIdt"
                  :toimeksianto-idt="
                    toimeksiannotMaksutiedotLomakkeelle.toimeksiantoIdt
                  "
                  :avoinna="
                    Number(
                      laskutMaksutiedotLomakkeelle.avoinna +
                        toimeksiannotMaksutiedotLomakkeelle.avoinna
                    )
                  " />
              </v-row>
            </template>
          </template>

          <template v-else-if="lataamassaDataa">
            <p class="mt-8 text-body-1 text-center app-animoitu-gradientti">
              Haetaan tietoja...
            </p>
          </template>
        </v-col>
      </v-row>
    </ViewLayout>
  </div>
</template>

<script>
import PaytrailLomake from "@/components/PaytrailLomake";
import { mapState } from "vuex";

export default {
  name: "Yhteenveto",
  components: {
    PaytrailLomake
  },
  data() {
    return {
      liikasuoritusDialogAuki: false,
      liikasuoritusValid: true,
      ibanTilinumero: "",
      tilinOmistajanNimi: "",
      bicPankkitunnus: "",
      liikasuoritukset: []
    };
  },
  computed: {
    ...mapState(["yleinen"]),
    envMaaritysInstanceClass() {
      return process.env.VUE_APP_INSTANCE_CLASS || "";
    },
    envMaaritysYhteenvetoText() {
      let kappaleet;
      if (typeof process.env.VUE_APP_YHTEENVETO_TEXT === "string") {
        kappaleet = process.env.VUE_APP_YHTEENVETO_TEXT.split(";");
      }
      return kappaleet || [];
    },
    lataamassaDataa() {
      return this.$store.state.yhteenveto.lataamassaDataa;
    },
    yhteenveto() {
      return this.$store.state.yhteenveto.yhteenveto;
    },
    laskutMaksutiedotLomakkeelle() {
      return this.$store.getters["avoimetAsiat/laskutMaksutiedotLomakkeelle"];
    },
    toimeksiannotMaksutiedotLomakkeelle() {
      return this.$store.getters[
        "avoimetAsiat/toimeksiannotMaksutiedotLomakkeelle"
      ];
    }
  },
  async created() {
    await this.haeYhteenveto();
    await this.haeLiikasuoritusYhteenveto();
    await this.haeMaksutiedot();
  },
  async mounted() {
    this.$store.dispatch("yleinen/chatBotIlmoitus");
    await this.haeLiikasuoritusYhteenveto();
  },
  methods: {
    async haeYhteenveto() {
      try {
        this.$store.commit("yhteenveto/lataamassaDataa", true);
        await this.$store.dispatch("yhteenveto/haeYhteenveto");
      } catch (e) {
        this.$oletusVirheenkasittely(
          e,
          "Tietojen haku epäonnistui. Yritä hetken kuluttua uudelleen."
        );
      } finally {
        this.$store.commit("yhteenveto/lataamassaDataa", false);
      }
    },
    async haeLiikasuoritusYhteenveto() {
      try {
        await this.$store.dispatch("yleinen/haeLiikasuoritusYhteenveto");
      } catch (e) {
        this.$oletusVirheenkasittely(
          e,
          "Tietojen haku epäonnistui. Yritä hetken kuluttua uudelleen."
        );
      }
    },
    async haeMaksutiedot() {
      await this.$store.dispatch("avoimetAsiat/haeMaksutiedot");
    },
    kasitteleLiikasuorituksiinHyppays() {
      this.$store.dispatch(
        "yleinen/kasitteleLiikasuorituksiinHyppays",
        this.$router
      );
    }
  }
};
</script>

<style lang="scss">
.yhteenveto {
  &__table {
    background-color: var(--background) !important;

    &__btn {
      width: 192px;
    }
  }
}
</style>
