import BaseModule from "@/store/modules/baseModule";

export default class Viestit extends BaseModule {
  state() {
    return {
      ...super.state(),
      lahettamassaUuttaViestia: false,
      lataamassaDataa: false,
      lukemattomatKpl: 0,
      nykyinenSivu: null,
      seuraavaSivu: 1,
      viestit: [],
      viestiAiheet: []
    };
  }

  mutations() {
    return {
      ...super.mutations(),
      asetaOletustila(state, payload) {
        state = Object.assign(state, payload);
      },
      lahettamassaUuttaViestia(state, payload) {
        state.lahettamassaUuttaViestia = payload;
      },
      lataamassaDataa(state, payload) {
        state.lataamassaDataa = payload;
      },
      lukemattomatKpl(state, payload) {
        state.lukemattomatKpl = payload;
      },
      nykyinenSivu(state, payload) {
        state.nykyinenSivu = payload;
      },
      pushViestit(state, payload) {
        state.viestit.push(...payload);
      },
      pushViestiAiheet(state, payload) {
        state.viestiAiheet.push(...payload);
      },
      seuraavaSivu(state, payload) {
        state.seuraavaSivu = payload;
      }
    };
  }

  getters() {
    return {
      ...super.getters(),
      oletustila() {
        return function () {
          return {
            lahettamassaUuttaViestia: false,
            lataamassaDataa: false,
            lukemattomatKpl: 0,
            nykyinenSivu: null,
            seuraavaSivu: 1,
            viestit: [],
            viestiAiheet: []
          };
        };
      }
    };
  }

  actions() {
    return {
      ...super.actions(),
      async asetaOletustila({ commit, getters }) {
        commit("asetaOletustila", getters.oletustila());
      },
      async haeViesteja({ getters, commit, state }) {
        try {
          if (state.lataamassaDataa || !state.seuraavaSivu) return;
          commit("nykyinenSivu", state.nykyinenSivu + 1);
          commit("lataamassaDataa", true);

          const { data } = await this._vm.$api.withAuth.request({
            method: "GET",
            url: getters.maaritaUrl("paamiesviestit/ryhmitelty/"),
            params: {
              sivun_pituus: 4,
              page: state.nykyinenSivu
            }
          });

          if (data.next) {
            commit("seuraavaSivu", state.nykyinenSivu + 1);
          } else {
            commit("seuraavaSivu", null);
          }

          const jarjestettyData = data.results.sort((a, b) => {
            let tulos = 0;
            const dateA = new Date(a.viimeisin_viesti);
            const dateB = new Date(b.viimeisin_viesti);
            if (dateA < dateB) tulos = 1;
            else if (dateA > dateB) tulos = -1;
            return tulos;
          });
          commit("pushViestit", jarjestettyData);
        } catch (error) {
          this._vm.$sentryLog(error);
        } finally {
          state.lataamassaDataa = false;
        }
      },
      async merkitseViestiLuetuksi({ getters, commit }, viesti) {
        await this._vm.$api.withAuth.request({
          method: "PUT",
          url: getters.maaritaUrl(`paamiesviestit/${viesti.id}/`),
          data: {
            luettu: new Date().toISOString()
          }
        });
      },
      async lahetaViesti({ getters, commit }, lomakeData) {
        return await this._vm.$api.withAuth.request({
          method: "POST",
          url: getters.maaritaUrl("paamiesviestit/"),
          data: lomakeData
        });
      },
      async haeViestiAiheet({ dispatch, commit }) {
        const laskutAiheet = await dispatch("laskutHaeViestiAiheet");
        const toimeksiannotAiheet = await dispatch(
          "toimeksiannotHaeViestiAiheet"
        );
        commit("pushViestiAiheet", [
          ...laskutAiheet.data,
          ...toimeksiannotAiheet.data
        ]);
      },
      async laskutHaeViestiAiheet({ getters }) {
        return await this._vm.$api.withAuth.request({
          method: "GET",
          url: getters.maaritaUrl("laskut/viesti_aihe/")
        });
      },
      async toimeksiannotHaeViestiAiheet({ getters }) {
        return await this._vm.$api.withAuth.request({
          method: "GET",
          url: getters.maaritaUrl("toimeksiannot/viesti_aihe/")
        });
      },
      async haeLukemattomatKpl({ getters, commit }) {
        try {
          const { data } = await this._vm.$api.withAuth.request({
            method: "GET",
            url: getters.maaritaUrl("paamiesviestit/lukemattomat_kpl/")
          });

          commit("lukemattomatKpl", data.lukemattomat_kpl);
        } catch (e) {
          this._vm.$sentryLog(e);
        }
      }
    };
  }
}
