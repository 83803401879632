export default {
  name: "LomakeMixin",
  props: {
    lataamassa: {
      type: Boolean,
      required: false,
      default() {
        return false;
      }
    },
    lomakeData: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    },
    auki: {
      type: Boolean,
      required: false,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      valid: true
    };
  },
  methods: {
    submit() {
      this.$emit("submit", this.lomakeData);
    }
  },
  watch: {
    lomakeData: {
      deep: true,
      handler(val) {
        this.$emit("input", val);
      }
    }
  }
};
