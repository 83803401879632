var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-menu", {
    attrs: { transition: "slide-y-transition", "nudge-bottom": "40" },
    scopedSlots: _vm._u([
      {
        key: "activator",
        fn: function ({ on }) {
          return [
            _c(
              "div",
              _vm._g({ staticClass: "loggedInUser__wrap my-6 px-2" }, on),
              [
                _c(
                  "div",
                  {
                    ref: "colContent",
                    staticClass: "loggedInUser__col loggedInUser__col--content",
                    class:
                      _vm.envMaaritysInstanceClass === "uuva"
                        ? "loggedInUser__col--content--white"
                        : "",
                  },
                  [
                    !_vm.$store.getters["yleinen/kirjauduttuKirjeella"]()
                      ? _c("span", [
                          _vm._v(" " + _vm._s(_vm.kayttajaNimi) + " "),
                        ])
                      : _c("span", [_vm._v("Olet kirjautunut kirjeellä")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "loggedInUser__col loggedInUser__col--avatar",
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "loggedInUser__iconWrap",
                        class:
                          _vm.envMaaritysInstanceClass === "uuva"
                            ? "loggedInUser__iconWrap--white"
                            : "",
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "loggedInUser__icon",
                            class:
                              _vm.envMaaritysInstanceClass === "uuva"
                                ? "loggedInUser__icon--white"
                                : "",
                          },
                          [_vm._v(" person ")]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "loggedInUser__col loggedInUser__col--actions",
                  },
                  [_c("v-icon", [_vm._v("arrow_drop_down")])],
                  1
                ),
              ]
            ),
          ]
        },
      },
      {
        key: "default",
        fn: function () {
          return [
            _c(
              "v-list",
              [
                !_vm.$store.getters["yleinen/kirjauduttuKirjeella"]()
                  ? _c(
                      "v-list-item",
                      {
                        attrs: {
                          disabled:
                            !_vm.$store.state.yleinen.velallistiedot ||
                            !_vm.$store.state.yleinen.velallistiedot.id,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("avaa-omat-tiedot")
                          },
                        },
                      },
                      [
                        _c("v-list-item-title", [_vm._v("Omat tiedot")]),
                        _c(
                          "v-list-item-avatar",
                          [
                            _c(
                              "v-icon",
                              { staticClass: "material-icons-outlined" },
                              [_vm._v("info")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm.envMaaritysInstanceClass !== "ijcapital"
                  ? _c(
                      "v-list-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.$emit("tunnistaudu")
                          },
                        },
                      },
                      [
                        _c("v-list-item-title", [_vm._v("Tunnistaudu")]),
                        _c(
                          "v-list-item-avatar",
                          [
                            _c(
                              "v-icon",
                              { staticClass: "material-icons-outlined" },
                              [_vm._v("login")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "v-list-item",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.$emit("kirjaudu-ulos")
                      },
                    },
                  },
                  [
                    _c("v-list-item-title", [_vm._v("Kirjaudu ulos")]),
                    _c(
                      "v-list-item-avatar",
                      [_c("v-icon", [_vm._v("logout")])],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }