var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "pa-4 d-flex flex-column" },
        [
          _c("v-text-field", {
            attrs: {
              label: "Tunnus",
              rules: [
                _vm.$validointiSaannot.numero,
                _vm.$validointiSaannot.kokonaisluku,
                _vm.$validointiSaannot.pakollinen,
              ],
              outlined: "",
              required: "",
              "background-color": "#f5f5f5",
            },
            model: {
              value: _vm.kirjeId,
              callback: function ($$v) {
                _vm.kirjeId = $$v
              },
              expression: "kirjeId",
            },
          }),
          _c("v-text-field", {
            attrs: {
              label: "Salasana",
              "append-icon": _vm.naytaSalasana
                ? "visibility_off"
                : "visibility",
              type: _vm.naytaSalasana ? "text" : "password",
              rules: [_vm.$validointiSaannot.pakollinen],
              outlined: "",
              required: "",
              "background-color": "#f5f5f5",
            },
            on: {
              "click:append": function ($event) {
                _vm.naytaSalasana = !_vm.naytaSalasana
              },
            },
            model: {
              value: _vm.kirjeToken,
              callback: function ($$v) {
                _vm.kirjeToken = $$v
              },
              expression: "kirjeToken",
            },
          }),
          _c(
            "v-btn",
            {
              attrs: {
                type: "submit",
                disabled: !_vm.valid || _vm.lataamassa,
                loading: _vm.lataamassa,
                color: "primary",
                rounded: "",
                large: "",
              },
            },
            [_vm._v(" Kirjaudu ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }