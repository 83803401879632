var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c("v-col", { staticClass: "font-weight-medium text-left" }, [
            _vm._v("Liikasuoritus"),
          ]),
          _c("v-col", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$n(_vm.lomakeData.liikasuoritus.liikasuoritus, "currency")
                ) +
                " "
            ),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { staticClass: "font-weight-medium text-left" }, [
            _vm._v("Käsittelymaksu"),
          ]),
          _c("v-col", [
            _vm._v(
              _vm._s(_vm.$n(-_vm.lomakeData.liikasuoritus.kulu, "currency"))
            ),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", [
            _vm._v(
              " veroton " +
                _vm._s(
                  _vm.$n(_vm.lomakeData.liikasuoritus.veroton, "currency")
                ) +
                " alv 24 % " +
                _vm._s(_vm.$n(_vm.lomakeData.liikasuoritus.vero, "currency")) +
                " "
            ),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { staticClass: "font-weight-medium text-left" }, [
            _vm._v("Palautettava summa"),
          ]),
          _c("v-col", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$n(
                    _vm.lomakeData.liikasuoritus.liikasuoritus -
                      _vm.lomakeData.liikasuoritus.kulu,
                    "currency"
                  )
                ) +
                " "
            ),
          ]),
        ],
        1
      ),
      _c("v-divider", { staticClass: "mx-4 my-2" }),
      _vm.lomakeData.toimeksianto
        ? _c(
            "v-row",
            [
              _c("v-col", { staticClass: "font-weight-medium text-left" }, [
                _vm._v("Asianumero"),
              ]),
              _c("v-col", { staticClass: "font-weight-medium text-rigth" }, [
                _vm._v(" " + _vm._s(_vm.lomakeData.toimeksianto.id) + " "),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.lomakeData.lasku
        ? _c(
            "v-row",
            [
              _c("v-col", { staticClass: "font-weight-medium text-left" }, [
                _vm._v("Laskunumero"),
              ]),
              _c("v-col", { staticClass: "font-weight-medium text-rigth" }, [
                _vm._v(" " + _vm._s(_vm.lomakeData.lasku.nro) + " "),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c("v-text-field", {
        attrs: {
          label: "IBAN tilinumero",
          rules: [
            _vm.$validointiSaannot.pakollinen,
            _vm.$validointiSaannot.isValidIBAN,
          ],
        },
        on: { change: _vm.selvitaIBANistaBIC },
        model: {
          value: _vm.lomakeData.tilinumero,
          callback: function ($$v) {
            _vm.$set(_vm.lomakeData, "tilinumero", $$v)
          },
          expression: "lomakeData.tilinumero",
        },
      }),
      _c("v-text-field", {
        attrs: {
          label: "BIC pankkitunnus",
          rules: [
            _vm.$validointiSaannot.pakollinen,
            _vm.$validointiSaannot.isValidBIC,
          ],
        },
        on: { change: _vm.paivitaBIC },
        model: {
          value: _vm.liikasuoritukset.bic,
          callback: function ($$v) {
            _vm.$set(_vm.liikasuoritukset, "bic", $$v)
          },
          expression: "liikasuoritukset.bic",
        },
      }),
      _c("v-text-field", {
        attrs: {
          label: "Tilinomistajan nimi",
          rules: [_vm.$validointiSaannot.pakollinen],
        },
        model: {
          value: _vm.lomakeData.tilinOmistajanNimi,
          callback: function ($$v) {
            _vm.$set(_vm.lomakeData, "tilinOmistajanNimi", $$v)
          },
          expression: "lomakeData.tilinOmistajanNimi",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }