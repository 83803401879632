export default {
  namespaced: true,
  state: {
    lataamassaDataa: false,
    yhteenveto: {}
  },
  getters: {
    eiAvoimiaVelkoja(state) {
      return (
        state.yhteenveto.laskut_kpl === 0 &&
        state.yhteenveto.toimeksiannot_kpl === 0
      );
    }
  },
  mutations: {
    asetaYhteenveto(state, payload) {
      state.yhteenveto = payload;
    },
    lataamassaDataa(state, payload) {
      state.lataamassaDataa = payload;
    }
  },
  actions: {
    async haeYhteenveto({ state, commit }) {
      const { data } = await this._vm.$api.withAuth.request({
        method: "GET",
        url: "yhteenveto/"
      });
      commit("asetaYhteenveto", data);
    }
  }
};
