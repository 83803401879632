var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass:
        "tapaus-kortti tapaus-kortti--lasku rounded-lg rounded-t-xl pa-1",
      attrs: {
        width: _vm.$vuetify.breakpoint.xs ? "85vw" : 562,
        elevation: "5",
      },
    },
    [
      _vm.vuexModuuli !== "liikasuoritukset"
        ? _c(
            "div",
            [
              _c(
                "CardDialog",
                {
                  attrs: {
                    otsikko: "Eräpäivän siirtopyyntö",
                    auki: _vm.erapaivanSiirtoLomakeAuki,
                    width: _vm.$vuetify.breakpoint.xs ? "85vw" : "320px",
                  },
                  on: {
                    sulje: function ($event) {
                      _vm.erapaivanSiirtoLomakeAuki = false
                    },
                  },
                },
                [
                  _c("ErapaivanSiirtoLomake", {
                    attrs: {
                      "lomake-data": _vm.erapaivanSiirtoLomakeData,
                      lataamassa: _vm.erapaivanSiirtoLomakeLataamassa,
                      tapaustyyppi: "laskut",
                    },
                    on: { submit: _vm.laskutLahetaErapaivanSiirtopyynto },
                  }),
                ],
                1
              ),
              _c("MenuContext", {
                attrs: {
                  "onko-nakyvissa": _vm.menuContextNakyvissa,
                  "position-x": _vm.menuContextX,
                  "position-y": _vm.menuContextY,
                  vaihtoehdot: _vm.menuContextKirjeet,
                },
                on: {
                  "update:return-value": _vm.suljeMenuContext,
                  lataaKirje: _vm.haeLaskuliite,
                },
              }),
            ],
            1
          )
        : _vm.vuexModuuli === "liikasuoritukset"
        ? _c(
            "CardDialog",
            {
              attrs: {
                otsikko: "Liikasuoritus",
                auki: _vm.laskuLiikasuoritusLomakeAuki,
                width: _vm.$vuetify.breakpoint.xs ? "85vw" : "320px",
              },
              on: {
                sulje: function ($event) {
                  _vm.laskuLiikasuoritusLomakeAuki = false
                },
              },
            },
            [
              !_vm.laskuLiikasuoritusLomakeData.liikasuoritus ||
              !_vm.laskuLiikasuoritusLomakeData.liikasuoritus.kohdeasia_id ||
              _vm.laskuLiikasuoritusLomakeData.liikasuoritus.kohdeasia_id ===
                "0"
                ? _c("LiikasuoritusLomake", {
                    attrs: {
                      "lomake-data": _vm.laskuLiikasuoritusLomakeData,
                      lataamassa: _vm.laskuLiikasuoritusLomakeLataamassa,
                      tapaustyyppi: "laskut",
                    },
                    on: { lahetaLiikasuoritus: _vm.lahetaLiikasuoritus },
                  })
                : _c("LiikasuorituskohdistusLomake", {
                    attrs: {
                      "lomake-data": _vm.laskuLiikasuoritusLomakeData,
                      lataamassa: _vm.laskuLiikasuoritusLomakeLataamassa,
                      tapaustyyppi: "laskut",
                    },
                    on: {
                      lahetaLiikasuoritus: _vm.lahetaLiikasuoritus,
                      lahetaKohdistusLiikasuoritus:
                        _vm.lahetaKohdistusLiikasuoritus,
                    },
                  }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        { staticClass: "justify-space-between" },
        [
          _c(
            "v-col",
            { staticClass: "pb-0", attrs: { cols: "9" } },
            [
              _c("v-card-title", { staticClass: "pb-0" }, [
                _c("h5", [
                  _vm._v(" Lasku: "),
                  _c("span", { staticClass: "app-overflow-break-word" }, [
                    _vm._v(_vm._s(_vm.lasku.nro)),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pb-0", attrs: { cols: "3" } },
            [
              _c("v-tooltip", {
                attrs: { top: "", "open-on-click": "", "open-on-focus": "" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function ({ on }) {
                      return [
                        _c(
                          "v-avatar",
                          _vm._g(
                            {
                              staticClass: "mx-4 mt-4 float-right",
                              attrs: {
                                color: _vm.ikoni.variAvatar,
                                size: "52",
                              },
                            },
                            on
                          ),
                          [
                            _c(
                              "v-icon",
                              { attrs: { color: _vm.ikoni.vari, large: "" } },
                              [_vm._v(" " + _vm._s(_vm.ikoni.nimi) + " ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function () {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.tilaUi) +
                            " " +
                            _vm._s(_vm.myohassa ? " (myöhässä)" : "") +
                            " "
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "py-0 my-0", attrs: { cols: "12" } },
            [
              _c("v-card-subtitle", { staticClass: "pt-0" }, [
                _vm._v(" Laskuttaja: " + _vm._s(_vm.lasku.paamies.nimi) + " "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-divider", { staticClass: "mx-4 my-2" }),
      _c(
        "v-list",
        [
          _c(
            "v-list-item",
            [
              _c(
                "v-list-item-title",
                { staticClass: "tapaus-kortti__list-item__title" },
                [_vm._v(" Eräpäivä ")]
              ),
              _c(
                "v-list-item-subtitle",
                {
                  staticClass: "tapaus-kortti__list-item__subtitle text-right",
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$date(new Date(_vm.lasku.erapaiva))) + " "
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "v-list-item",
            [
              _c(
                "v-list-item-title",
                { staticClass: "tapaus-kortti__list-item__title" },
                [_vm._v(" Asian tila ")]
              ),
              _c(
                "v-list-item-subtitle",
                {
                  staticClass: "tapaus-kortti__list-item__subtitle text-right",
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.tilaUi) +
                      " " +
                      _vm._s(_vm.myohassa ? " (myöhässä)" : "") +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "v-list-item",
            [
              _c(
                "v-list-item-title",
                { staticClass: "tapaus-kortti__list-item__title" },
                [_vm._v(" Avoinna ")]
              ),
              _c(
                "v-list-item-subtitle",
                {
                  staticClass: "tapaus-kortti__list-item__subtitle text-right",
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$n(Number(_vm.lasku.avoinna), "currency")) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
          _c("v-list-item", [_c("v-divider")], 1),
          _vm.vuexModuuli === "liikasuoritukset"
            ? _c(
                "v-list-item",
                {
                  staticClass: "my-4",
                  class: _vm.$vuetify.breakpoint.xs
                    ? "justify-center"
                    : "justify-end",
                },
                [
                  _vm.lasku.liikasuoritustila ===
                  _vm.yleinen.liikasuoritustilaTiedotPuuttuu
                    ? _c("TapausKorttiTooltipBtn", {
                        attrs: {
                          teksti: "Palauta tilitiedot",
                          ikoni: "send",
                          disabled: _vm.lasku.disabled,
                          "tooltip-teksti":
                            "Tästä avautuu lomake, jolla voit antaa palautukseen tarvittavat tiedot",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.avaaLiikasuoritus(
                              _vm.lasku.menuContextLiikasuoritukset[0]
                            )
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.lasku.liikasuoritustila ===
                  _vm.yleinen.liikasuoritustilaKasittelyssa
                    ? _c("span", [
                        _vm._v(
                          " Tilitiedot palautettu. Rahat palautetaan 1-2 viikon kuluttua. "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.tilaUi === _vm.AVOINNA && _vm.vuexModuuli !== "liikasuoritukset"
            ? [
                _c(
                  "v-list-item",
                  {
                    staticClass: "my-4",
                    class: _vm.$vuetify.breakpoint.xs
                      ? "justify-center"
                      : "justify-end",
                  },
                  [
                    _c("TapausKorttiBtn", {
                      attrs: {
                        teksti: "Liitteet",
                        ikoni: "download",
                        disabled: !_vm.onLaskuLiitteita,
                      },
                      on: { click: _vm.avaaMenuContext },
                    }),
                  ],
                  1
                ),
                _c(
                  "v-list-item",
                  {
                    staticClass: "my-4",
                    class: _vm.$vuetify.breakpoint.xs
                      ? "justify-center"
                      : "justify-end",
                  },
                  [
                    _c("TapausKorttiBtn", {
                      attrs: {
                        teksti: _vm.erapaivaSiirrettavissa
                          ? "Siirrä eräpäivää"
                          : "Eräpäivää ei voi siirtää",
                        ikoni: _vm.erapaivaSiirrettavissa
                          ? "arrow_right_alt"
                          : "",
                        disabled: !_vm.erapaivaSiirrettavissa,
                      },
                      on: {
                        click: function ($event) {
                          _vm.erapaivanSiirtoLomakeAuki = true
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "v-list-item",
                  {
                    staticClass: "my-4",
                    class: _vm.$vuetify.breakpoint.xs
                      ? "justify-center"
                      : "justify-end",
                  },
                  [
                    !(_vm.envMaaritysInstanceClass === "creditvisor")
                      ? _c("PaytrailLomake", {
                          attrs: {
                            "lasku-id": _vm.lasku.id,
                            avoinna: Number(_vm.lasku.avoinna),
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "v-list-item",
                  {
                    staticClass: "my-4",
                    class: _vm.$vuetify.breakpoint.xs
                      ? "justify-center"
                      : "justify-end",
                  },
                  [
                    _c("TapausKorttiBtn", {
                      attrs: { teksti: "Uusi viesti", ikoni: "message" },
                      on: { click: _vm.siirryViestitValilehdelle },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c("div", {
        staticClass: "tapaus-kortti__vari-ilmaisin mt-4",
        class: _vm.variIlmaisinLuokka,
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }