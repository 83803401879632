import Vue from "vue";
import axios from "axios";
import router from "@/router";
import { naytaVaroitusilmoitus } from "@/utils/misc";
import store from "@/store";

const baseURL = process.env.VUE_APP_API_ROOT;

const getAccessToken = () => {
  return localStorage.getItem("accessToken");
};

const setAccessToken = (token) => {
  return localStorage.setItem("accessToken", token);
};

const getRefreshToken = () => {
  return localStorage.getItem("refreshToken");
};

const tyhjennaTokenit = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
};

const base = axios.create({
  baseURL: baseURL,
  timeout: 30000
});

base.interceptors.request.use(
  (request) => {
    return request;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

base.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

const withAuth = axios.create({
  baseURL: baseURL,
  timeout: 300000
});

let resetTokenLoading = false;
let requestQueue = [];

withAuth.interceptors.request.use(
  (request) => {
    if (process.env.VUE_APP_ENV === "development") {
      // Paikallisen Anittan kanssa testausta varten aseta tähän paikallisen Anittan käyttäjälle 'portaali-api' luotu token.
      request.headers.Authorization =
        "Token fe2edf026c1c2d2ee8b6c0091a529c7d607287c2";
    }

    if (store.getters["yleinen/kirjauduttuKirjeella"]()) {
      request.headers["X-Auth-Kirje-Id"] =
        store.getters["kirjauduKirjeella/kirjeId"];
      request.headers["X-Auth-Kirje-Token"] =
        store.getters["kirjauduKirjeella/kirjeToken"];
    } else if (getAccessToken()) {
      if (process.env.VUE_APP_ENV !== "development") {
        request.headers.Authorization = "Token " + getAccessToken();
      }
    }

    return request;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

withAuth.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error && error.response && error.response.status === 401) {
      return refreshTokenAndRetry(error);
    } else {
      console.log(error);
      return Promise.reject(error);
    }
  }
);

const refreshTokenAndRetry = async (error) => {
  try {
    console.log("Yritetään tehdä token refresh...");

    const { response: errorResponse } = error;

    if (!getRefreshToken()) {
      router.replace({
        name: "Kirjaudu"
      });

      return Promise.reject(error);
    }

    // Lisätään epäonnistunut pyyntö jonoon
    const retryOriginalRequest = new Promise((resolve) => {
      addToRequestQueue((access_token) => {
        errorResponse.config.headers.Authorization = "Token " + access_token;
        resolve(axios(errorResponse.config));
      });
    });

    if (!resetTokenLoading) {
      resetTokenLoading = true;

      await axios
        .request({
          method: "POST",
          url: baseURL + "token/refresh/",
          data: {
            refresh: getRefreshToken()
          }
        })
        .then((response) => {
          const newAccessToken = response.data.access;
          setAccessToken(newAccessToken);
          resetTokenLoading = false;

          console.log("Tehdään uudelleen epäonnistuneet pyynnöt...");

          requestQueue.forEach((callback) => callback(newAccessToken));
          requestQueue = [];
        })
        .catch((error) => {
          resetTokenLoading = false;
          tyhjennaTokenit();

          router.replace({
            name: "Kirjaudu"
          });

          naytaVaroitusilmoitus(
            "Istuntosi on vanhentunut.  Ole hyvä ja kirjaudu sisään."
          );
          // Asetetaan lippu, jonka avulla estetään tarpeeton Sentry-virheenkaappaus
          if (error.response) error.response.istuntoVanhentunut = true;
          return Promise.reject(error);
        });
    }

    return retryOriginalRequest;
  } catch (error) {
    console.log(error);

    return Promise.reject(error);
  }
};

function addToRequestQueue(callback) {
  requestQueue.push(callback);
}

const luoCancelTokenSource = () => {
  const cancelToken = axios.CancelToken;
  return cancelToken.source();
};

const api = {
  base,
  withAuth,
  luoCancelTokenSource,
  tyhjennaTokenit
};

Vue.prototype.$api = api;

export default api;
