var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CardDialog",
    {
      staticClass: "px-0 py-0 app-height-100-pct",
      attrs: {
        "sisalto-class": _vm.sisaltoClass,
        auki: _vm.auki,
        width: "1200",
        "max-width": "98vw",
      },
      on: {
        sulje: function ($event) {
          return _vm.$emit("sulje")
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "pdf-modal" },
        [
          _c("pdf", {
            staticStyle: { width: "100%" },
            attrs: {
              annotation: true,
              src: _vm.paikallinenUrl,
              scale: _vm.scale,
              page: _vm.nykyinenSivu,
            },
            on: {
              "update:scale": function ($event) {
                _vm.scale = $event
              },
              numpages: _vm.kasitteleNumpages,
              loading: _vm.asetaLataamassa,
              error: _vm.kasitteleError,
            },
          }),
          _vm.pdfLataamassa
            ? _c(
                "p",
                {
                  staticClass:
                    "mt-n12 text-body-1 text-center app-animoitu-gradientti",
                },
                [_vm._v(" Haetaan tietoja... ")]
              )
            : _vm._e(),
        ],
        1
      ),
      !_vm.pdfLataamassa
        ? _c(
            "v-row",
            {
              staticClass: "px-0 mt-2 mx-auto mb-0",
              attrs: { justify: "center" },
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mt-1",
                  attrs: { icon: "" },
                  on: { click: _vm.pienennaSivunumeroa },
                },
                [
                  _c("v-icon", { attrs: { color: "primary" } }, [
                    _vm._v("remove_circle"),
                  ]),
                ],
                1
              ),
              _c("v-text-field", {
                staticClass: "pdf-modal__input pa-0",
                attrs: {
                  type: "number",
                  suffix: `/ ${_vm.sivujaYhtTeksti}`,
                  "hide-details": "",
                },
                model: {
                  value: _vm.nykyinenSivu,
                  callback: function ($$v) {
                    _vm.nykyinenSivu = _vm._n($$v)
                  },
                  expression: "nykyinenSivu",
                },
              }),
              _c(
                "v-btn",
                {
                  staticClass: "mt-1",
                  attrs: { icon: "" },
                  on: { click: _vm.kasvataSivunumeroa },
                },
                [
                  _c("v-icon", { attrs: { color: "primary" } }, [
                    _vm._v("add_circle"),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        {
          staticClass: "px-0 pb-4 mx-auto mb-0",
          class: _vm.$vuetify.breakpoint.smAndDown ? "mt-n8" : "mt-n9",
          attrs: {
            justify: _vm.$vuetify.breakpoint.smAndDown
              ? "space-between"
              : "end",
          },
        },
        [
          _c(
            "v-btn",
            {
              staticClass: "mr-4",
              attrs: {
                color: "primary",
                small: _vm.$vuetify.breakpoint.smAndDown,
                rounded: "",
              },
              on: { click: _vm.lataaPdf },
            },
            [_vm._v(" Lataa ")]
          ),
          _c(
            "v-btn",
            {
              attrs: {
                color: "primary",
                small: _vm.$vuetify.breakpoint.smAndDown,
                rounded: "",
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("sulje")
                },
              },
            },
            [_vm._v(" Sulje ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }