export default {
  namespaced: true,
  state: {},
  actions: {
    async haeSaldotodistus({ state }) {
      return await this._vm.$api.withAuth.request({
        method: "GET",
        responseType: "arraybuffer",
        url: "saldotodistus/"
      });
    }
  }
};
