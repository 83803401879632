<template>
  <v-menu
    v-model="naytetaanko"
    :position-x="positionX"
    :position-y="positionY"
    absolute
    offset-y
    @update:return-value="$emit('update:return-value')">
    <v-list>
      <v-list-item
        v-for="vaihtoehto in vaihtoehdot"
        :key="vaihtoehto.nimi"
        @click="$emit(vaihtoehto.event, vaihtoehto)">
        <v-list-item-avatar v-if="vaihtoehto.icon" size="24">
          <v-icon>{{ vaihtoehto.icon }}</v-icon>
        </v-list-item-avatar>
        <v-list-item-title>
          <span :class="vaihtoehto.onOtsikko ? 'font-weight-medium' : ''">
            {{ vaihtoehto.nimi }}
          </span>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "MenuContext",
  props: {
    onkoNakyvissa: {
      type: Boolean,
      required: false,
      default() {
        return false;
      }
    },
    positionX: {
      type: Number,
      required: true
    },
    positionY: {
      type: Number,
      required: true
    },
    vaihtoehdot: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      naytetaanko: this.onkoNakyvissa
    };
  },
  watch: {
    onkoNakyvissa() {
      this.naytetaanko = this.onkoNakyvissa;
    }
  }
};
</script>

<style lang="scss" scoped></style>
