var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "viesti-kortti rounded-lg",
      attrs: {
        width: _vm.$vuetify.breakpoint.xs ? "85vw" : 562,
        elevation: "2",
      },
    },
    [
      _c(
        "v-row",
        { staticClass: "justify-space-between" },
        [
          _c(
            "v-col",
            { attrs: { cols: "9" } },
            [
              _c("v-card-title", [
                _c(
                  "h5",
                  [
                    _vm.tapaus.tyyppi === "Lasku"
                      ? [
                          _vm._v(" Lasku: "),
                          _c(
                            "span",
                            { staticClass: "app-overflow-break-word" },
                            [_vm._v(_vm._s(_vm.tapaus.nro))]
                          ),
                        ]
                      : [
                          _vm._v(" Perintätoimeksianto "),
                          _c("br"),
                          _vm._v(" Asianumero: "),
                          _c(
                            "span",
                            { staticClass: "app-overflow-break-word" },
                            [
                              _vm.envMaaritysInstanceClass === "creditvisor"
                                ? _c("span", [_vm._v("A")])
                                : _vm._e(),
                              _vm._v(" " + _vm._s(_vm.tapaus.id) + " "),
                            ]
                          ),
                        ],
                  ],
                  2
                ),
              ]),
              _c("v-card-subtitle", [
                _vm._v(_vm._s(_vm.paamiehenRooli) + ": " + _vm._s(_vm.paamies)),
              ]),
              _c("v-card-subtitle", { staticClass: "mt-n4 pt-0" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$date(
                        new Date(_vm.tapaus.viimeisin_viesti),
                        "d.M.yyyy H:mm"
                      )
                    ) +
                    " "
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _c(
                "v-avatar",
                {
                  staticClass: "ma-4 float-right",
                  attrs: { color: _vm.ikoni.variAvatar, size: "52" },
                },
                [
                  _c(
                    "v-icon",
                    { attrs: { color: _vm.ikoni.vari, large: "" } },
                    [_vm._v("message")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-divider", { staticClass: "mx-4 my-0" }),
      _c(
        "v-expansion-panels",
        { attrs: { flat: "" } },
        [
          _c(
            "v-expansion-panel",
            [
              _c(
                "v-expansion-panel-header",
                {
                  staticClass: "px-4 viesti-kortti__esikatselu",
                  on: { click: _vm.kasitteleViestipaneelinAvaaminen },
                },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "viesti-kortti__esikatselu__row",
                      class: _vm.$vuetify.breakpoint.xs
                        ? "viesti-kortti__esikatselu__row--xs"
                        : "viesti-kortti__esikatselu__row--sm-and-up",
                    },
                    [
                      _c("v-col", { attrs: { cols: "11" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "viesti-kortti__esikatselu__teksti",
                            class: !_vm.viestiketjuLuettu
                              ? "viesti-kortti__esikatselu__teksti--uusia-viesteja"
                              : "",
                          },
                          [_vm._v(" " + _vm._s(_vm.esikatseluTeksti) + " ")]
                        ),
                      ]),
                    ],
                    1
                  ),
                  !_vm.viestiketjuLuettu
                    ? _c("v-badge", {
                        attrs: {
                          color: "info",
                          dot: "",
                          "offset-y": "-4",
                          "offset-x": "-16",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-expansion-panel-content",
                { staticClass: "viesti-kortti__viestipaneeli" },
                [
                  _vm._l(_vm.jarjestetytViestit, function (viesti) {
                    return [
                      _c("v-divider", {
                        key: "divider-" + viesti.luotu,
                        staticClass: "my-2",
                      }),
                      _c(
                        "v-card-subtitle",
                        {
                          key: "subtitle-" + viesti.luotu,
                          staticClass: "mx-0 px-0 py-2",
                        },
                        [
                          viesti.luettu === null && viesti.muu_kayttaja === ""
                            ? _c(
                                "v-badge",
                                {
                                  attrs: { color: "info", dot: "", inline: "" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$date(
                                          new Date(viesti.luotu),
                                          "d.M.yyyy H:mm"
                                        )
                                      ) +
                                      " - " +
                                      _vm._s(_vm.maaritaLahettaja(viesti)) +
                                      " "
                                  ),
                                ]
                              )
                            : _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$date(
                                        new Date(viesti.luotu),
                                        "d.M.yyyy H:mm"
                                      )
                                    ) +
                                    " - " +
                                    _vm._s(_vm.maaritaLahettaja(viesti)) +
                                    " "
                                ),
                              ]),
                        ],
                        1
                      ),
                      _c(
                        "span",
                        {
                          key: "span-" + viesti.luotu,
                          staticClass:
                            "viesti-kortti__viestipaneeli__viestiteksti",
                        },
                        [_vm._v(" " + _vm._s(viesti.viesti) + " ")]
                      ),
                    ]
                  }),
                  _c("v-divider", { staticClass: "my-2" }),
                  _c(
                    "v-form",
                    {
                      ref: "lahetaVastausviestiForm",
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.lahetaVastausviesti.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("v-textarea", {
                        ref: "viestiInput",
                        attrs: {
                          label: "",
                          rows: "1",
                          "hide-details": true,
                          "auto-grow": "",
                          placeholder: "Kirjoita vastaus",
                          "validate-on-blur": false,
                          loading: _vm.lahettamassaViestia,
                        },
                        model: {
                          value: _vm.vastausviesti,
                          callback: function ($$v) {
                            _vm.vastausviesti = $$v
                          },
                          expression: "vastausviesti",
                        },
                      }),
                      _c("v-checkbox", {
                        staticClass: "mt-1",
                        attrs: {
                          label:
                            "Lähetä tieto sähköpostiin kun viestiin vastataan",
                        },
                        model: {
                          value: _vm.chkEmailherate,
                          callback: function ($$v) {
                            _vm.chkEmailherate = $$v
                          },
                          expression: "chkEmailherate",
                        },
                      }),
                      _vm.chkEmailherate
                        ? _c("v-text-field", {
                            attrs: {
                              label: "Sähköpostiosoite",
                              rules: [
                                _vm.$validointiSaannot.pakollinen,
                                _vm.$validointiSaannot.email,
                              ],
                            },
                            model: {
                              value: _vm.emailherate,
                              callback: function ($$v) {
                                _vm.emailherate = $$v
                              },
                              expression: "emailherate",
                            },
                          })
                        : _vm._e(),
                      _c(
                        "v-row",
                        {
                          staticClass: "mt-4 mb-0 mx-n4 px-4",
                          attrs: { justify: "end" },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                rounded: "",
                                disabled:
                                  !_vm.vastausviesti ||
                                  (_vm.chkEmailherate && !_vm.emailherate),
                                type: "submit",
                              },
                            },
                            [_vm._v(" Lähetä ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }