var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass:
        "tapaus-kortti tapaus-kortti--toimeksianto rounded-lg rounded-t-xl pa-1",
      attrs: {
        width: _vm.$vuetify.breakpoint.xs ? "85vw" : 562,
        elevation: "5",
      },
    },
    [
      _vm.vuexModuuli !== "liikasuoritukset"
        ? _c(
            "div",
            [
              _c(
                "CardDialog",
                {
                  attrs: {
                    otsikko: "Eräpäivän siirtopyyntö",
                    auki: _vm.erapaivanSiirtoLomakeAuki,
                    width: _vm.$vuetify.breakpoint.xs ? "85vw" : "320px",
                  },
                  on: {
                    sulje: function ($event) {
                      _vm.erapaivanSiirtoLomakeAuki = false
                    },
                  },
                },
                [
                  _c("ErapaivanSiirtoLomake", {
                    attrs: {
                      "lomake-data": _vm.erapaivanSiirtoLomakeData,
                      lataamassa: _vm.erapaivanSiirtoLomakeLataamassa,
                      tapaustyyppi: "toimeksiannot",
                    },
                    on: {
                      submit: _vm.toimeksiannotLahetaErapaivanSiirtopyynto,
                    },
                  }),
                ],
                1
              ),
              _c(
                "CardDialog",
                {
                  attrs: {
                    otsikko: _vm.teeMaksusopimusTeksti,
                    auki: _vm.maksusopimusLomakeAuki,
                    width: _vm.$vuetify.breakpoint.xs ? "85vw" : "400px",
                  },
                  on: {
                    sulje: function ($event) {
                      _vm.maksusopimusLomakeAuki = false
                    },
                  },
                },
                [
                  _c("ToimeksiantoMaksusopimusLomake", {
                    attrs: {
                      "lomake-data": _vm.maksusopimusLomakeData,
                      lataamassa: _vm.maksusopimusLomakeLataamassa,
                    },
                    on: {
                      sulje: function ($event) {
                        _vm.maksusopimusLomakeAuki = false
                      },
                      submit: _vm.lahetaMaksusopimus,
                    },
                  }),
                ],
                1
              ),
              _vm.toimeksianto.maksusopimus
                ? _c(
                    "CardDialog",
                    {
                      attrs: {
                        otsikko: _vm.maksusopimuksenTilaTeksti,
                        auki: _vm.maksusopimusKatsoDialogAuki,
                        width: _vm.$vuetify.breakpoint.xs ? "85vw" : "400px",
                      },
                      on: {
                        sulje: function ($event) {
                          _vm.maksusopimusKatsoDialogAuki = false
                        },
                      },
                    },
                    [
                      _c("ToimeksiantoMaksusopimusKatso", {
                        attrs: {
                          toimeksianto: _vm.toimeksianto,
                          "maksusopimus-maksettava-era":
                            _vm.maksusopimusMaksettavaEra,
                        },
                        on: {
                          sulje: function ($event) {
                            _vm.maksusopimusKatsoDialogAuki = false
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("MenuContext", {
                attrs: {
                  "onko-nakyvissa": _vm.menuContextNakyvissa,
                  "position-x": _vm.menuContextX,
                  "position-y": _vm.menuContextY,
                  vaihtoehdot: _vm.menuContextKirjeet,
                },
                on: {
                  "update:return-value": _vm.suljeMenuContext,
                  lataaKirje: _vm.haeKirje,
                },
              }),
              _c("MenuContext", {
                attrs: {
                  "onko-nakyvissa": _vm.menuContextNakyvissa2,
                  "position-x": _vm.menuContextX,
                  "position-y": _vm.menuContextY,
                  vaihtoehdot: _vm.menuContextLaskuliitteet,
                },
                on: {
                  "update:return-value": _vm.suljeMenuContext,
                  lataaKirje: _vm.haeToimeksiantoLaskuliite,
                },
              }),
            ],
            1
          )
        : _vm.vuexModuuli === "liikasuoritukset"
        ? _c(
            "CardDialog",
            {
              attrs: {
                otsikko: "Liikasuoritus",
                auki: _vm.toimeksiantoLiikasuoritusLomakeAuki,
                width: _vm.$vuetify.breakpoint.xs ? "85vw" : "320px",
              },
              on: {
                sulje: function ($event) {
                  _vm.toimeksiantoLiikasuoritusLomakeAuki = false
                },
              },
            },
            [
              !_vm.toimeksiantoLiikasuoritusLomakeData.liikasuoritus ||
              !_vm.toimeksiantoLiikasuoritusLomakeData.liikasuoritus
                .kohdeasia_id ||
              _vm.toimeksiantoLiikasuoritusLomakeData.liikasuoritus
                .kohdeasia_id == "0"
                ? _c("LiikasuoritusLomake", {
                    attrs: {
                      "lomake-data": _vm.toimeksiantoLiikasuoritusLomakeData,
                      lataamassa: _vm.toimeksiantoLiikasuoritusLomakeLataamassa,
                      tapaustyyppi: "toimeksiannot",
                    },
                    on: { lahetaLiikasuoritus: _vm.lahetaLiikasuoritus },
                  })
                : _c("LiikasuorituskohdistusLomake", {
                    attrs: {
                      "lomake-data": _vm.toimeksiantoLiikasuoritusLomakeData,
                      lataamassa: _vm.toimeksiantoLiikasuoritusLomakeLataamassa,
                      tapaustyyppi: "toimeksiannot",
                    },
                    on: {
                      lahetaLiikasuoritus: _vm.lahetaLiikasuoritus,
                      lahetaKohdistusLiikasuoritus:
                        _vm.lahetaKohdistusLiikasuoritus,
                    },
                  }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        { staticClass: "justify-space-between" },
        [
          _c(
            "v-col",
            { staticClass: "pb-0", attrs: { cols: "9" } },
            [
              _c("v-card-title", { staticClass: "pb-0" }, [
                _c("h5", [
                  _vm._v(" Perintätoimeksianto "),
                  _c("br"),
                  _vm._v(" Asianumero: "),
                  _c("span", { staticClass: "app-overflow-break-word" }, [
                    _vm.envMaaritysInstanceClass === "creditvisor"
                      ? _c("span", [_vm._v("A")])
                      : _vm._e(),
                    _vm._v(" " + _vm._s(_vm.toimeksianto.id) + " "),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pb-0", attrs: { cols: "3" } },
            [
              _c("v-tooltip", {
                attrs: { top: "", "open-on-click": "", "open-on-focus": "" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function ({ on }) {
                      return [
                        _c(
                          "v-avatar",
                          _vm._g(
                            {
                              staticClass: "mx-4 mt-4 float-right",
                              attrs: {
                                color: _vm.ikoni.variAvatar,
                                size: "52",
                              },
                            },
                            on
                          ),
                          [
                            _c(
                              "v-icon",
                              { attrs: { color: _vm.ikoni.vari, large: "" } },
                              [_vm._v(" " + _vm._s(_vm.ikoni.nimi) + " ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function () {
                      return [_vm._v(" " + _vm._s(_vm.tilaUi) + " ")]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "py-0 my-0", attrs: { cols: "12" } },
            [
              _c("v-card-subtitle", { staticClass: "pt-0" }, [
                _vm._v(
                  " Toimeksiantaja: " +
                    _vm._s(_vm.toimeksianto.paamies.nimi) +
                    " "
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-divider", { staticClass: "mx-4 my-2" }),
      _c(
        "v-list",
        [
          _vm.maksusopimusAvoinna &&
          _vm.tilaUi !== _vm.MAKSETTU &&
          _vm.tilaUi !== _vm.PERUTTU
            ? _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-title",
                    { staticClass: "tapaus-kortti__list-item__title" },
                    [_vm._v(" " + _vm._s(_vm.maksusopimusTeksti) + " ")]
                  ),
                  _c(
                    "v-list-item-subtitle",
                    {
                      staticClass:
                        "tapaus-kortti__list-item__subtitle text-right",
                    },
                    [_vm._v(" Aktiivinen ")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-list-item",
            [
              _c(
                "v-list-item-title",
                { staticClass: "tapaus-kortti__list-item__title" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.maksusopimusAvoinna
                          ? "Seuraava eräpäivä"
                          : "Eräpäivä"
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "v-list-item-subtitle",
                {
                  staticClass: "tapaus-kortti__list-item__subtitle text-right",
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.naytettavaErapaiva
                          ? _vm.$date(new Date(_vm.naytettavaErapaiva))
                          : "HETI"
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm.maksusopimusAvoinna &&
          _vm.tilaUi !== _vm.MAKSETTU &&
          _vm.tilaUi !== _vm.PERUTTU
            ? _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-title",
                    { staticClass: "tapaus-kortti__list-item__title" },
                    [_vm._v(" Maksettava ")]
                  ),
                  _c(
                    "v-list-item-subtitle",
                    {
                      staticClass:
                        "tapaus-kortti__list-item__subtitle text-right",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.maksusopimusMaksettavaEra
                              ? _vm.$n(
                                  Number(_vm.maksusopimusMaksettavaEra.avoinna),
                                  "currency"
                                )
                              : "-"
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-list-item",
            [
              _c(
                "v-list-item-title",
                { staticClass: "tapaus-kortti__list-item__title" },
                [_vm._v(" Asian tila ")]
              ),
              _c(
                "v-list-item-subtitle",
                {
                  staticClass: "tapaus-kortti__list-item__subtitle text-right",
                },
                [_vm._v(" " + _vm._s(_vm.tilaUi) + " ")]
              ),
            ],
            1
          ),
          _c(
            "v-list-item",
            [
              _c(
                "v-list-item-title",
                { staticClass: "tapaus-kortti__list-item__title" },
                [_vm._v(" Avoinna ")]
              ),
              _c(
                "v-list-item-subtitle",
                {
                  staticClass: "tapaus-kortti__list-item__subtitle text-right",
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$n(Number(_vm.toimeksianto.avoinna), "currency")
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm.vuexModuuli !== "liikasuoritukset"
            ? _c(
                "div",
                [
                  _c("v-list-item", [_c("v-divider")], 1),
                  _c(
                    "v-list-item",
                    {
                      staticClass: "my-4",
                      class: _vm.$vuetify.breakpoint.xs
                        ? "justify-center"
                        : "justify-end",
                    },
                    [
                      _c("TapausKorttiBtn", {
                        attrs: { teksti: "Perintäkirjeet", ikoni: "download" },
                        on: { click: _vm.avaaMenuContext },
                      }),
                    ],
                    1
                  ),
                  _vm.toimeksianto.laskuliite_set &&
                  _vm.toimeksianto.laskuliite_set.length > 0
                    ? [
                        _c(
                          "v-list-item",
                          {
                            staticClass: "my-4",
                            class: _vm.$vuetify.breakpoint.xs
                              ? "justify-center"
                              : "justify-end",
                          },
                          [
                            _c("TapausKorttiBtn", {
                              attrs: {
                                teksti: "Laskuliitteet",
                                ikoni: "download",
                              },
                              on: { click: _vm.avaaMenuContext2 },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _vm.tilaUi === _vm.PERINNASSA
                    ? [
                        _c(
                          "v-list-item",
                          {
                            staticClass: "my-4",
                            class: _vm.$vuetify.breakpoint.xs
                              ? "justify-center"
                              : "justify-end",
                          },
                          [
                            _c("TapausKorttiBtn", {
                              attrs: {
                                teksti: _vm.toimeksianto
                                  .onko_erapaivaa_siirretty
                                  ? "Eräpäivää on siirretty"
                                  : "Siirrä eräpäivää",
                                ikoni: _vm.toimeksianto.onko_erapaivaa_siirretty
                                  ? ""
                                  : "arrow_right_alt",
                                disabled:
                                  _vm.toimeksianto.onko_erapaivaa_siirretty,
                              },
                              on: {
                                click: _vm.kasitteleSiirraErapaivaaBtnClick,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-list-item",
                          {
                            staticClass: "my-4",
                            class: _vm.$vuetify.breakpoint.xs
                              ? "justify-center"
                              : "justify-end",
                          },
                          [
                            _vm.toimeksianto.maksusopimus
                              ? _c("TapausKorttiBtn", {
                                  attrs: {
                                    teksti: _vm.katsoMaksusopimusTeksti,
                                    ikoni: "view_agenda",
                                  },
                                  on: {
                                    click: _vm.avaaMaksusopimusKatsoDialog,
                                  },
                                })
                              : _c("TapausKorttiBtn", {
                                  attrs: {
                                    teksti: _vm.teeMaksusopimusTeksti,
                                    ikoni: "view_agenda",
                                    disabled: _vm.maksusopimusEstetty,
                                  },
                                  on: {
                                    click: _vm.kasitteleTeeMaksusopimusBtnClick,
                                  },
                                }),
                          ],
                          1
                        ),
                        _c(
                          "v-list-item",
                          {
                            staticClass: "my-4",
                            class: _vm.$vuetify.breakpoint.xs
                              ? "justify-center"
                              : "justify-end",
                          },
                          [
                            _c("PaytrailLomake", {
                              attrs: {
                                "toimeksianto-id": _vm.toimeksianto.id,
                                avoinna: Number(_vm.toimeksianto.avoinna),
                                "avoinna-era": Number(
                                  _vm.maksusopimusMaksettavaEra
                                    ? parseFloat(
                                        _vm.maksusopimusMaksettavaEra.avoinna
                                      )
                                    : 0
                                ),
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  [
                    _vm.PERINNASSA,
                    _vm.OIKEUDELLISESSA_PERINNASSA,
                    _vm.ULOSOTOSSA,
                  ].includes(_vm.tilaUi)
                    ? [
                        _c(
                          "v-list-item",
                          {
                            staticClass: "my-4",
                            class: _vm.$vuetify.breakpoint.xs
                              ? "justify-center"
                              : "justify-end",
                          },
                          [
                            _c("TapausKorttiBtn", {
                              attrs: {
                                teksti: "Uusi viesti",
                                ikoni: "message",
                              },
                              on: { click: _vm.siirryViestitValilehdelle },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _vm.tilaUi === _vm.PERINNASSA
                    ? [
                        _vm.toimeksiantoreklamaationappi
                          ? _c(
                              "v-list-item",
                              {
                                staticClass: "my-4",
                                class: _vm.$vuetify.breakpoint.xs
                                  ? "justify-center"
                                  : "justify-end",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex",
                                    class: _vm.$vuetify.breakpoint.xs
                                      ? "tapaus-kortti__btn-with-icon--xs"
                                      : "tapaus-kortti__btn-with-icon--sm-and-up",
                                  },
                                  [
                                    _c("TapausKorttiBtn", {
                                      attrs: {
                                        teksti: "Tee reklamaatio",
                                        href:
                                          _vm.toimeksiantoreklamaationappi.url +
                                          "?asianumero=" +
                                          _vm.toimeksianto.saatavarivi_set[0]
                                            .laskunumero,
                                        "custom-classes": "flex-grow-1",
                                        target: "_blank",
                                        ikoni: "sentiment_very_dissatisfied",
                                      },
                                    }),
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: {
                                          top: "",
                                          "open-on-hover": false,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          icon: "",
                                                          color: "error",
                                                          "retain-focus-on-click":
                                                            "",
                                                        },
                                                        on: {
                                                          click: on.click,
                                                          blur: on.blur,
                                                        },
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v("info"),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          218126657
                                        ),
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.toimeksiantoreklamaationappi
                                                .infoteksti
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _vm.vuexModuuli === "liikasuoritukset"
            ? _c(
                "v-list-item",
                {
                  staticClass: "my-4",
                  class: _vm.$vuetify.breakpoint.xs
                    ? "justify-center"
                    : "justify-end",
                },
                [
                  _vm.toimeksianto.liikasuoritustila ===
                  _vm.yleinen.liikasuoritustilaTiedotPuuttuu
                    ? _c("TapausKorttiTooltipBtn", {
                        attrs: {
                          teksti: "Palauta tilitiedot",
                          ikoni: "send",
                          disabled: _vm.toimeksianto.disabled,
                          "tooltip-teksti":
                            "Tästä avautuu lomake, jolla voit antaa palautukseen tarvittavat tiedot",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.avaaLiikasuoritus(
                              _vm.toimeksianto.menuContextLiikasuoritukset[0]
                            )
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.toimeksianto.liikasuoritustila ===
                  _vm.yleinen.liikasuoritustilaKasittelyssa
                    ? _c("span", [
                        _vm._v(
                          " Tilitiedot palautettu. Rahat palautetaan 1-2 viikon kuluttua. "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("div", {
        staticClass: "tapaus-kortti__vari-ilmaisin mt-4",
        class: _vm.variIlmaisinLuokka,
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }