<template>
  <v-menu transition="slide-y-transition" nudge-bottom="40">
    <template #activator="{ on }">
      <div class="loggedInUser__wrap my-6 px-2" v-on="on">
        <div
          ref="colContent"
          class="loggedInUser__col loggedInUser__col--content"
          :class="
            envMaaritysInstanceClass === 'uuva'
              ? 'loggedInUser__col--content--white'
              : ''
          ">
          <span v-if="!$store.getters['yleinen/kirjauduttuKirjeella']()">
            {{ kayttajaNimi }}
          </span>
          <span v-else>Olet kirjautunut kirjeellä</span>
        </div>
        <div class="loggedInUser__col loggedInUser__col--avatar">
          <span
            class="loggedInUser__iconWrap"
            :class="
              envMaaritysInstanceClass === 'uuva'
                ? 'loggedInUser__iconWrap--white'
                : ''
            ">
            <v-icon
              class="loggedInUser__icon"
              :class="
                envMaaritysInstanceClass === 'uuva'
                  ? 'loggedInUser__icon--white'
                  : ''
              ">
              person
            </v-icon>
          </span>
        </div>
        <div class="loggedInUser__col loggedInUser__col--actions">
          <v-icon>arrow_drop_down</v-icon>
        </div>
      </div>
    </template>
    <template #default>
      <v-list>
        <v-list-item
          v-if="!$store.getters['yleinen/kirjauduttuKirjeella']()"
          :disabled="
            !$store.state.yleinen.velallistiedot ||
            !$store.state.yleinen.velallistiedot.id
          "
          @click="$emit('avaa-omat-tiedot')">
          <v-list-item-title>Omat tiedot</v-list-item-title>
          <v-list-item-avatar>
            <v-icon class="material-icons-outlined">info</v-icon>
          </v-list-item-avatar>
        </v-list-item>
        <v-list-item
          v-else-if="envMaaritysInstanceClass !== 'ijcapital'"
          @click="$emit('tunnistaudu')">
          <v-list-item-title>Tunnistaudu</v-list-item-title>
          <v-list-item-avatar>
            <v-icon class="material-icons-outlined">login</v-icon>
          </v-list-item-avatar>
        </v-list-item>
        <v-list-item @click="$emit('kirjaudu-ulos')">
          <v-list-item-title>Kirjaudu ulos</v-list-item-title>
          <v-list-item-avatar>
            <v-icon>logout</v-icon>
          </v-list-item-avatar>
        </v-list-item>
      </v-list>
    </template>
  </v-menu>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "LoggedInUser",
  computed: {
    ...mapState({
      envMaaritysInstanceClass() {
        return process.env.VUE_APP_INSTANCE_CLASS || "";
      },
      kayttajaNimi: (state) => state.yleinen.tunnistettuKayttajaNimi
    })
  }
};
</script>

<style lang="scss" scoped>
.loggedInUser {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  &__wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    max-width: 360px;
    cursor: pointer;
  }

  &__col {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;

    &--content {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: calc(1vw * 22);
      color: var(--grey);
      text-align: left;

      &--white {
        color: white;
      }
    }

    &--avatar {
      margin: 0 4px 0 8px;
    }
  }

  &__iconWrap {
    overflow: hidden;
    position: relative;
    display: inline-block;
    width: 1.8rem;
    height: 1.8rem;
    border: solid 2px lighten(#000, 50);
    border-radius: 10rem;

    &--white {
      border: solid 2px white;
    }
  }

  &__icon {
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: 0;
    padding: 0;
    color: lighten(#000, 50);
    transform: translateY(-40%) translateX(-50%) scale(1.3);

    &--white {
      color: white;
    }
  }
}
</style>
