<template>
  <div>
    <ToimeksiantoMaksusopimusEraTaulukko
      :maksuerat="toimeksianto.maksusopimus.maksusopimusera_erittely" />
    <v-row
      v-if="toimeksianto.maksusopimus.avoinna"
      class="ma-0 mt-4"
      justify="end">
      <PaytrailLomake
        :toimeksianto-id="toimeksianto.id"
        :avoinna="Number(toimeksianto.avoinna)"
        :avoinna-era="
          Number(
            maksusopimusMaksettavaEra
              ? parseFloat(maksusopimusMaksettavaEra.avoinna)
              : 0
          )
        " />
    </v-row>
    <v-row v-else class="ma-0 mt-4" justify="center">
      <p class="text-subtitle-1">Kaikki erät ovat maksettu.</p>
    </v-row>
    <v-row class="ma-0 mt-0 py-4" justify="end">
      <v-btn class="ml-4" color="primary" rounded @click="$emit('sulje')">
        Sulje
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import PaytrailLomake from "@/components/PaytrailLomake";
import ToimeksiantoMaksusopimusEraTaulukko from "@/components/ToimeksiantoMaksusopimusEraTaulukko";

export default {
  name: "ToimeksiantoMaksusopimusKatso",
  components: {
    PaytrailLomake,
    ToimeksiantoMaksusopimusEraTaulukko
  },
  props: {
    maksusopimusMaksettavaEra: {
      type: [Object, null],
      required: false,
      default: null
    },
    toimeksianto: {
      type: Object,
      required: true
    }
  }
};
</script>

<style></style>
