var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "business-footer" },
    [
      _c("div", { class: _vm.footerWrapClass }, [
        _c(
          "div",
          { class: _vm.colClass },
          [
            _vm.envMaaritysFooterTextLeftColumn
              ? [
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v(
                      " " + _vm._s(_vm.envMaaritysFooterTextLeftColumn) + " "
                    ),
                  ]),
                  _c("v-divider", { staticClass: "my-2", attrs: { dark: "" } }),
                ]
              : _vm._e(),
            _vm.envMaaritysEmail || _vm.envMaaritysTel
              ? _c("p", [
                  _vm.envMaaritysEmail
                    ? _c(
                        "a",
                        { attrs: { href: "mailto:" + _vm.envMaaritysEmail } },
                        [_vm._v(" " + _vm._s(_vm.envMaaritysEmail) + " ")]
                      )
                    : _vm._e(),
                  _vm.envMaaritysEmail && _vm.envMaaritysTel
                    ? _c("span", [_c("br")])
                    : _vm._e(),
                  _vm.envMaaritysTel
                    ? _c(
                        "a",
                        { attrs: { href: `tel:${_vm.envMaaritysTel}` } },
                        [_vm._v(" " + _vm._s(_vm.envMaaritysTel) + " ")]
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
          ],
          2
        ),
        _c(
          "div",
          { class: _vm.colClass },
          [
            _vm.envMaaritysFooterTextMiddleColumn
              ? [
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v(
                      " " + _vm._s(_vm.envMaaritysFooterTextMiddleColumn) + " "
                    ),
                  ]),
                  _c("v-divider", { staticClass: "my-2", attrs: { dark: "" } }),
                ]
              : _vm._e(),
            _vm.envMaaritysInstanceClass === "creditvisor"
              ? [
                  _c("a", { attrs: { href: "https://www.creditvisor.fi" } }, [
                    _vm._v("CreditVisor.fi"),
                  ]),
                  _c(
                    "a",
                    {
                      on: {
                        click: function ($event) {
                          _vm.lisatietoaPerinnastaDialogAuki = true
                        },
                      },
                    },
                    [_vm._v(" Lisätietoa perinnästä ")]
                  ),
                  _c(
                    "a",
                    {
                      on: {
                        click: function ($event) {
                          _vm.tietosuojaselosteDialogAuki = true
                        },
                      },
                    },
                    [_vm._v("Tietosuojaseloste")]
                  ),
                ]
              : _vm._e(),
          ],
          2
        ),
        _c(
          "div",
          { class: _vm.colClass },
          [
            _vm.envMaaritysFooterTextRightColumn
              ? [
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v(
                      " " + _vm._s(_vm.envMaaritysFooterTextRightColumn) + " "
                    ),
                  ]),
                  _c("v-divider", { staticClass: "my-2", attrs: { dark: "" } }),
                ]
              : _vm._e(),
            _vm.envMaaritysAddress ||
            _vm.envMaaritysPostal ||
            _vm.envMaaritysLocality
              ? _c(
                  "p",
                  [
                    _vm.envMaaritysAddress
                      ? _c("span", [_vm._v(_vm._s(_vm.envMaaritysAddress))])
                      : _vm._e(),
                    _vm.envMaaritysAddress &&
                    (_vm.envMaaritysPostal || _vm.envMaaritysLocality)
                      ? _c("span", [_vm._v(" , ")])
                      : _vm._e(),
                    _vm.envMaaritysPostal
                      ? _c("span", [_vm._v(_vm._s(_vm.envMaaritysPostal))])
                      : _vm._e(),
                    _vm.envMaaritysLocality
                      ? _c("span", [_vm._v(_vm._s(_vm.envMaaritysLocality))])
                      : _vm._e(),
                    _vm.envMaaritysBusinessId
                      ? [
                          _c("br"),
                          _vm._v(
                            " Y-tunnus: " +
                              _vm._s(_vm.envMaaritysBusinessId) +
                              " "
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                )
              : _vm._e(),
            _vm.envMaaritysCopyrightText
              ? _c("p", [
                  _vm._v(" " + _vm._s(_vm.envMaaritysCopyrightText) + " "),
                ])
              : _vm._e(),
          ],
          2
        ),
      ]),
      _vm.envMaaritysInstanceClass === "creditvisor"
        ? [
            _c("PdfDialog", {
              attrs: {
                auki: _vm.tietosuojaselosteDialogAuki,
                "paikallinen-url": _vm.tietosuojaselostePaikallinenUrl,
                "tiedoston-nimi": "Oma CreditVisor - Tietosuojaseloste.pdf",
              },
              on: {
                sulje: function ($event) {
                  _vm.tietosuojaselosteDialogAuki = false
                },
              },
            }),
            _c("PdfDialog", {
              attrs: {
                auki: _vm.lisatietoaPerinnastaDialogAuki,
                "paikallinen-url": _vm.lisatietoaPerinnastaPaikallinenUrl,
                "tiedoston-nimi": "Oma CreditVisor - Lisätietoa perinnästä.pdf",
              },
              on: {
                sulje: function ($event) {
                  _vm.lisatietoaPerinnastaDialogAuki = false
                },
              },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }