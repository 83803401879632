var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("h1", { class: _vm.titleClass }, [
    _vm._v(" " + _vm._s(_vm.teksti) + " "),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }