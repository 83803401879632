const TILA_LUONNOS = "D";
const TILA_LAHETTAMATTA = "L";
const TILA_AVOINNA = "A";
const TILA_MAKSETTU = "M";
const TILA_PERINNASSA = "P";
const TILA_KESKEYTETTY = "R";
const TILA_PERUTTU = "X";
const TILA_ERAANNYTETTY = "E";
const TILA_KUITTAUKSESSA = "K";
const TILA_MYYTY = "Y";

const Lasku = class {
  static get TILA_LUONNOS() {
    return TILA_LUONNOS;
  }

  static get TILA_LAHETTAMATTA() {
    return TILA_LAHETTAMATTA;
  }

  static get TILA_AVOINNA() {
    return TILA_AVOINNA;
  }

  static get TILA_MAKSETTU() {
    return TILA_MAKSETTU;
  }

  static get TILA_PERINNASSA() {
    return TILA_PERINNASSA;
  }

  static get TILA_KESKEYTETTY() {
    return TILA_KESKEYTETTY;
  }

  static get TILA_PERUTTU() {
    return TILA_PERUTTU;
  }

  static get TILA_ERAANNYTETTY() {
    return TILA_ERAANNYTETTY;
  }

  static get TILA_KUITTAUKSESSA() {
    return TILA_KUITTAUKSESSA;
  }

  static get TILA_MYYTY() {
    return TILA_MYYTY;
  }

  static get TILAT_AVOINNA() {
    return [
      Lasku.TILA_AVOINNA,
      Lasku.TILA_KESKEYTETTY,
      Lasku.TILA_KUITTAUKSESSA
    ];
  }

  static get TILAT_MAKSETTU() {
    return [Lasku.TILA_MAKSETTU, Lasku.TILA_MYYTY];
  }
};

export default Lasku;
