var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-tooltip", {
    attrs: { top: "" },
    scopedSlots: _vm._u(
      [
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "div",
                _vm._g({}, on),
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "justify-space-between",
                      class: _vm.$vuetify.breakpoint.xs
                        ? "tapaus-kortti__btn--xs"
                        : "tapaus-kortti__btn--sm-and-up",
                      attrs: {
                        color: "primary",
                        disabled: _vm.disabled,
                        rounded: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("click", $event)
                        },
                      },
                    },
                    [
                      _c("span", [_vm._v(_vm._s(_vm.teksti))]),
                      _c("v-icon", { staticClass: "ml-2" }, [
                        _vm._v(" " + _vm._s(_vm.ikoni) + " "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
        },
        _vm.tooltipTeksti
          ? {
              key: "default",
              fn: function () {
                return [_vm._v(" " + _vm._s(_vm.tooltipTeksti) + " ")]
              },
              proxy: true,
            }
          : null,
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }