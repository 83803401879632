import Vue from "vue";
const ibantools = require("ibantools");

const validointiSaannot = {
  arrayVahimmaispituus: (arvo, kynnysarvo = 1) =>
    (Array.isArray(arvo) && arvo.length >= kynnysarvo) ||
    `Syötä vähintään ${kynnysarvo}`,
  email: (arvo) =>
    /.+@.+/.test(arvo) ||
    arvo === "" ||
    "Tarkista sähköpostiosoitteen muotoilu",
  liianMontaMerkkia: (arvo, kynnysarvo) =>
    arvo <= kynnysarvo || `Sallittu merkkimäärä (${kynnysarvo}) ylitetty`,
  kokonaisluku: (arvo) =>
    Number.isInteger(Number(arvo)) || "Arvon tulee olla kokonaisluku",
  nollaTaiSuurempi: (arvo) => arvo >= 0 || "Arvon tulee olla 0 tai suurempi",
  numero: (arvo) => !isNaN(parseInt(arvo)) || "Syötä numero",
  numeroTaiTyhja: (arvo) =>
    arvo === undefined || !isNaN(arvo) || "Syötä numero tai jätä tyhjäksi",
  pakollinen: (arvo) => !!arvo || "Pakollinen tieto puuttuu",
  pakollinenLoyha: (arvo) =>
    (arvo !== null && arvo !== undefined && arvo !== "") ||
    "Pakollinen tieto puuttuu",
  pakollinenObject: (arvo) =>
    (arvo && Object.keys(arvo).length !== 0) || "Pakollinen tieto puuttuu",
  puhelinnumero: (arvo) =>
    /^\+?([\d\s]){4,}$/.test(arvo) ||
    arvo === "" ||
    arvo === null ||
    "Tarkista puhelinnumero",
  pienempiTaiYhtaIsoKuin: (arvo, kynnysarvo) =>
    parseInt(arvo) <= kynnysarvo ||
    `Arvo ei voi olla suurempi kuin ${kynnysarvo}`,
  suurempiTaiYhtaIsoKuin: (arvo, kynnysarvo) =>
    parseInt(arvo) >= kynnysarvo ||
    `Arvo ei voi olla pienempi kuin ${kynnysarvo}`,
  suurempiKuinNolla: (arvo) => arvo > 0 || "Arvon tulee olla suurempi kuin 0",
  vahimmaispituus: (arvo, kynnysarvo = 1) =>
    arvo.length >= kynnysarvo || `Syötä vähintään ${kynnysarvo} merkkiä`,
  isValidIBAN: (v) =>
    ibantools.isValidIBAN(typeof v === "string" ? v.replace(/\s+/g, "") : v) ||
    "Epäkelpo IBAN",
  isValidBIC: (v) => ibantools.isValidBIC(v) || "Epäkelpo BIC"
};

const plugin = {
  install() {
    Vue.validointiSaannot = validointiSaannot;
    Vue.prototype.$validointiSaannot = validointiSaannot;
  }
};

Vue.use(plugin);

export default validointiSaannot;
