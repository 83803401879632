<template>
  <div class="lataa-lisaa-scroll-btn">
    <p
      class="text-body-1 text-center mb-0 lataa-lisaa-scroll-btn__teksti"
      @click="ilmoitaPainalluksesta">
      Lataa lisää
    </p>
    <v-btn
      class="lataa-lisaa-scroll-btn__btn"
      text
      @click="ilmoitaPainalluksesta">
      <v-icon large color="primary">expand_more</v-icon>
    </v-btn>
    <div v-observe-visibility="ilmoitaNakyvyydesta" class="pt-1" />
  </div>
</template>

<script>
export default {
  name: "LataaLisaaScrollBtn",
  methods: {
    ilmoitaPainalluksesta() {
      this.$emit("btn-click");
    },
    ilmoitaNakyvyydesta(nakyvissa) {
      this.$emit("nakyvyysmuutos", nakyvissa);
    }
  }
};
</script>

<style lang="scss">
.lataa-lisaa-scroll-btn {
  .lataa-lisaa-scroll-btn__teksti {
    color: var(--grey);
  }

  .lataa-lisaa-scroll-btn__btn {
    display: block;
    margin: 0 auto;
  }
}
</style>
