<template>
  <v-card
    :width="$vuetify.breakpoint.xs ? '85vw' : 562"
    class="viesti-kortti rounded-lg"
    elevation="2">
    <v-row class="justify-space-between">
      <v-col cols="9">
        <v-card-title>
          <h5>
            <template v-if="tapaus.tyyppi === 'Lasku'">
              Lasku:
              <span class="app-overflow-break-word">{{ tapaus.nro }}</span>
            </template>
            <template v-else>
              Perintätoimeksianto
              <br />
              Asianumero:
              <span class="app-overflow-break-word">
                <span v-if="envMaaritysInstanceClass === 'creditvisor'">A</span>
                {{ tapaus.id }}
              </span>
            </template>
          </h5>
        </v-card-title>
        <v-card-subtitle>{{ paamiehenRooli }}: {{ paamies }}</v-card-subtitle>
        <v-card-subtitle class="mt-n4 pt-0">
          {{ $date(new Date(tapaus.viimeisin_viesti), "d.M.yyyy H:mm") }}
        </v-card-subtitle>
      </v-col>
      <v-col cols="3">
        <v-avatar class="ma-4 float-right" :color="ikoni.variAvatar" size="52">
          <v-icon :color="ikoni.vari" large>message</v-icon>
        </v-avatar>
      </v-col>
    </v-row>
    <v-divider class="mx-4 my-0" />
    <v-expansion-panels flat>
      <v-expansion-panel>
        <v-expansion-panel-header
          class="px-4 viesti-kortti__esikatselu"
          @click="kasitteleViestipaneelinAvaaminen">
          <v-row
            class="viesti-kortti__esikatselu__row"
            :class="
              $vuetify.breakpoint.xs
                ? 'viesti-kortti__esikatselu__row--xs'
                : 'viesti-kortti__esikatselu__row--sm-and-up'
            ">
            <v-col cols="11">
              <div
                class="viesti-kortti__esikatselu__teksti"
                :class="
                  !viestiketjuLuettu
                    ? 'viesti-kortti__esikatselu__teksti--uusia-viesteja'
                    : ''
                ">
                {{ esikatseluTeksti }}
              </div>
            </v-col>
          </v-row>
          <v-badge
            v-if="!viestiketjuLuettu"
            color="info"
            dot
            offset-y="-4"
            offset-x="-16"></v-badge>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="viesti-kortti__viestipaneeli">
          <template v-for="viesti in jarjestetytViestit">
            <v-divider
              :key="'divider-' + viesti.luotu"
              class="my-2"></v-divider>
            <v-card-subtitle
              :key="'subtitle-' + viesti.luotu"
              class="mx-0 px-0 py-2">
              <v-badge
                v-if="viesti.luettu === null && viesti.muu_kayttaja === ''"
                color="info"
                dot
                inline>
                {{ $date(new Date(viesti.luotu), "d.M.yyyy H:mm") }} -
                {{ maaritaLahettaja(viesti) }}
              </v-badge>
              <span v-else>
                {{ $date(new Date(viesti.luotu), "d.M.yyyy H:mm") }} -
                {{ maaritaLahettaja(viesti) }}
              </span>
            </v-card-subtitle>
            <span
              :key="'span-' + viesti.luotu"
              class="viesti-kortti__viestipaneeli__viestiteksti">
              {{ viesti.viesti }}
            </span>
          </template>
          <v-divider class="my-2"></v-divider>
          <v-form
            ref="lahetaVastausviestiForm"
            @submit.prevent="lahetaVastausviesti">
            <v-textarea
              ref="viestiInput"
              v-model="vastausviesti"
              class=""
              label=""
              rows="1"
              :hide-details="true"
              auto-grow
              placeholder="Kirjoita vastaus"
              :validate-on-blur="false"
              :loading="lahettamassaViestia" />
            <v-checkbox
              v-model="chkEmailherate"
              class="mt-1"
              label="Lähetä tieto sähköpostiin kun viestiin vastataan" />
            <v-text-field
              v-if="chkEmailherate"
              v-model="emailherate"
              label="Sähköpostiosoite"
              :rules="[
                $validointiSaannot.pakollinen,
                $validointiSaannot.email
              ]" />
            <v-row class="mt-4 mb-0 mx-n4 px-4" justify="end">
              <v-btn
                color="primary"
                rounded
                :disabled="!vastausviesti || (chkEmailherate && !emailherate)"
                type="submit">
                Lähetä
              </v-btn>
            </v-row>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import Toimeksianto from "@/classes/Toimeksianto";

const PERINNASSA = "Perinnässä";
const MAKSETTU = "Maksettu";
const PERUTTU = "Peruttu";
const ULOSOTOSSA = "Ulosotossa";

export default {
  name: "ViestiKortti",
  components: {},
  props: {
    tapaus: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    PERINNASSA,
    jarjestetytViestit: [],
    lahettamassaViestia: false,
    vastausviesti: "",
    viestiketjuLuettu: false,
    chkEmailherate: false,
    emailherate: ""
  }),
  computed: {
    ...mapState(["yleinen"]),
    envMaaritysInstanceClass() {
      return process.env.VUE_APP_INSTANCE_CLASS || "";
    },
    envMaaritysCompanyName() {
      return process.env.VUE_APP_COMPANY_NAME || "";
    },
    ikoni() {
      const ikoni = {
        vari: "white",
        variAvatar: "primary",
        nimi: "article"
      };

      if (this.tilaUi === PERINNASSA) {
        ikoni.nimi = "article";
      } else if (this.tilaUi === MAKSETTU) {
        ikoni.nimi = "check_circle";
        ikoni.variAvatar = "success";
      } else if (this.tilaUi === PERUTTU) {
        ikoni.nimi = "cancel";
        ikoni.variAvatar = "grey darken-3";
      } else if (this.tilaUi === ULOSOTOSSA) {
        ikoni.variAvatar = "red";
      }

      return ikoni;
    },
    tilaUi() {
      const tila = this.tapaus.tila;
      if (Toimeksianto.TILAT_PERINNASSA.includes(tila)) {
        return PERINNASSA;
      } else if (Toimeksianto.TILAT_MAKSETTU.includes(tila)) {
        return MAKSETTU;
      } else if (Toimeksianto.TILAT_PERUTTU.includes(tila)) {
        return PERUTTU;
      } else if (tila === Toimeksianto.TILA_ULOSOTTOPERINTA) {
        return ULOSOTOSSA;
      } else {
        return PERINNASSA;
      }
    },
    esikatseluTeksti() {
      return this.tapaus.portaaliviesti_set[
        this.tapaus.portaaliviesti_set.length - 1
      ].viesti;
    },
    paamies() {
      return this.tapaus.portaaliviesti_set[0].paamies.nimi;
    },
    paamiehenRooli() {
      return this.tapaus.tyyppi === "Lasku" ? "Laskuttaja" : "Toimeksiantaja";
    },
    lukemattomiaViesteja() {
      return this.tapaus.portaaliviesti_set.some((viesti) => {
        // Ei näytetä käyttäjän itsensä lähettämiä viestejä uusina.
        return viesti.muu_kayttaja === "" && viesti.luettu === null;
      });
    },
    kirjauduttuKirjeella() {
      return this.$store.getters["yleinen/kirjauduttuKirjeella"]();
    }
  },
  created() {
    if (!this.lukemattomiaViesteja) {
      this.viestiketjuLuettu = true;
    }
    this.jarjestaViestit();
  },
  async mounted() {
    if (!this.yleinen.velallistiedot && !this.kirjauduttuKirjeella) {
      await this.$store.dispatch("yleinen/haeVelallistiedot");
    }
    this.emailherate = this.yleinen.velallistiedot?.emailosoite?.email || "";
  },
  methods: {
    maaritaLahettaja(viesti) {
      if (!viesti.muu_kayttaja) {
        return this.envMaaritysCompanyName || "";
      } else {
        return viesti.muu_kayttaja;
      }
    },
    jarjestaViestit() {
      this.jarjestetytViestit = this.tapaus.portaaliviesti_set.sort((a, b) => {
        let tulos = 0;
        const dateA = new Date(a.luotu);
        const dateB = new Date(b.luotu);
        if (dateA > dateB) tulos = 1;
        else if (dateA < dateB) tulos = -1;
        return tulos;
      });
    },
    async kasitteleViestipaneelinAvaaminen() {
      if (this.viestiketjuLuettu) return;

      await this.merkitseViestitLuetuiksi();
      this.viestiketjuLuettu = true;
    },
    merkitseViestitLuetuiksi() {
      return Promise.all(
        this.tapaus.portaaliviesti_set.map(async (viesti) => {
          if (!viesti.muu_kayttaja && !viesti.luettu) {
            await this.$store.dispatch(
              "viestit/merkitseViestiLuetuksi",
              viesti
            );
            this.$store.dispatch("viestit/haeLukemattomatKpl");
          }
        })
      );
    },
    async lahetaVastausviesti() {
      try {
        if (
          this.chkEmailherate &&
          !this.$refs.lahetaVastausviestiForm.validate()
        )
          return;
        this.lahettamassaViestia = true;
        const data = this.koostaVastausviestiData();
        if (this.chkEmailherate && !this.kirjauduttuKirjeella) {
          const jatketaanko = await this.$store.dispatch(
            "yleinen/kysyEmailJaPaivita",
            this.emailherate
          );
          if (!jatketaanko) return;
        }
        const tulos = await this.$store.dispatch("viestit/lahetaViesti", data);

        this.vastausviesti = "";
        this.tapaus.portaaliviesti_set = this.tapaus.portaaliviesti_set.map(
          (viesti) => {
            viesti.luettu = true;
            return viesti;
          }
        );
        this.tapaus.portaaliviesti_set.push(tulos.data);
        this.jarjestaViestit();
      } catch (e) {
        this.$oletusVirheenkasittely(e);
      } finally {
        this.lahettamassaViestia = false;
      }
    },
    koostaVastausviestiData() {
      const viimeisinViesti =
        this.jarjestetytViestit[this.jarjestetytViestit.length - 1];
      const data = {
        aihe: viimeisinViesti.aihe,
        vastaus_id: viimeisinViesti.id,
        viesti: this.vastausviesti
      };
      if (this.tapaus.tyyppi === "Lasku") {
        data.lasku_id = this.tapaus.id;
      } else {
        data.toimeksianto_id = this.tapaus.id;
      }
      if (this.chkEmailherate) {
        data.emailherate = this.emailherate;
      }

      return data;
    }
  }
};
</script>

<style lang="scss">
.v-card.viesti-kortti {
  .viesti-kortti__esikatselu {
    &--uusia-viesteja {
      background-color: #f1edfd;
    }

    .viesti-kortti__esikatselu__row {
      &--xs {
        width: 80%;
      }

      &--sm-and-up {
        width: 524px;
      }
    }

    .viesti-kortti__esikatselu__teksti {
      overflow: hidden;
      white-space: nowrap;
      color: var(--grey);

      &--uusia-viesteja {
        font-weight: bold;
      }
    }
  }

  .viesti-kortti__viestipaneeli {
    .v-expansion-panel-content__wrap {
      padding: 0 16px 16px 16px;
    }

    .viesti-kortti__viestipaneeli__viestiteksti {
      font-size: 0.9375rem;
    }

    .viesti-kortti__viestipaneeli__uusi-viesti {
      background-color: #f1edfd;
    }
  }
}
</style>
