<template>
  <div>
    <p class="mt-8 text-body-1 text-center app-animoitu-gradientti">
      Käsitellään kirjautumista...
    </p>
  </div>
</template>

<script>
export default {
  name: "KirjauduUudelleenohjaus",
  created() {
    this.kasitteleTokenitJaKirjaaSisaan();
  },
  methods: {
    async kasitteleTokenitJaKirjaaSisaan() {
      this.loading = true;

      try {
        const access_token = new URL(location.href).searchParams.get("access");
        const refresh_token = new URL(location.href).searchParams.get(
          "refresh"
        );

        if (!refresh_token) {
          this.$router.replace({
            name: "Kirjaudu"
          });

          return;
        }

        localStorage.setItem("accessToken", access_token);
        localStorage.setItem("refreshToken", refresh_token);

        this.$router.replace({
          name: "Yhteenveto"
        });
      } catch (e) {
        this.$sentryCaptureCustom(e);
        this.$naytaVaroitusilmoitus(
          "Uudelleenohjaus ei onnistunut. Ole hyvä ja kirjaudu sisään."
        );
        this.$router.replace({
          name: "Kirjaudu"
        });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
