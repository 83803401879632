var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("ToimeksiantoMaksusopimusEraTaulukko", {
        attrs: {
          maksuerat: _vm.toimeksianto.maksusopimus.maksusopimusera_erittely,
        },
      }),
      _vm.toimeksianto.maksusopimus.avoinna
        ? _c(
            "v-row",
            { staticClass: "ma-0 mt-4", attrs: { justify: "end" } },
            [
              _c("PaytrailLomake", {
                attrs: {
                  "toimeksianto-id": _vm.toimeksianto.id,
                  avoinna: Number(_vm.toimeksianto.avoinna),
                  "avoinna-era": Number(
                    _vm.maksusopimusMaksettavaEra
                      ? parseFloat(_vm.maksusopimusMaksettavaEra.avoinna)
                      : 0
                  ),
                },
              }),
            ],
            1
          )
        : _c(
            "v-row",
            { staticClass: "ma-0 mt-4", attrs: { justify: "center" } },
            [
              _c("p", { staticClass: "text-subtitle-1" }, [
                _vm._v("Kaikki erät ovat maksettu."),
              ]),
            ]
          ),
      _c(
        "v-row",
        { staticClass: "ma-0 mt-0 py-4", attrs: { justify: "end" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "ml-4",
              attrs: { color: "primary", rounded: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("sulje")
                },
              },
            },
            [_vm._v(" Sulje ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }