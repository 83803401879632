export default {
  namespaced: true,
  state: {
    kirjeId: "",
    kirjeToken: "",
    suoraanMaksamaan: false
  },
  getters: {
    kirjeId(state) {
      return state.kirjeId || localStorage.getItem("kirjeId") || "";
    },
    kirjeToken(state) {
      return state.kirjeToken || localStorage.getItem("kirjeToken") || "";
    }
  },
  mutations: {
    asetaKirjeId(state, kirjeId) {
      localStorage.setItem("kirjeId", kirjeId);
      state.kirjeId = kirjeId;
    },
    asetaKirjeToken(state, kirjeToken) {
      localStorage.setItem("kirjeToken", kirjeToken);
      state.kirjeToken = kirjeToken;
    },
    asetaSuoraanMaksamaan(state, arvo) {
      state.suoraanMaksamaan = arvo;
    }
  },
  actions: {
    async kirjauduKirjeella(
      { commit, getters },
      { suoraanMaksamaan } = { suoraanMaksamaan: false }
    ) {
      await this._vm.$api.withAuth.request({
        method: "GET",
        url: `kirje_auth/kirje/auth/${getters.kirjeId}/`,
        headers: {
          "X-Auth-Kirje-Id": getters.kirjeId,
          "X-Auth-Kirje-Token": getters.kirjeToken
        }
      });

      commit("asetaSuoraanMaksamaan", suoraanMaksamaan);
      commit("yleinen/asetaKirjauduttuKirjeella", true, { root: true });
    },
    async tyhjennaKirjetiedot({ commit }) {
      commit("asetaKirjeId", "");
      commit("asetaKirjeToken", "");
      commit("yleinen/asetaKirjauduttuKirjeella", false, { root: true });
    },
    async luoKirjeenTapauksenPaytrailMaksu({ dispatch, rootGetters }) {
      const maksutiedot =
        rootGetters["avoimetAsiat/kirjeenTapauksenMaksutiedot"];
      if (!maksutiedot) {
        // Kirjeen tapauksen maksutietoja ei löytynyt
        return null;
      }

      return await dispatch("paytrail/luoPaytrailMaksu", maksutiedot, {
        root: true
      });
    }
  }
};
