<template>
  <v-card
    :width="$vuetify.breakpoint.xs ? '85vw' : 562"
    class="tapaus-kortti tapaus-kortti--toimeksianto rounded-lg rounded-t-xl pa-1"
    elevation="5">
    <!-- Dialogit  -->
    <div v-if="vuexModuuli !== 'liikasuoritukset'">
      <CardDialog
        otsikko="Eräpäivän siirtopyyntö"
        :auki="erapaivanSiirtoLomakeAuki"
        :width="$vuetify.breakpoint.xs ? '85vw' : '320px'"
        @sulje="erapaivanSiirtoLomakeAuki = false">
        <ErapaivanSiirtoLomake
          :lomake-data="erapaivanSiirtoLomakeData"
          :lataamassa="erapaivanSiirtoLomakeLataamassa"
          tapaustyyppi="toimeksiannot"
          @submit="toimeksiannotLahetaErapaivanSiirtopyynto" />
      </CardDialog>

      <CardDialog
        :otsikko="teeMaksusopimusTeksti"
        :auki="maksusopimusLomakeAuki"
        :width="$vuetify.breakpoint.xs ? '85vw' : '400px'"
        @sulje="maksusopimusLomakeAuki = false">
        <ToimeksiantoMaksusopimusLomake
          :lomake-data="maksusopimusLomakeData"
          :lataamassa="maksusopimusLomakeLataamassa"
          @sulje="maksusopimusLomakeAuki = false"
          @submit="lahetaMaksusopimus" />
      </CardDialog>

      <CardDialog
        v-if="toimeksianto.maksusopimus"
        :otsikko="maksusopimuksenTilaTeksti"
        :auki="maksusopimusKatsoDialogAuki"
        :width="$vuetify.breakpoint.xs ? '85vw' : '400px'"
        @sulje="maksusopimusKatsoDialogAuki = false">
        <ToimeksiantoMaksusopimusKatso
          :toimeksianto="toimeksianto"
          :maksusopimus-maksettava-era="maksusopimusMaksettavaEra"
          @sulje="maksusopimusKatsoDialogAuki = false" />
      </CardDialog>

      <MenuContext
        :onko-nakyvissa="menuContextNakyvissa"
        :position-x="menuContextX"
        :position-y="menuContextY"
        :vaihtoehdot="menuContextKirjeet"
        @update:return-value="suljeMenuContext"
        @lataaKirje="haeKirje" />

      <MenuContext
        :onko-nakyvissa="menuContextNakyvissa2"
        :position-x="menuContextX"
        :position-y="menuContextY"
        :vaihtoehdot="menuContextLaskuliitteet"
        @update:return-value="suljeMenuContext"
        @lataaKirje="haeToimeksiantoLaskuliite" />
    </div>

    <CardDialog
      v-else-if="vuexModuuli === 'liikasuoritukset'"
      otsikko="Liikasuoritus"
      :auki="toimeksiantoLiikasuoritusLomakeAuki"
      :width="$vuetify.breakpoint.xs ? '85vw' : '320px'"
      @sulje="toimeksiantoLiikasuoritusLomakeAuki = false">
      <LiikasuoritusLomake
        v-if="
          !toimeksiantoLiikasuoritusLomakeData.liikasuoritus ||
          !toimeksiantoLiikasuoritusLomakeData.liikasuoritus.kohdeasia_id ||
          toimeksiantoLiikasuoritusLomakeData.liikasuoritus.kohdeasia_id == '0'
        "
        :lomake-data="toimeksiantoLiikasuoritusLomakeData"
        :lataamassa="toimeksiantoLiikasuoritusLomakeLataamassa"
        tapaustyyppi="toimeksiannot"
        @lahetaLiikasuoritus="lahetaLiikasuoritus" />
      <LiikasuorituskohdistusLomake
        v-else
        :lomake-data="toimeksiantoLiikasuoritusLomakeData"
        :lataamassa="toimeksiantoLiikasuoritusLomakeLataamassa"
        tapaustyyppi="toimeksiannot"
        @lahetaLiikasuoritus="lahetaLiikasuoritus"
        @lahetaKohdistusLiikasuoritus="lahetaKohdistusLiikasuoritus" />
    </CardDialog>
    <!-- Kortin sisältö -->
    <v-row class="justify-space-between">
      <v-col cols="9" class="pb-0">
        <v-card-title class="pb-0">
          <h5>
            Perintätoimeksianto
            <br />
            Asianumero:
            <span class="app-overflow-break-word">
              <span v-if="envMaaritysInstanceClass === 'creditvisor'">A</span>
              {{ toimeksianto.id }}
            </span>
          </h5>
        </v-card-title>
      </v-col>
      <v-col cols="3" class="pb-0">
        <v-tooltip top open-on-click open-on-focus>
          <template #activator="{ on }">
            <v-avatar
              class="mx-4 mt-4 float-right"
              :color="ikoni.variAvatar"
              size="52"
              v-on="on">
              <v-icon :color="ikoni.vari" large>
                {{ ikoni.nimi }}
              </v-icon>
            </v-avatar>
          </template>
          <template #default>
            {{ tilaUi }}
          </template>
        </v-tooltip>
      </v-col>
      <v-col cols="12" class="py-0 my-0">
        <v-card-subtitle class="pt-0">
          Toimeksiantaja: {{ toimeksianto.paamies.nimi }}
        </v-card-subtitle>
      </v-col>
    </v-row>
    <v-divider class="mx-4 my-2" />
    <v-list>
      <v-list-item
        v-if="maksusopimusAvoinna && tilaUi !== MAKSETTU && tilaUi !== PERUTTU">
        <v-list-item-title class="tapaus-kortti__list-item__title">
          {{ maksusopimusTeksti }}
        </v-list-item-title>
        <v-list-item-subtitle
          class="tapaus-kortti__list-item__subtitle text-right">
          Aktiivinen
        </v-list-item-subtitle>
      </v-list-item>
      <v-list-item>
        <v-list-item-title class="tapaus-kortti__list-item__title">
          {{ maksusopimusAvoinna ? "Seuraava eräpäivä" : "Eräpäivä" }}
        </v-list-item-title>
        <v-list-item-subtitle
          class="tapaus-kortti__list-item__subtitle text-right">
          {{
            naytettavaErapaiva ? $date(new Date(naytettavaErapaiva)) : "HETI"
          }}
        </v-list-item-subtitle>
      </v-list-item>
      <v-list-item
        v-if="maksusopimusAvoinna && tilaUi !== MAKSETTU && tilaUi !== PERUTTU">
        <v-list-item-title class="tapaus-kortti__list-item__title">
          Maksettava
        </v-list-item-title>
        <v-list-item-subtitle
          class="tapaus-kortti__list-item__subtitle text-right">
          {{
            maksusopimusMaksettavaEra
              ? $n(Number(maksusopimusMaksettavaEra.avoinna), "currency")
              : "-"
          }}
        </v-list-item-subtitle>
      </v-list-item>
      <v-list-item>
        <v-list-item-title class="tapaus-kortti__list-item__title">
          Asian tila
        </v-list-item-title>
        <v-list-item-subtitle
          class="tapaus-kortti__list-item__subtitle text-right">
          {{ tilaUi }}
        </v-list-item-subtitle>
      </v-list-item>
      <v-list-item>
        <v-list-item-title class="tapaus-kortti__list-item__title">
          Avoinna
        </v-list-item-title>
        <v-list-item-subtitle
          class="tapaus-kortti__list-item__subtitle text-right">
          {{ $n(Number(toimeksianto.avoinna), "currency") }}
        </v-list-item-subtitle>
      </v-list-item>
      <div v-if="vuexModuuli !== 'liikasuoritukset'">
        <v-list-item>
          <v-divider />
        </v-list-item>
        <v-list-item
          class="my-4"
          :class="$vuetify.breakpoint.xs ? 'justify-center' : 'justify-end'">
          <TapausKorttiBtn
            teksti="Perintäkirjeet"
            ikoni="download"
            @click="avaaMenuContext" />
        </v-list-item>
        <template
          v-if="
            toimeksianto.laskuliite_set &&
            toimeksianto.laskuliite_set.length > 0
          ">
          <v-list-item
            class="my-4"
            :class="$vuetify.breakpoint.xs ? 'justify-center' : 'justify-end'">
            <TapausKorttiBtn
              teksti="Laskuliitteet"
              ikoni="download"
              @click="avaaMenuContext2" />
          </v-list-item>
        </template>
        <template v-if="tilaUi === PERINNASSA">
          <v-list-item
            class="my-4"
            :class="$vuetify.breakpoint.xs ? 'justify-center' : 'justify-end'">
            <TapausKorttiBtn
              :teksti="
                toimeksianto.onko_erapaivaa_siirretty
                  ? 'Eräpäivää on siirretty'
                  : 'Siirrä eräpäivää'
              "
              :ikoni="
                toimeksianto.onko_erapaivaa_siirretty ? '' : 'arrow_right_alt'
              "
              :disabled="toimeksianto.onko_erapaivaa_siirretty"
              @click="kasitteleSiirraErapaivaaBtnClick" />
          </v-list-item>
          <v-list-item
            class="my-4"
            :class="$vuetify.breakpoint.xs ? 'justify-center' : 'justify-end'">
            <TapausKorttiBtn
              v-if="toimeksianto.maksusopimus"
              :teksti="katsoMaksusopimusTeksti"
              ikoni="view_agenda"
              @click="avaaMaksusopimusKatsoDialog" />
            <TapausKorttiBtn
              v-else
              :teksti="teeMaksusopimusTeksti"
              ikoni="view_agenda"
              :disabled="maksusopimusEstetty"
              @click="kasitteleTeeMaksusopimusBtnClick" />
          </v-list-item>
          <v-list-item
            class="my-4"
            :class="$vuetify.breakpoint.xs ? 'justify-center' : 'justify-end'">
            <PaytrailLomake
              :toimeksianto-id="toimeksianto.id"
              :avoinna="Number(toimeksianto.avoinna)"
              :avoinna-era="
                Number(
                  maksusopimusMaksettavaEra
                    ? parseFloat(maksusopimusMaksettavaEra.avoinna)
                    : 0
                )
              " />
          </v-list-item>
        </template>
        <template
          v-if="
            [PERINNASSA, OIKEUDELLISESSA_PERINNASSA, ULOSOTOSSA].includes(
              tilaUi
            )
          ">
          <v-list-item
            class="my-4"
            :class="$vuetify.breakpoint.xs ? 'justify-center' : 'justify-end'">
            <TapausKorttiBtn
              teksti="Uusi viesti"
              ikoni="message"
              @click="siirryViestitValilehdelle" />
          </v-list-item>
        </template>
        <template v-if="tilaUi === PERINNASSA">
          <v-list-item
            v-if="toimeksiantoreklamaationappi"
            class="my-4"
            :class="$vuetify.breakpoint.xs ? 'justify-center' : 'justify-end'">
            <div
              class="d-flex"
              :class="
                $vuetify.breakpoint.xs
                  ? 'tapaus-kortti__btn-with-icon--xs'
                  : 'tapaus-kortti__btn-with-icon--sm-and-up'
              ">
              <!-- Asianumeron lisäys on kovakoodattu, joten jos reklamointilinkki lisätään
              jollekin muulle päämiehelle, ratkaisua pitää kehittää -->
              <TapausKorttiBtn
                teksti="Tee reklamaatio"
                :href="
                  toimeksiantoreklamaationappi.url +
                  '?asianumero=' +
                  toimeksianto.saatavarivi_set[0].laskunumero
                "
                custom-classes="flex-grow-1"
                target="_blank"
                ikoni="sentiment_very_dissatisfied" />
              <v-tooltip top :open-on-hover="false">
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="error"
                    v-bind="attrs"
                    retain-focus-on-click
                    @click="on.click"
                    @blur="on.blur">
                    <v-icon>info</v-icon>
                  </v-btn>
                </template>
                <span>{{ toimeksiantoreklamaationappi.infoteksti }}</span>
              </v-tooltip>
            </div>
          </v-list-item>
        </template>
      </div>
      <v-list-item
        v-else-if="vuexModuuli === 'liikasuoritukset'"
        class="my-4"
        :class="$vuetify.breakpoint.xs ? 'justify-center' : 'justify-end'">
        <TapausKorttiTooltipBtn
          v-if="
            toimeksianto.liikasuoritustila ===
            yleinen.liikasuoritustilaTiedotPuuttuu
          "
          teksti="Palauta tilitiedot"
          ikoni="send"
          :disabled="toimeksianto.disabled"
          tooltip-teksti="Tästä avautuu lomake, jolla voit antaa palautukseen tarvittavat tiedot"
          @click="
            avaaLiikasuoritus(toimeksianto.menuContextLiikasuoritukset[0])
          " />
        <span
          v-if="
            toimeksianto.liikasuoritustila ===
            yleinen.liikasuoritustilaKasittelyssa
          ">
          Tilitiedot palautettu. Rahat palautetaan 1-2 viikon kuluttua.
        </span>
      </v-list-item>
    </v-list>
    <div
      class="tapaus-kortti__vari-ilmaisin mt-4"
      :class="variIlmaisinLuokka"></div>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import Toimeksianto from "@/classes/Toimeksianto";
import ErapaivanSiirtoLomake from "@/components/ErapaivanSiirtoLomake";
import LiikasuoritusLomake from "@/components/LiikasuoritusLomake";
import LiikasuorituskohdistusLomake from "@/components/LiikasuorituskohdistusLomake";
import MenuContext from "@/components/MenuContext";
import TapausKorttiBtn from "@/components/TapausKorttiBtn";
import TapausKorttiTooltipBtn from "@/components/TapausKorttiTooltipBtn";
import ToimeksiantoMaksusopimusKatso from "@/components/ToimeksiantoMaksusopimusKatso";
import ToimeksiantoMaksusopimusLomake from "@/components/ToimeksiantoMaksusopimusLomake";
import PaytrailLomake from "@/components/PaytrailLomake";
import MenuContextMixin from "@/mixins/MenuContextMixin";

const PERINNASSA = "Perinnässä";
const OIKEUDELLISESSA_PERINNASSA = "Oikeudellisessa perinnässä";
const MAKSETTU = "Maksettu";
const PERUTTU = "Peruttu";
const ULOSOTOSSA = "Ulosotossa";

export default {
  name: "ToimeksiantoKortti",
  components: {
    PaytrailLomake,
    ErapaivanSiirtoLomake,
    LiikasuoritusLomake,
    LiikasuorituskohdistusLomake,
    MenuContext,
    TapausKorttiBtn,
    TapausKorttiTooltipBtn,
    ToimeksiantoMaksusopimusKatso,
    ToimeksiantoMaksusopimusLomake
  },
  mixins: [MenuContextMixin],
  props: {
    toimeksianto: {
      type: Object,
      required: true
    },
    vuexModuuli: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      toimeksiantoLiikasuoritusLomakeAuki: false,
      toimeksiantoLiikasuoritusLomakeData: {
        toimeksianto: this.toimeksianto
      },
      toimeksiantoLiikasuoritusLomakeLataamassa: false,
      erapaivanSiirtoLomakeAuki: false,
      erapaivanSiirtoLomakeData: { toimeksiantoId: this.toimeksianto.id },
      erapaivanSiirtoLomakeLataamassa: false,
      maksusopimusKatsoDialogAuki: false,
      maksusopimusLomakeAuki: false,
      maksusopimusLomakeData: {
        erat: 2,
        erapaiva: 1,
        toimeksiantoId: this.toimeksianto.id,
        velallistyyppi: this.toimeksianto.velallistyyppi
      },
      maksusopimusLomakeLataamassa: false,
      envPaamiesConfs: {},
      MAKSETTU,
      OIKEUDELLISESSA_PERINNASSA,
      PERINNASSA,
      PERUTTU,
      ULOSOTOSSA
    };
  },
  computed: {
    ...mapState(["yleinen", "liikasuoritukset"]),
    envMaaritysInstanceClass() {
      return process.env.VUE_APP_INSTANCE_CLASS || "";
    },
    ikoni() {
      const ikoni = {
        vari: "white",
        variAvatar: "primary",
        nimi: "assignment_late"
      };

      if ([PERINNASSA, OIKEUDELLISESSA_PERINNASSA].includes(this.tilaUi)) {
        ikoni.variAvatar = "perinnassa";
      } else if (this.tilaUi === MAKSETTU) {
        ikoni.nimi = "check_circle";
        ikoni.variAvatar = "success";
      } else if (this.tilaUi === PERUTTU) {
        ikoni.nimi = "cancel";
        ikoni.variAvatar = "grey darken-3";
      } else if (this.tilaUi === ULOSOTOSSA) {
        ikoni.variAvatar = "ulosotossa";
      }

      return ikoni;
    },
    tilaUi() {
      const tila = this.toimeksianto.tila;
      if (tila === Toimeksianto.TILA_VAPAAEHTOINEN_PERINTA) {
        return PERINNASSA;
      } else if (tila === Toimeksianto.TILA_OIKEUDELLINEN_PERINTA) {
        return OIKEUDELLISESSA_PERINNASSA;
      } else if (Toimeksianto.TILAT_MAKSETTU.includes(tila)) {
        return MAKSETTU;
      } else if (Toimeksianto.TILAT_PERUTTU.includes(tila)) {
        return PERUTTU;
      } else if (tila === Toimeksianto.TILA_ULOSOTTOPERINTA) {
        return ULOSOTOSSA;
      } else {
        return PERINNASSA;
      }
    },
    variIlmaisinLuokka() {
      if (this.tilaUi === PERINNASSA) {
        return "tapaus-kortti__vari-ilmaisin--toimeksianto--perinnassa";
      } else if (this.tilaUi === ULOSOTOSSA) {
        return "tapaus-kortti__vari-ilmaisin--toimeksianto--ulosotossa";
      } else {
        return "";
      }
    },
    menuContextKirjeet() {
      const kirjeSet = this.toimeksianto.lahetetty_kirje_set.map((kirje) => {
        return {
          icon: "download",
          id: kirje.id,
          lahetysaika: kirje.lahetysaika,
          nimi: `Perintäkirje ${this.$date(new Date(kirje.lahetysaika))}`,
          event: "lataaKirje"
        };
      });
      if (kirjeSet.length) {
        if (kirjeSet.length > 1) {
          return this.menuContextRakennaMoniKirjeSet(kirjeSet);
        } else {
          return kirjeSet;
        }
      } else {
        return [
          {
            nimi: "Ei perintäkirjeitä"
          }
        ];
      }
    },
    menuContextLaskuliitteet() {
      if (!this.toimeksianto || !this.toimeksianto.laskuliite_set) {
        return [
          {
            nimi: "Ei laskuliitteitä"
          }
        ];
      }
      const liiteSet = this.toimeksianto.laskuliite_set.map((liite) => {
        return {
          icon: "download",
          id: liite.id,
          luotu: liite.luotu,
          nimi: liite.nimi,
          liiteNimi: liite.nimi,
          event: "lataaKirje"
        };
      });
      if (liiteSet.length) {
        return liiteSet;
      } else {
        return [
          {
            nimi: "Ei laskuliitteitä"
          }
        ];
      }
    },
    naytettavaErapaiva() {
      const era = this.maksusopimusMaksettavaEra;
      if (era !== null) {
        return era.erapaiva;
      }
      return this.toimeksianto.erapaiva;
    },
    teeMaksusopimusTeksti() {
      if (this.envMaaritysInstanceClass === "creditvisor") {
        return this.$vuetify.breakpoint.xs
          ? "Maksusuunnitelma"
          : "Tee maksusuunnitelma";
      } else {
        return "Tee maksusopimus";
      }
    },
    maksusopimusEstetty() {
      return (
        this.toimeksianto.paamies.esta_maksusopimuksen_teko_portaali ||
        this.toimeksianto.maksusopimus_count > 0
      );
    },
    katsoMaksusopimusTeksti() {
      if (this.envMaaritysInstanceClass === "creditvisor") {
        return this.$vuetify.breakpoint.xs
          ? "Maksusuunnitelma"
          : "Ks. maksusuunnitelma";
      } else {
        return this.$vuetify.breakpoint.xs
          ? "Maksusopimus"
          : "Katso maksusopimus";
      }
    },
    maksusopimusTeksti() {
      if (this.envMaaritysInstanceClass === "creditvisor") {
        return "Maksusuunnitelma";
      } else {
        return "Maksusopimus";
      }
    },
    maksusopimusLuotuTeksti() {
      if (this.envMaaritysInstanceClass === "creditvisor") {
        return "Maksusuunnitelma luotu";
      } else {
        return "Maksusopimus luotu";
      }
    },
    maksusopimuksenTilaTeksti() {
      if (this.envMaaritysInstanceClass === "creditvisor") {
        return "Maksusuunnitelman tila";
      } else {
        return "Maksusopimuksen tila";
      }
    },
    maksusopimusAvoinna() {
      return (
        this.toimeksianto.maksusopimus && this.toimeksianto.maksusopimus.avoinna
      );
    },
    maksusopimusMaksettavaEra() {
      if (this.toimeksianto.maksusopimus) {
        const era =
          this.toimeksianto.maksusopimus.maksusopimusera_erittely.find(
            (era) => era.maksupaiva === null
          );
        // Jos kaikki erät on maksettu, palautetaan null
        return era || null;
      } else {
        return null;
      }
    },
    toimeksiantoreklamaationappi() {
      if (
        this.toimeksianto.paamies.id in this.envPaamiesConfs &&
        "toimeksiantoreklamaationappi" in
          this.envPaamiesConfs[this.toimeksianto.paamies.id]
      ) {
        return this.envPaamiesConfs[this.toimeksianto.paamies.id]
          .toimeksiantoreklamaationappi;
      }
      return "";
    }
  },
  async created() {
    const json_file_name = process.env.VUE_APP_PAAMIES_CONFS;
    if (json_file_name) {
      this.envPaamiesConfs = await import(
        "@/assets/paamies_confs/" + json_file_name + ".json"
      );
    }
  },
  methods: {
    menuContextRakennaMoniKirjeSet(kirjeSet) {
      kirjeSet.sort((a, b) => {
        let tulos = 0;
        const dateA = new Date(a.lahetysaika);
        const dateB = new Date(b.lahetysaika);
        if (dateA < dateB) tulos = 1;
        else if (dateA > dateB) tulos = -1;
        return tulos;
      });

      const moniKirjeSet = [
        {
          nimi: "Viimeisin kirje",
          onOtsikko: true,
          event: ""
        }
      ];
      moniKirjeSet.push(kirjeSet[0]);
      moniKirjeSet.push({
        nimi: "Aiemmat kirjeet",
        onOtsikko: true,
        event: ""
      });
      moniKirjeSet.push(...kirjeSet.slice(1));

      return moniKirjeSet;
    },
    async lahetaLiikasuoritus() {
      this.toimeksiantoLiikasuoritusLomakeLataamassa = true;
      try {
        const lahettiinko = await this.$store.dispatch(
          "liikasuoritukset/lahetaLiikasuoritus",
          this.toimeksiantoLiikasuoritusLomakeData
        );
        this.toimeksiantoLiikasuoritusLomakeData.bic =
          this.liikasuoritukset.bic;
        if (!lahettiinko) {
          return;
        }
        this.toimeksiantoLiikasuoritusLomakeAuki = false;
        this.$naytaOnnistumisilmoitus("Liikasuorituksen tiedot lähetetty.");
        this.$emit("alustaListaus");
      } catch (e) {
        this.$oletusVirheenkasittely(e);
      } finally {
        this.toimeksiantoLiikasuoritusLomakeLataamassa = false;
      }
    },
    async lahetaKohdistusLiikasuoritus() {
      this.toimeksiantoLiikasuoritusLomakeLataamassa = true;
      try {
        this.toimeksiantoLiikasuoritusLomakeData.kohdistusvaihtoehto =
          this.toimeksiantoLiikasuoritusLomakeData.liikasuoritus.kohdeasia_id;
        const lahettiinko = await this.$store.dispatch(
          "liikasuoritukset/lahetaKohdistusLiikasuoritus",
          this.toimeksiantoLiikasuoritusLomakeData
        );
        if (!lahettiinko) {
          return;
        }
        this.toimeksiantoLiikasuoritusLomakeAuki = false;
        this.$naytaOnnistumisilmoitus("Liikasuorituksen tiedot lähetetty.");
        this.$emit("alustaListaus");
      } catch (e) {
        this.$oletusVirheenkasittely(e);
      } finally {
        this.toimeksiantoLiikasuoritusLomakeLataamassa = false;
      }
    },
    avaaLiikasuoritus(liikasuoritus) {
      try {
        this.toimeksiantoLiikasuoritusLomakeData.liikasuoritus = liikasuoritus;
        this.toimeksiantoLiikasuoritusLomakeAuki = true;
      } catch (e) {
        this.$oletusVirheenkasittely(e);
      }
    },
    async haeKirje(kirje) {
      try {
        const response = await this.$store.dispatch(
          "avoimetAsiat/haeKirje",
          kirje.id
        );
        this.$naytaTiedostonlataamisdialog(response, `${kirje.nimi}.pdf`);
      } catch (e) {
        this.$oletusVirheenkasittely(e);
      }
    },
    async haeToimeksiantoLaskuliite(laskuliite) {
      try {
        const response = await this.$store.dispatch(
          "avoimetAsiat/haeToimeksiantoLaskuliite",
          laskuliite.id
        );
        this.$naytaTiedostonlataamisdialog(response, laskuliite.liiteNimi);
      } catch (e) {
        this.$oletusVirheenkasittely(e);
      }
    },
    async toimeksiannotLahetaErapaivanSiirtopyynto() {
      this.erapaivanSiirtoLomakeLataamassa = true;
      try {
        await this.$store.dispatch(
          "avoimetAsiat/toimeksiannotLahetaErapaivanSiirtopyynto",
          this.erapaivanSiirtoLomakeData
        );
        this.erapaivanSiirtoLomakeAuki = false;
        this.$naytaOnnistumisilmoitus("Perintätapauksen eräpäivää siirretty.");
        this.$emit("alustaListaus");
      } catch (e) {
        this.$oletusVirheenkasittely(e);
      } finally {
        this.erapaivanSiirtoLomakeLataamassa = false;
      }
    },
    async lahetaMaksusopimus() {
      this.maksusopimusLomakeLataamassa = true;
      try {
        await this.$store.dispatch(
          "avoimetAsiat/toimeksiannotLahetaMaksusopimus",
          this.maksusopimusLomakeData
        );
        this.maksusopimusSiirtoLomakeAuki = false;
        this.$naytaOnnistumisilmoitus(this.maksusopimusLuotuTeksti);
        this.$emit("alustaListaus");
      } catch (e) {
        this.$oletusVirheenkasittely(e);
      } finally {
        this.maksusopimusLomakeLataamassa = false;
      }
    },
    avaaMaksusopimusKatsoDialog() {
      this.maksusopimusKatsoDialogAuki = true;
    },
    siirryViestitValilehdelle() {
      const avoinAsia = {
        laskuVaiToimeksianto: "T",
        id: this.toimeksianto.id,
        nro: null
      };
      this.$store.commit("yleinen/asetaAvoinAsia", avoinAsia);
      this.$emit("uusiViesti");
    },
    kasitteleSiirraErapaivaaBtnClick() {
      if (
        !this.toimeksianto.vaihe ||
        this.toimeksianto.vaihe.esta_erapaivan_siirto_portaali
      ) {
        this.$naytaVaroitusilmoitus(
          "Toimeksiannon perinnän vaihe ei salli eräpäivän siirtoa."
        );
      } else {
        this.erapaivanSiirtoLomakeAuki = true;
      }
    },
    kasitteleTeeMaksusopimusBtnClick() {
      if (
        !this.toimeksianto.vaihe ||
        this.toimeksianto.vaihe.esta_maksusopimus_portaali
      ) {
        const maksusopimusteksti =
          this.envMaaritysInstanceClass === "creditvisor"
            ? "maksusuunnitelman"
            : "maksusopimuksen";
        this.$naytaVaroitusilmoitus(
          `Toimeksiannon perinnän vaihe ei salli ${maksusopimusteksti} tekemistä.`
        );
      } else {
        this.maksusopimusLomakeAuki = true;
      }
    }
  }
};
</script>

<style lang="scss">
.swal2-title {
  font-family: Georgia, serif;
  font-size: 0.9375rem !important;
}
.swal2-popup {
  font-family: Georgia, serif;
  font-size: 0.9375rem !important;
}
</style>
