<template>
  <ViewLayout ref="viewLayout">
    <v-row
      v-if="
        $store.getters['yleinen/kirjauduttuKirjeella']() &&
        envMaaritysInstanceClass !== 'ijcapital'
      "
      justify="center"
      class="mb-8">
      <v-card
        :width="$vuetify.breakpoint.xs ? '85vw' : 562"
        class="elevation-5 pa-5 rounded-lg primary info">
        <p class="text-subtitle-1 mb-0">
          <v-icon class="mr-2" color="white">info</v-icon>
          <span class="white--text">
            Olet kirjautunut kirjeellä.
            <a
              class="white--text app-hover-link font-weight-medium"
              @click="siirryTunnistautumaan">
              Tunnistaudu
            </a>
            nähdäksesi kaikki asiasi.
          </span>
        </p>
      </v-card>
    </v-row>
    <v-row v-if="maksamassaKirjeenTapausta" justify="center" class="mb-4">
      <p class="mt-8 text-body-1 text-center app-animoitu-gradientti">
        Siirrytään maksamaan...
      </p>
    </v-row>
    <template v-else>
      <template
        v-if="
          vuexModuuli === 'avoimetAsiat' &&
          !$store.getters['yhteenveto/eiAvoimiaVelkoja']
        ">
        <v-row justify="center" class="mb-0">
          <PaytrailLomake
            :lasku-idt="laskutMaksutiedotLomakkeelle.laskuIdt"
            :toimeksianto-idt="
              toimeksiannotMaksutiedotLomakkeelle.toimeksiantoIdt
            "
            :avoinna="
              Number(
                laskutMaksutiedotLomakkeelle.avoinna +
                  toimeksiannotMaksutiedotLomakkeelle.avoinna
              )
            " />
        </v-row>
        <v-divider class="mt-6 mb-9" />
      </template>
      <v-row
        v-for="tapaus in tapaukset"
        :key="tapaus.id"
        justify="center"
        class="mb-8">
        <ToimeksiantoKortti
          v-if="tapaus.toimeksiantovelallinen_set"
          :toimeksianto="tapaus"
          :vuex-moduuli="vuexModuuli"
          @alustaListaus="alustaListaus"
          @uusiViesti="siirryViestitValilehdelle" />
        <LaskuKortti
          v-else-if="tapaus.asiakas"
          :lasku="tapaus"
          :vuex-moduuli="vuexModuuli"
          @alustaListaus="alustaListaus"
          @uusiViesti="siirryViestitValilehdelle" />
      </v-row>
      <v-row v-if="lataamassaTapauksia" justify="center" class="mb-8">
        <ToimeksiantoKorttiSkeletonLoader />
      </v-row>
      <v-row
        v-if="lataaLisaaScrollBtnNakyvyysMaaritys"
        justify="center"
        class="mb-8">
        <LataaLisaaScrollBtn
          @btn-click="haeTapauksia"
          @nakyvyysmuutos="kasitteleNakyvyysmuutos" />
      </v-row>
      <template v-if="!tapaukset.length && !lataamassaTapauksia">
        <p class="mt-8 text-body-1 text-center">
          {{ noDataText }}
        </p>
      </template>
    </template>
  </ViewLayout>
</template>

<script>
import LataaLisaaScrollBtn from "@/components/LataaLisaaScrollBtn";
import LaskuKortti from "@/components/LaskuKortti";
import PaytrailLomake from "@/components/PaytrailLomake";
import ToimeksiantoKortti from "@/components/ToimeksiantoKortti";
import ToimeksiantoKorttiSkeletonLoader from "@/components/ToimeksiantoKorttiSkeletonLoader";
import { tunnistaudu } from "@/utils/tunnistautuminen";

export default {
  name: "Tapauslistaus",
  components: {
    LaskuKortti,
    LataaLisaaScrollBtn,
    PaytrailLomake,
    ToimeksiantoKortti,
    ToimeksiantoKorttiSkeletonLoader
  },
  props: {
    vuexModuuli: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      suodatus: []
    };
  },
  computed: {
    envMaaritysInstanceClass() {
      return process.env.VUE_APP_INSTANCE_CLASS || "";
    },
    noDataText() {
      if (this.vuexModuuli !== "liikasuoritukset") {
        return "Ei tietoja.";
      }
      return "Ei puuttuvia liikasuoritusilmoituksia";
    },
    laskutNykyinenSivu() {
      return this.$store.state[this.vuexModuuli].laskutNykyinenSivu;
    },
    laskutSeuraavaSivu() {
      return this.$store.state[this.vuexModuuli].laskutSeuraavaSivu;
    },
    lataamassaTapauksia() {
      return this.$store.state[this.vuexModuuli].lataamassaTapauksia;
    },
    tapaukset() {
      return this.$store.state[this.vuexModuuli].tapaukset;
    },
    laskutMaksutiedotLomakkeelle() {
      return this.$store.getters["avoimetAsiat/laskutMaksutiedotLomakkeelle"];
    },
    toimeksiannotMaksutiedotLomakkeelle() {
      return this.$store.getters[
        "avoimetAsiat/toimeksiannotMaksutiedotLomakkeelle"
      ];
    },
    toimeksiannotNykyinenSivu() {
      return this.$store.state[this.vuexModuuli].toimeksiannotNykyinenSivu;
    },
    toimeksiannotSeuraavaSivu() {
      return this.$store.state[this.vuexModuuli].toimeksiannotSeuraavaSivu;
    },
    lataaLisaaScrollBtnNakyvyysMaaritys() {
      return (
        this.tapaukset.length &&
        !this.lataamassaTapauksia &&
        ((this.laskutSeuraavaSivu && this.suodatus.includes("laskut")) ||
          (this.toimeksiannotSeuraavaSivu &&
            this.suodatus.includes("toimeksiannot")))
      );
    },
    maksamassaKirjeenTapausta() {
      return (
        this.$store.getters["yleinen/kirjauduttuKirjeella"]() &&
        this.$store.state.kirjauduKirjeella.suoraanMaksamaan
      );
    }
  },
  created() {
    this.asetaSuodatus();
    this.alustaListaus();
    if (this.vuexModuuli === "avoimetAsiat") this.haeMaksutiedot();
  },
  methods: {
    asetaSuodatus() {
      const suodatus = this.$route.params.suodatus;
      if (this.$route.params.suodatus) {
        this.suodatus = suodatus;
      } else if (this.envMaaritysInstanceClass === "creditvisor") {
        // CreditVisorilla ja IJ Capitallla toistaiseksi haetaan vain toimeksiannot
        this.suodatus = ["toimeksiannot"];
      } else if (this.envMaaritysInstanceClass === "ijcapital") {
        // CreditVisorilla ja IJ Capitallla toistaiseksi haetaan vain toimeksiannot
        this.suodatus = ["toimeksiannot"];
      } else {
        this.suodatus = ["laskut", "toimeksiannot"];
      }
    },
    async alustaListaus() {
      // Scrollataan ylös siltä varalta että mahdollisen uudelleenalustamisen aikaan käyttäjä on
      // scrollannut LataaLisaaScrollBtn:n näkyviin. Vältetään ylimääräiset pyynnöt.
      if (this.$refs.viewLayout) this.$refs.viewLayout.$el.scrollTop = 0;
      await this.$store.dispatch(`${this.vuexModuuli}/asetaOletustila`);
      this.haeTapauksia();
    },
    siirryViestitValilehdelle() {
      this.$router.replace({
        name: "Viestit"
      });
    },
    async haeMaksutiedot() {
      await this.$store.dispatch(`${this.vuexModuuli}/haeMaksutiedot`);
      if (this.maksamassaKirjeenTapausta) this.siirryMaksamaanKirjeenTapaus();
    },
    async siirryMaksamaanKirjeenTapaus() {
      try {
        const response = await this.$store.dispatch(
          "kirjauduKirjeella/luoKirjeenTapauksenPaytrailMaksu"
        );

        if (response === null) {
          this.$naytaVaroitusilmoitus(
            "Kirjeen maksutietoja ei löytynyt. Voit tarkistaa arkistosta, onko maksu jo suoritettu."
          );
          this.$store.commit("kirjauduKirjeella/asetaSuoraanMaksamaan", false);
        } else {
          // Uudelleenohjataan Paytrailin maksusivulle
          window.location.href = response.data.href;
        }
      } catch (e) {
        this.$oletusVirheenkasittely(e);
        this.$store.commit("kirjauduKirjeella/asetaSuoraanMaksamaan", false);
      }
    },
    async haeTapauksia() {
      try {
        await this.$store.dispatch(
          `${this.vuexModuuli}/haeTapauksia`,
          this.suodatus
        );
      } catch (e) {
        this.$oletusVirheenkasittely(
          e,
          "Tietojen haku epäonnistui. Yritä hetken kuluttua uudelleen."
        );
      }
    },
    kasitteleNakyvyysmuutos(nakyvissa) {
      if (nakyvissa) {
        this.haeTapauksia();
      }
    },
    siirryTunnistautumaan() {
      tunnistaudu();
    }
  }
};
</script>
