var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-tabs",
    {
      staticClass: "tabs",
      attrs: {
        centered: _vm.centered,
        vertical: _vm.vertical,
        color: _vm.envMaaritysColorNavTabs,
      },
    },
    [
      !_vm.kirjauduttuKirjeella
        ? _c("v-tab", { attrs: { to: "yhteenveto" } }, [_vm._v("Yhteenveto")])
        : _vm._e(),
      _c("v-tab", { attrs: { to: "avoimet" } }, [_vm._v("Avoimet asiat")]),
      _c(
        "v-tab",
        { attrs: { to: "viestit" } },
        [
          _vm.lukemattomatViestitKpl
            ? _c(
                "v-badge",
                {
                  attrs: {
                    color: "info",
                    content: String(_vm.lukemattomatViestitKpl),
                  },
                },
                [_vm._v(" Viestit ")]
              )
            : _c("span", [_vm._v("Viestit")]),
        ],
        1
      ),
      _c("v-tab", { attrs: { to: "arkisto" } }, [_vm._v("Arkisto")]),
      _vm.yleinen.liikasuoritusYhteenveto
        .yhteensa_liikasuoritus_myos_palautetut_kpl
        ? _c(
            "v-tab",
            { attrs: { to: "liikasuoritukset" } },
            [
              _vm._v(" Liikasuoritukset "),
              _vm.yleinen.liikasuoritusYhteenveto.yhteensa_liikasuoritus_kpl
                ? _c("v-badge", {
                    attrs: {
                      color: "error",
                      content: String(
                        _vm.yleinen.liikasuoritusYhteenveto
                          .yhteensa_liikasuoritus_kpl
                      ),
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }