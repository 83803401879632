import BaseAsiat from "@/store/modules/baseAsiat";
import Lasku from "@/classes/Lasku";
import Toimeksianto from "@/classes/Toimeksianto";
import { differenceInYears } from "date-fns";

export default class Arkisto extends BaseAsiat {
  state() {
    return {
      ...super.state()
    };
  }

  getters() {
    return {
      ...super.getters(),
      laskutUrlParametrit(state) {
        const urlParametrit = new URLSearchParams();
        urlParametrit.append("sivun_pituus", 2);
        urlParametrit.append("page", state.laskutNykyinenSivu);
        urlParametrit.append("tila", Lasku.TILA_MAKSETTU);
        urlParametrit.append("tila", Lasku.TILA_MYYTY);
        urlParametrit.append("tila", Lasku.TILA_PERUTTU);
        urlParametrit.append("ordering", "-erapaiva");
        return urlParametrit;
      },
      toimeksiannotUrlParametrit(state) {
        const urlParametrit = new URLSearchParams();
        urlParametrit.append("sivun_pituus", 2);
        urlParametrit.append("page", state.toimeksiannotNykyinenSivu);
        urlParametrit.append("tila", Toimeksianto.TILA_LOPPUUN_PERITTY);
        urlParametrit.append("tila", Toimeksianto.TILA_MYYTY);
        urlParametrit.append("tila", Toimeksianto.TILA_LOPETETTU);
        urlParametrit.append("tila", Toimeksianto.TILA_PERUTTU);
        urlParametrit.append("ordering", "-luotu");
        return urlParametrit;
      }
    };
  }

  mutations() {
    return {
      ...super.mutations(),
      pushTapaukset(state, payload) {
        // Suodatetaan arkistosta pois yli 5 vuotta vanhat
        const tanaan = new Date();
        for (const tapaus of payload) {
          const tapausLuotu = new Date(tapaus.luotu);
          const eroVuosissa = differenceInYears(tanaan, tapausLuotu);
          if (eroVuosissa < 5) {
            state.tapaukset.push(tapaus);
          }
        }
      }
    };
  }

  actions() {
    return {
      ...super.actions()
    };
  }
}
