var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "kirjaudu mb-n4",
      class:
        _vm.envMaaritysInstanceClass !== "creditvisor" ? "app-bg--primary" : "",
    },
    [
      _c(
        "div",
        { staticClass: "paasisalto", attrs: { "data-v": _vm.versio } },
        [
          _vm.envMaaritysInstanceClass === "uuva"
            ? [
                _c("div", { staticClass: "d-flex align-center" }, [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex align-center app-bg--white rounded",
                    },
                    [
                      _c("v-img", {
                        staticClass: "mx-4",
                        attrs: {
                          alt: "Logo",
                          contain: "",
                          src: _vm.envMaaritysLogo,
                          width: _vm.$vuetify.breakpoint.smAndDown ? 75 : 150,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "h1",
                    {
                      staticClass: "white--text mx-4",
                      class: _vm.$vuetify.breakpoint.smAndDown
                        ? "text-h3"
                        : "text-h1",
                    },
                    [_vm._v(" Oma "), _c("br"), _vm._v(" UUVA ")]
                  ),
                ]),
              ]
            : [
                _c(
                  "div",
                  { staticClass: "d-flex align-center flex-column" },
                  [
                    _c(
                      "h1",
                      { staticClass: "ma-4", class: _vm.kirjauduH1Class },
                      [_vm._v("Oma")]
                    ),
                    _c("v-img", {
                      staticClass: "mx-4",
                      attrs: {
                        alt: "Logo",
                        contain: "",
                        src: _vm.envMaaritysLogo,
                        height: _vm.$vuetify.breakpoint.smAndDown ? 40 : 100,
                      },
                    }),
                  ],
                  1
                ),
              ],
          _c(
            "div",
            { staticClass: "d-flex justify-center flex-column" },
            [
              _vm.envMaaritysInstanceClass !== "ijcapital"
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mt-16 elevation-5",
                      class:
                        _vm.envMaaritysInstanceClass === "creditvisor"
                          ? "white--text"
                          : "",
                      attrs: {
                        color: _vm.envMaaritysLoginButtonColor,
                        rounded: "",
                        large: "",
                      },
                      on: { click: _vm.siirryTunnistautumaan },
                    },
                    [_vm._v(" TUNNISTAUDU ")]
                  )
                : _vm._e(),
              _c(
                "p",
                {
                  staticClass:
                    "text-subtitle-1 text-center mt-8 mb-0 app-hover-link",
                  class:
                    _vm.envMaaritysInstanceClass === "creditvisor"
                      ? "primary--text"
                      : "white--text",
                  on: {
                    click: function ($event) {
                      return _vm.$router.push({ path: "/kirje" })
                    },
                  },
                },
                [_vm._v(" Kirjaudu kirjeellä ")]
              ),
            ],
            1
          ),
          _vm.envMaaritysLoginDescription
            ? _c(
                "h2",
                {
                  staticClass: "text-center mt-16 kuvaus",
                  class:
                    _vm.envMaaritysInstanceClass === "creditvisor"
                      ? "primary--text"
                      : "white--text",
                },
                [_vm._v(" " + _vm._s(_vm.envMaaritysLoginDescription) + " ")]
              )
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }