<template>
  <v-form
    v-if="envMaaritysInstanceClass !== 'ijcapital'"
    v-model="valid"
    @submit.prevent="submit">
    <v-tooltip
      v-if="!onkoAvoinnaRajoissa"
      color="transparent"
      bottom
      open-on-click
      open-on-focus
      :activator="`#activator${activatorRandomId}`">
      <v-card
        :width="$vuetify.breakpoint.xs ? '85vw' : 562"
        class="elevation-5 pa-5 ma-0 rounded-lg primary info">
        <p class="text-subtitle-1 mb-0">
          <v-icon class="mr-2" color="white">info</v-icon>
          <span class="white--text">
            Maksua ei voida suorittaa. Maksettavaa on
            {{ $n(Number(avoinna), "currency") }}.
            <span v-if="avoinna < minSumma">
              Pienin mahdollinen suoritus on
              {{ $n(Number(minSumma), "currency") }}.
            </span>
            <span v-else-if="avoinna > maxSumma">
              Suurin mahdollinen suoritus on
              {{ $n(Number(maxSumma), "currency") }}.
            </span>
          </span>
        </p>
      </v-card>
    </v-tooltip>
    <!-- Kaikkien tapauksien maksaminen kerralla -->
    <!-- Tarvitaan wrapper div erilliseksi activatoriksi, koska tooltip ei toimi jos activator on kiinnitetty btn:ään, joka on disabled -->
    <div
      v-if="
        toimeksiantoIdt.length ||
        laskuIdt.length ||
        (toimeksiantoId === null && laskuId === null)
      "
      :id="
        !onkoAvoinnaRajoissa ? `activator${activatorRandomId}` : 'passiivinen-1'
      ">
      <TapausKorttiBtn
        teksti="Maksa kaikki"
        ikoni="payment"
        :disabled="!onkoAvoinnaRajoissa"
        @click="maksaKaikki" />
    </div>
    <!-- Yksittäisen tapauksen tai maksusopimuserän maksaminen -->
    <div
      v-else
      :id="
        !onkoAvoinnaRajoissa && !onkoAvoinnaEraRajoissa
          ? `activator${activatorRandomId}`
          : 'passiivinen-2'
      ">
      <v-menu>
        <template #activator="{ on }">
          <TapausKorttiBtn
            ref="button"
            teksti="Maksa"
            ikoni="payment"
            :disabled="!onkoAvoinnaRajoissa && !onkoAvoinnaEraRajoissa"
            v-on="avoinnaEra > 0 ? on : { click: maksa }" />
        </template>
        <template #default>
          <v-list>
            <v-list-item :disabled="!onkoAvoinnaRajoissa" @click="maksa">
              <v-list-item-avatar>
                <v-icon>reorder</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>Maksa kokonaan</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="avoinnaEra > 0"
              :disabled="!onkoAvoinnaEraRajoissa"
              @click="maksaEra">
              <v-list-item-avatar>
                <v-icon>segment</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>Maksa erä</v-list-item-title>
            </v-list-item>
          </v-list>
        </template>
      </v-menu>
    </div>
  </v-form>
  <div v-else></div>
</template>

<script>
import LomakeMixin from "@/mixins/LomakeMixin";
import TapausKorttiBtn from "@/components/TapausKorttiBtn";

const PAYTRAIL_MAX_SUMMA = 499999.99;
const PAYTRAIL_MIN_SUMMA = 0.65;

export default {
  name: "PaytrailLomake",
  components: {
    TapausKorttiBtn
  },
  mixins: [LomakeMixin],
  props: {
    laskuId: {
      type: Number,
      default: null
    },
    laskuIdt: {
      type: Array,
      default: () => []
    },
    toimeksiantoId: {
      type: Number,
      default: null
    },
    toimeksiantoIdt: {
      type: Array,
      default: () => []
    },
    avoinna: {
      type: Number,
      required: true
    },
    avoinnaEra: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      // Tarvitaan jotta tooltipit toimivat, kun näytöllä on monta PaytrailLomake-komponenttia samanaikaisesti
      activatorRandomId: String(Math.random()).replace(".", "")
    };
  },
  computed: {
    envMaaritysInstanceClass() {
      return process.env.VUE_APP_INSTANCE_CLASS || "";
    },
    onkoAvoinnaRajoissa() {
      return (
        PAYTRAIL_MIN_SUMMA <= this.avoinna && this.avoinna <= PAYTRAIL_MAX_SUMMA
      );
    },
    onkoAvoinnaEraRajoissa() {
      return (
        PAYTRAIL_MIN_SUMMA <= this.avoinnaEra &&
        this.avoinnaEra <= PAYTRAIL_MAX_SUMMA
      );
    },
    minSumma() {
      return PAYTRAIL_MIN_SUMMA;
    },
    maxSumma() {
      return PAYTRAIL_MAX_SUMMA;
    }
  },
  methods: {
    async lahetaMaksu(data) {
      try {
        const response = await this.$store.dispatch(
          "paytrail/luoPaytrailMaksu",
          data
        );
        // Uudelleenohjataan Paytrailin maksusivulle
        window.location.href = response.data.href;
      } catch (e) {
        this.$oletusVirheenkasittely(e);
      }
    },
    async maksa() {
      const data = {};
      if (this.toimeksiantoId) {
        data.toimeksiannot = [{ toimeksianto_id: this.toimeksiantoId }];
      } else if (this.laskuId) {
        data.laskut = [{ lasku_id: this.laskuId }];
      }
      await this.lahetaMaksu(data);
    },
    async maksaEra() {
      const data = {
        toimeksiannot: [
          { toimeksianto_id: this.toimeksiantoId, maksusopimusera: true }
        ]
      };
      await this.lahetaMaksu(data);
    },
    async maksaKaikki() {
      const toimeksiannot = this.toimeksiantoIdt.map((id) => ({
        toimeksianto_id: id
      }));
      const laskut = this.laskuIdt.map((id) => ({ lasku_id: id }));
      const data = { laskut, toimeksiannot };
      await this.lahetaMaksu(data);
    }
  }
};
</script>

<style lang="scss" scoped>
.v-tooltip__content {
  opacity: 1 !important;
}
</style>
