<template>
  <v-form v-model="valid" @submit.prevent="submit">
    <v-card class="pa-4 d-flex flex-column">
      <v-text-field
        v-model="kirjeId"
        label="Tunnus"
        :rules="[
          $validointiSaannot.numero,
          $validointiSaannot.kokonaisluku,
          $validointiSaannot.pakollinen
        ]"
        outlined
        required
        background-color="#f5f5f5" />
      <v-text-field
        v-model="kirjeToken"
        label="Salasana"
        :append-icon="naytaSalasana ? 'visibility_off' : 'visibility'"
        :type="naytaSalasana ? 'text' : 'password'"
        :rules="[$validointiSaannot.pakollinen]"
        outlined
        required
        background-color="#f5f5f5"
        @click:append="naytaSalasana = !naytaSalasana" />
      <v-btn
        type="submit"
        :disabled="!valid || lataamassa"
        :loading="lataamassa"
        color="primary"
        rounded
        large>
        Kirjaudu
      </v-btn>
    </v-card>
  </v-form>
</template>

<script>
import LomakeMixin from "@/mixins/LomakeMixin";

export default {
  name: "KirjauduKirjeellaLomake",
  mixins: [LomakeMixin],
  data() {
    return {
      naytaSalasana: false
    };
  },
  computed: {
    kirjeId: {
      get() {
        return this.$store.getters["kirjauduKirjeella/kirjeId"];
      },
      set(arvo) {
        this.$store.commit("kirjauduKirjeella/asetaKirjeId", arvo);
      }
    },
    kirjeToken: {
      get() {
        return this.$store.getters["kirjauduKirjeella/kirjeToken"];
      },
      set(arvo) {
        this.$store.commit("kirjauduKirjeella/asetaKirjeToken", arvo);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
