var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      !_vm.piilotaNavBar ? _c("NavBar") : _vm._e(),
      _c("SivupalkkiNav"),
      _c(
        "v-main",
        [
          _c(
            "v-container",
            {
              staticClass:
                "app-min-height-100-pct app-bg--background pa-0 main-container",
              attrs: { fluid: "" },
            },
            [
              _c("router-view", { staticClass: "view-base" }),
              _c("BusinessFooter"),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }