var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-simple-table",
    { staticClass: "flex-grow-1", attrs: { dense: "" } },
    [
      _c("thead", [
        _c("tr", [
          _c("th", { staticClass: "text-left" }, [_vm._v("Eräpäivä")]),
          _c("th", { staticClass: "text-left" }, [_vm._v("Summa")]),
          !_vm.luonnos
            ? _c("th", { staticClass: "text-left" }, [_vm._v("Maksupäivä")])
            : _vm._e(),
        ]),
      ]),
      _c(
        "tbody",
        _vm._l(_vm.maksuerat, function (era) {
          return _c(
            "tr",
            { key: era.erapaiva, class: _vm.maaritaErarivinLuokka(era) },
            [
              _c("td", [_vm._v(_vm._s(_vm.$date(new Date(era.erapaiva))))]),
              _c("td", [_vm._v(_vm._s(_vm.$n(Number(era.summa), "currency")))]),
              !_vm.luonnos
                ? _c("td", [
                    _vm._v(
                      " " +
                        _vm._s(
                          era.maksupaiva
                            ? _vm.$date(new Date(era.maksupaiva))
                            : _vm.maaritaErarivinLuokka(era) ===
                              "erarivi--myohassa"
                            ? _vm.$vuetify.breakpoint.smAndDown
                              ? "Myöh."
                              : "Myöhässä"
                            : "-"
                        ) +
                        " "
                    ),
                  ])
                : _vm._e(),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }