<template>
  <v-navigation-drawer
    ref="sivupalkki"
    v-model="naytaSivupalkkiNav"
    :width="$vuetify.breakpoint.xs ? '70vw' : '336px'"
    app
    right
    temporary>
    <div class="pa-4">
      <NavTabs :centered="false" vertical />
    </div>
    <div class="pa-4 app-bg--primary d-flex justify-center flex-column">
      <div class="mb-4 justify-center d-flex">
        <span
          class="v-navigation-drawer__teksti v-navigation-drawer__teksti--otsikko">
          Kirjautuneena:
        </span>
      </div>
      <div class="mb-4 justify-center d-flex">
        <span class="v-navigation-drawer__teksti">
          <span v-if="!$store.getters['yleinen/kirjauduttuKirjeella']()">
            {{ tunnistettuKayttajaNimi }}
          </span>
          <span v-else>Olet kirjautunut kirjeellä</span>
        </span>
      </div>
      <v-btn
        v-if="!$store.getters['yleinen/kirjauduttuKirjeella']()"
        :disabled="
          !$store.state.yleinen.velallistiedot ||
          !$store.state.yleinen.velallistiedot.id
        "
        href=""
        target="_blank"
        text
        color="white"
        class="my-1"
        @click="avaaOmatTiedot">
        <span class="mr-2">Omat tiedot</span>
        <v-icon class="material-icons-outlined">info</v-icon>
      </v-btn>
      <v-btn
        v-else-if="envMaaritysInstanceClass !== 'ijcapital'"
        href=""
        target="_blank"
        text
        color="white"
        class="my-1"
        @click="siirryTunnistautumaan">
        <span class="mr-2">Tunnistaudu</span>
        <v-icon class="material-icons-outlined">login</v-icon>
      </v-btn>
      <v-btn
        href=""
        target="_blank"
        text
        color="white"
        class="my-1"
        @click="kirjauduUlos">
        <span class="mr-2">Kirjaudu ulos</span>
        <v-icon>logout</v-icon>
      </v-btn>
    </div>
    <div class="v-navigation-drawer__taytto app-bg--primary" />

    <CardDialog
      otsikko="Omat tiedot"
      :auki="omatTiedotDialogAuki"
      :width="$vuetify.breakpoint.xs ? '85vw' : '320px'"
      @sulje="omatTiedotDialogAuki = false">
      <OmatTiedotLomake
        :auki="omatTiedotDialogAuki"
        @sulje="omatTiedotDialogAuki = false" />
    </CardDialog>
  </v-navigation-drawer>
</template>

<script>
import NavTabs from "@/components/NavTabs";
import OmatTiedotLomake from "@/components/OmatTiedotLomake";
import SivupalkkiNavMixin from "@/mixins/SivupalkkiNavMixin";
import { tunnistaudu } from "@/utils/tunnistautuminen";

export default {
  name: "SivupalkkiNav",
  components: {
    NavTabs,
    OmatTiedotLomake
  },
  mixins: [SivupalkkiNavMixin],
  data() {
    return {
      omatTiedotDialogAuki: false
    };
  },
  computed: {
    envMaaritysInstanceClass() {
      return process.env.VUE_APP_INSTANCE_CLASS || "";
    },
    tunnistettuKayttajaNimi() {
      return this.$store.state.yleinen.tunnistettuKayttajaNimi;
    }
  },
  methods: {
    avaaOmatTiedot() {
      this.omatTiedotDialogAuki = true;
    },
    kirjauduUlos() {
      this.$api.tyhjennaTokenit();
      this.$store.dispatch("kirjauduKirjeella/tyhjennaKirjetiedot");
      this.$store.commit("yleinen/asetaChatBotIlmoitusNaytetty", false);
      this.$store.commit("yleinen/asetaLiikasuorituksetNaytetty", false);
      this.$store.commit("yleinen/asetaLiikasuoritusYhteenveto", {});

      this.$router.replace({
        name: "Kirjaudu"
      });

      this.$naytaOnnistumisilmoitus("Olet kirjautunut ulos.");
    },
    siirryTunnistautumaan() {
      tunnistaudu();
    }
  }
};
</script>

<style lang="scss">
.v-navigation-drawer {
  height: calc(var(--vh, 1vh) * 100);

  .v-navigation-drawer__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .v-navigation-drawer__teksti {
    color: white;
    text-align: center;

    /* Mukailee v-tabsin tyylejä */
    &--otsikko {
      font-size: 0.9375rem;
      font-weight: 500;
      letter-spacing: 0.075em;
      text-transform: uppercase;
    }
  }

  .v-navigation-drawer__taytto {
    flex-grow: 1;
  }
}
</style>
