<template>
  <div
    class="kirjaudu mb-n4"
    :class="
      envMaaritysInstanceClass !== 'creditvisor' ? 'app-bg--primary' : ''
    ">
    <div class="paasisalto" :data-v="versio">
      <template v-if="envMaaritysInstanceClass === 'uuva'">
        <div class="d-flex align-center">
          <div class="d-flex align-center app-bg--white rounded">
            <v-img
              alt="Logo"
              class="mx-4"
              contain
              :src="envMaaritysLogo"
              :width="$vuetify.breakpoint.smAndDown ? 75 : 150" />
          </div>
          <h1
            class="white--text mx-4"
            :class="$vuetify.breakpoint.smAndDown ? 'text-h3' : 'text-h1'">
            Oma
            <br />
            UUVA
          </h1>
        </div>
      </template>
      <template v-else>
        <div class="d-flex align-center flex-column">
          <h1 class="ma-4" :class="kirjauduH1Class">Oma</h1>
          <v-img
            alt="Logo"
            class="mx-4"
            contain
            :src="envMaaritysLogo"
            :height="$vuetify.breakpoint.smAndDown ? 40 : 100" />
        </div>
      </template>
      <div class="d-flex justify-center flex-column">
        <v-btn
          v-if="envMaaritysInstanceClass !== 'ijcapital'"
          class="mt-16 elevation-5"
          :class="
            envMaaritysInstanceClass === 'creditvisor' ? 'white--text' : ''
          "
          :color="envMaaritysLoginButtonColor"
          rounded
          large
          @click="siirryTunnistautumaan">
          TUNNISTAUDU
        </v-btn>
        <p
          class="text-subtitle-1 text-center mt-8 mb-0 app-hover-link"
          :class="
            envMaaritysInstanceClass === 'creditvisor'
              ? 'primary--text'
              : 'white--text'
          "
          @click="$router.push({ path: '/kirje' })">
          Kirjaudu kirjeellä
        </p>
      </div>

      <h2
        v-if="envMaaritysLoginDescription"
        class="text-center mt-16 kuvaus"
        :class="
          envMaaritysInstanceClass === 'creditvisor'
            ? 'primary--text'
            : 'white--text'
        ">
        {{ envMaaritysLoginDescription }}
      </h2>
    </div>
  </div>
</template>

<script>
import { tunnistaudu } from "@/utils/tunnistautuminen";

export default {
  name: "Kirjaudu",
  computed: {
    envMaaritysInstanceClass() {
      return process.env.VUE_APP_INSTANCE_CLASS || "";
    },
    envMaaritysLoginButtonColor() {
      return process.env.VUE_APP_LOGIN_BUTTON_COLOR || "";
    },
    envMaaritysLoginDescription() {
      return process.env.VUE_APP_LOGIN_DESCRIPTION || "";
    },
    envMaaritysLogo() {
      const logo = process.env.VUE_APP_LOGO;
      return logo ? require(`@/assets/${logo}.png`) : "";
    },
    kirjauduH1Class() {
      return [
        this.envMaaritysInstanceClass === "creditvisor" ? "black--text" : "",
        this.$vuetify.breakpoint.smAndDown ? "text-h3" : "text-h1"
      ];
    },
    versio() {
      return process.env.VUE_APP_PACKAGE_VERSION;
    }
  },
  methods: {
    siirryTunnistautumaan() {
      tunnistaudu();
    }
  }
};
</script>

<style lang="scss">
.kirjaudu {
  display: flex;
  flex-direction: column;
  padding-bottom: 12px;
}

.paasisalto {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
}
.kuvaus {
  margin-right: 15%;
  margin-left: 15%;
}
</style>
