<template>
  <v-app-bar
    app
    :color="envMaaritysColorNavBar"
    :dark="envMaaritysDarkNavBar"
    elevate-on-scroll
    :hide-on-scroll="$vuetify.breakpoint.smAndDown">
    <template v-if="envMaaritysInstanceClass === 'uuva'">
      <div class="d-flex align-center app-bg--white rounded">
        <v-img
          alt="Logo"
          class="shrink mt-1"
          contain
          max-height="48"
          :src="envMaaritysLogo"
          width="50" />
      </div>
      <v-toolbar-title class="mx-4 app-app-bar-title">
        <h3 class="app-app-bar-title__text">Oma UUVA</h3>
      </v-toolbar-title>
    </template>
    <div v-else class="d-flex align-center">
      <v-img
        alt="Logo"
        class="shrink mt-1"
        contain
        max-height="48"
        :src="envMaaritysLogo"
        width="200" />
    </div>
    <template v-if="$vuetify.breakpoint.smAndDown">
      <v-spacer></v-spacer>
      <div class="d-flex align-end">
        <v-btn
          class="float-right"
          text
          @click="naytaSivupalkkiNav = !naytaSivupalkkiNav">
          <v-icon large>menu</v-icon>
          <v-badge
            v-if="lukemattomatViestitJaPuuttuvatLiikasuorituksetKpl"
            :color="palleronTaustaVari"
            :content="palleronLkm"></v-badge>
        </v-btn>
      </div>
    </template>
    <template v-else>
      <NavTabs />
      <LoggedInUser
        mini-variant
        @avaa-omat-tiedot="avaaOmatTiedot"
        @kirjaudu-ulos="kirjauduUlos"
        @tunnistaudu="siirryTunnistautumaan" />
    </template>
    <template v-if="$vuetify.breakpoint.smAndDown" #extension>
      <v-row justify="center">
        <ViewTitle :teksti="$route.name || ''" />
      </v-row>
    </template>

    <CardDialog
      otsikko="Omat tiedot"
      :auki="omatTiedotDialogAuki"
      :width="$vuetify.breakpoint.xs ? '85vw' : '320px'"
      @sulje="omatTiedotDialogAuki = false">
      <OmatTiedotLomake
        :auki="omatTiedotDialogAuki"
        @sulje="omatTiedotDialogAuki = false" />
    </CardDialog>
  </v-app-bar>
</template>

<script>
import LoggedInUser from "@/components/LoggedInUser";
import NavTabs from "@/components/NavTabs";
import OmatTiedotLomake from "@/components/OmatTiedotLomake";
import SivupalkkiNavMixin from "@/mixins/SivupalkkiNavMixin";
import { tunnistaudu } from "@/utils/tunnistautuminen";
import { mapState } from "vuex";

export default {
  name: "NavBar",
  components: {
    LoggedInUser,
    OmatTiedotLomake,
    NavTabs
  },
  mixins: [SivupalkkiNavMixin],
  data() {
    return {
      omatTiedotDialogAuki: false
    };
  },
  computed: {
    ...mapState(["yleinen"]),
    envMaaritysLogo() {
      const logo = process.env.VUE_APP_LOGO;
      return logo ? require(`@/assets/${logo}.png`) : "";
    },
    envMaaritysInstanceClass() {
      return process.env.VUE_APP_INSTANCE_CLASS || "";
    },
    envMaaritysDarkNavBar() {
      return parseInt(process.env.VUE_APP_DARK_NAV_BAR) === 1;
    },
    envMaaritysColorNavBar() {
      return process.env.VUE_APP_COLOR_NAV_BAR || "";
    },
    iconColor() {
      return process.env.VUE_APP_COLOR_NAV_BAR;
    },
    lukemattomatViestitJaPuuttuvatLiikasuorituksetKpl() {
      return (
        this.$store.state.viestit.lukemattomatKpl +
        this.yleinen.liikasuoritusYhteenveto.yhteensa_liikasuoritus_kpl
      );
    },
    palleronLkm() {
      if (
        this.yleinen.liikasuoritusYhteenveto.yhteensa_liikasuoritus_kpl &&
        this.$store.state.viestit.lukemattomatKpl
      ) {
        return "";
      } else if (
        this.yleinen.liikasuoritusYhteenveto.yhteensa_liikasuoritus_kpl
      ) {
        return String(
          this.yleinen.liikasuoritusYhteenveto.yhteensa_liikasuoritus_kpl
        );
      } else if (this.$store.state.viestit.lukemattomatKpl) {
        return String(this.$store.state.viestit.lukemattomatKpl);
      }
      return "";
    },
    palleronTaustaVari() {
      if (!this.yleinen.liikasuoritusYhteenveto.yhteensa_liikasuoritus_kpl) {
        return "info";
      }
      return "error";
    }
  },
  methods: {
    avaaOmatTiedot() {
      this.omatTiedotDialogAuki = true;
    },
    kirjauduUlos() {
      this.$api.tyhjennaTokenit();
      this.$store.dispatch("kirjauduKirjeella/tyhjennaKirjetiedot");
      this.$store.commit("yleinen/asetaChatBotIlmoitusNaytetty", false);
      this.$store.commit("yleinen/asetaLiikasuorituksetNaytetty", false);
      this.$store.commit("yleinen/asetaLiikasuoritusYhteenveto", {});

      this.$router.replace({
        name: "Kirjaudu"
      });

      this.$naytaOnnistumisilmoitus("Olet kirjautunut ulos.");
    },
    siirryTunnistautumaan() {
      tunnistaudu();
    }
  }
};
</script>

<style lang="scss">
.app-app-bar-title {
  color: white;
  width: 168px;
  overflow: visible;

  .app-app-bar-title__text {
    overflow: visible;
    width: 168px;
  }
}
</style>
