import Vue from "vue";
import * as Sentry from "@sentry/vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/api";
import "./plugins/date-fns";
import "./plugins/toastification";
import i18n from "./plugins/vue-i18n";
import "./plugins/vue-observe-visibility";
import "./plugins/validointisaannot";
import vuetify from "./plugins/vuetify";
import { oletusVirheenkasittely, sentryLog } from "./utils/poikkeukset";
import {
  muotoileDesimaaliluku,
  naytaOnnistumisilmoitus,
  naytaChatBotIlmoitus,
  naytaTiedostonlataamisdialog,
  naytaVaroitusilmoitus,
  naytaVirheilmoitus,
  kahdenPaivanEroPaivissa
} from "@/utils/misc";
import { CardDialog, ViewLayout, ViewTitle } from "@/components";
import "sweetalert2/dist/sweetalert2.min.css";
import VueSweetalert2 from "vue-sweetalert2";

Vue.config.productionTip = false;

/* Globaalit komponentit */
Vue.component("CardDialog", CardDialog);
Vue.component("ViewLayout", ViewLayout);
Vue.component("ViewTitle", ViewTitle);

/* Globaalit funktiot */
Vue.prototype.$muotoileDesimaaliluku = muotoileDesimaaliluku;
Vue.prototype.$naytaOnnistumisilmoitus = naytaOnnistumisilmoitus;
Vue.prototype.$naytaChatBotIlmoitus = naytaChatBotIlmoitus;
Vue.prototype.$naytaTiedostonlataamisdialog = naytaTiedostonlataamisdialog;
Vue.prototype.$naytaVaroitusilmoitus = naytaVaroitusilmoitus;
Vue.prototype.$naytaVirheilmoitus = naytaVirheilmoitus;
Vue.prototype.$kahdenPaivanEroPaivissa = kahdenPaivanEroPaivissa;
Vue.prototype.$oletusVirheenkasittely = oletusVirheenkasittely;
Vue.prototype.$sentryLog = sentryLog;
Vue.use(VueSweetalert2, {
  confirmButtonColor: "success",
  //cancelButtonColor: '',
  reverseButtons: true
});

/* Pluginien alustukset */

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_ENV,
    tracesSampleRate: 1.0,
    attachStackTrace: true,
    attachProps: true,
    logErrors: true
  });
}

Vue.config.errorHandler = function (error, vm, info) {
  console.log("main.js: Vue.config.errorHandler");
  const tags = {
    vueInfo: info,
    vueComponent: vm ? vm.$vnode.tag : null,
    handler: "Vue.config.errorHandler"
  };
  sentryLog(error, {}, tags);
};

window.onerror = function (msg, url, line, col, error) {
  console.log("main.js: window.onerror");
  const tags = {
    handler: "window.onerror"
  };
  sentryLog(error, {}, tags);
};

window.addEventListener("unhandledrejection", function (event) {
  console.log("main.js: unhandledrejection");
  const tags = {
    handler: "unhandledrejection"
  };
  sentryLog(event.reason, {}, tags);
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App)
}).$mount("#app");

Vue.config.devtools = process.env.VUE_APP_ENV === "development" ? true : false;
