<template>
  <div class="avoimet-asiat">
    <Tapauslistaus vuex-moduuli="liikasuoritukset" />
  </div>
</template>

<script>
import { Tapauslistaus } from "@/components";
import { mapState } from "vuex";

export default {
  name: "Liikasuoritukset",
  components: {
    Tapauslistaus
  },
  computed: {
    ...mapState(["yleinen"])
  },
  async mounted() {
    if (
      !this.yleinen.velallistiedot &&
      !this.$store.getters["yleinen/kirjauduttuKirjeella"]()
    ) {
      await this.$store.dispatch("yleinen/haeVelallistiedot");
    }
    await this.haeLiikasuoritusYhteenveto();
    this.$store.commit("yleinen/asetaLiikasuorituksetNaytetty", true, {
      root: true
    });
  },
  methods: {
    async haeLiikasuoritusYhteenveto() {
      try {
        await this.$store.dispatch("yleinen/haeLiikasuoritusYhteenveto");
      } catch (e) {
        this.$oletusVirheenkasittely(
          e,
          "Tietojen haku epäonnistui. Yritä hetken kuluttua uudelleen."
        );
      }
    }
  }
};
</script>
