import BaseModule from "@/store/modules/baseModule";

export default class Paytrail extends BaseModule {
  getters() {
    return {
      ...super.getters()
    };
  }

  actions() {
    return {
      async luoPaytrailMaksu({ getters }, data) {
        return await this._vm.$api.withAuth.request({
          method: "POST",
          url: getters.maaritaUrl("paytrail/luo_maksu/"),
          data: data
        });
      },
      async ilmoitaPaytrailSuccess({ getters }, params) {
        return await this._vm.$api.withAuth.request({
          method: "GET",
          url: getters.maaritaUrl("paytrail/success/"),
          params: params
        });
      },
      async ilmoitaPaytrailCancel({ getters }, params) {
        return await this._vm.$api.withAuth.request({
          method: "GET",
          url: getters.maaritaUrl("paytrail/cancel/"),
          params: params
        });
      }
    };
  }
}
