var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "toimeksianto-kortti rounded-lg rounded-t-xl pa-1",
      attrs: {
        "min-width": _vm.$vuetify.breakpoint.xs ? "85vw" : 562,
        "min-height": "420",
        elevation: "5",
      },
    },
    [
      _c("v-skeleton-loader", {
        attrs: {
          width: _vm.$vuetify.breakpoint.xs ? "85vw" : 562,
          type: "card",
        },
      }),
      _c(
        "v-list-item-subtitle",
        { staticClass: "mt-8 text-body-1 text-center app-animoitu-gradientti" },
        [_vm._v(" Haetaan tietoja... ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }