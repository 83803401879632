<template>
  <div class="business-footer">
    <div :class="footerWrapClass">
      <div :class="colClass">
        <template v-if="envMaaritysFooterTextLeftColumn">
          <p class="mb-0">
            {{ envMaaritysFooterTextLeftColumn }}
          </p>
          <v-divider class="my-2" dark />
        </template>

        <p v-if="envMaaritysEmail || envMaaritysTel">
          <a v-if="envMaaritysEmail" :href="'mailto:' + envMaaritysEmail">
            {{ envMaaritysEmail }}
          </a>
          <span v-if="envMaaritysEmail && envMaaritysTel"><br /></span>
          <a v-if="envMaaritysTel" :href="`tel:${envMaaritysTel}`">
            {{ envMaaritysTel }}
          </a>
        </p>
      </div>
      <div :class="colClass">
        <template v-if="envMaaritysFooterTextMiddleColumn">
          <p class="mb-0">
            {{ envMaaritysFooterTextMiddleColumn }}
          </p>
          <v-divider class="my-2" dark />
        </template>
        <template v-if="envMaaritysInstanceClass === 'creditvisor'">
          <a href="https://www.creditvisor.fi">CreditVisor.fi</a>
          <a @click="lisatietoaPerinnastaDialogAuki = true">
            Lisätietoa perinnästä
          </a>
          <a @click="tietosuojaselosteDialogAuki = true">Tietosuojaseloste</a>
        </template>
      </div>
      <div :class="colClass">
        <template v-if="envMaaritysFooterTextRightColumn">
          <p class="mb-0">
            {{ envMaaritysFooterTextRightColumn }}
          </p>
          <v-divider class="my-2" dark />
        </template>
        <p
          v-if="envMaaritysAddress || envMaaritysPostal || envMaaritysLocality">
          <span v-if="envMaaritysAddress">{{ envMaaritysAddress }}</span>
          <span
            v-if="
              envMaaritysAddress && (envMaaritysPostal || envMaaritysLocality)
            ">
            ,
          </span>
          <span v-if="envMaaritysPostal">{{ envMaaritysPostal }}</span>
          <span v-if="envMaaritysLocality">{{ envMaaritysLocality }}</span>
          <template v-if="envMaaritysBusinessId">
            <br />
            Y-tunnus: {{ envMaaritysBusinessId }}
          </template>
        </p>
        <p v-if="envMaaritysCopyrightText">
          {{ envMaaritysCopyrightText }}
        </p>
      </div>
    </div>
    <template v-if="envMaaritysInstanceClass === 'creditvisor'">
      <PdfDialog
        :auki="tietosuojaselosteDialogAuki"
        :paikallinen-url="tietosuojaselostePaikallinenUrl"
        tiedoston-nimi="Oma CreditVisor - Tietosuojaseloste.pdf"
        @sulje="tietosuojaselosteDialogAuki = false" />
      <PdfDialog
        :auki="lisatietoaPerinnastaDialogAuki"
        :paikallinen-url="lisatietoaPerinnastaPaikallinenUrl"
        tiedoston-nimi="Oma CreditVisor - Lisätietoa perinnästä.pdf"
        @sulje="lisatietoaPerinnastaDialogAuki = false" />
    </template>
  </div>
</template>

<script>
import PdfDialog from "@/components/PdfDialog";

export default {
  components: {
    PdfDialog
  },
  data() {
    return {
      tietosuojaselosteDialogAuki: false,
      lisatietoaPerinnastaDialogAuki: false,
      tietosuojaselostePaikallinenUrl: require("@/assets/tietosuojaseloste_28_5_2021.pdf"),
      lisatietoaPerinnastaPaikallinenUrl: require("@/assets/lisatietoa_perinnasta.pdf")
    };
  },
  computed: {
    footerWrapClass() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "business-footer__wrap--sm-and-down pa-5 pb-2";
      } else {
        return "business-footer__wrap pa-5 pb-2";
      }
    },
    colClass() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "business-footer__col--sm-and-down";
      } else {
        return "business-footer__col";
      }
    },
    envMaaritysInstanceClass() {
      return process.env.VUE_APP_INSTANCE_CLASS || "";
    },
    envMaaritysEmail() {
      return process.env.VUE_APP_EMAIL || "";
    },
    envMaaritysTel() {
      return process.env.VUE_APP_TEL || "";
    },
    envMaaritysCopyrightText() {
      return process.env.VUE_APP_COPYRIGHT_TEXT || "";
    },
    envMaaritysAddress() {
      return process.env.VUE_APP_ADDRESS || "";
    },
    envMaaritysPostal() {
      return process.env.VUE_APP_POSTAL || "";
    },
    envMaaritysLocality() {
      return process.env.VUE_APP_LOCALITY || "";
    },
    envMaaritysBusinessId() {
      return process.env.VUE_APP_BUSINESS_ID || "";
    },
    envMaaritysFooterTextLeftColumn() {
      return process.env.VUE_APP_FOOTER_TEXT_LEFT_COLUMN || "";
    },
    envMaaritysFooterTextMiddleColumn() {
      return process.env.VUE_APP_FOOTER_TEXT_MIDDLE_COLUMN || "";
    },
    envMaaritysFooterTextRightColumn() {
      return process.env.VUE_APP_FOOTER_TEXT_RIGHT_COLUMN || "";
    },
    envMaaritysFooterTextColor() {
      return process.env.VUE_APP_FOOTER_TEXT_COLOR || "#fff";
    },
    envMaaritysFooterEmailLinkTextColor() {
      return process.env.VUE_APP_FOOTER_EMAIL_LINK_TEXT_COLOR || "";
    },
    envMaaritysFooterBackgroundColor() {
      return process.env.VUE_APP_FOOTER_BACKGROUND_COLOR || "rgba(0,0,0,.30)";
    }
  }
};
</script>

<style lang="scss" scoped>
$footerBackgroundColor: env(VUE_APP_FOOTER_BACKGROUND_COLOR);
$footerTextColor: env(VUE_APP_FOOTER_TEXT_COLOR);

.business-footer {
  background-color: $footerBackgroundColor;
  color: $footerTextColor;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 16px;

  &__wrap {
    display: flex;
    flex-direction: row;
    width: 90%;
    max-width: 1024px;

    &--sm-and-down {
      flex-direction: column;
    }
  }

  &__col {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    width: 33.33%;
    margin: 0 8px;

    &--sm-and-down {
      width: 100%;
      margin: 0 0 12px 0;
    }
  }

  a {
    color: $footerTextColor;
    text-decoration: none;
    font-size: 0.9375rem;
  }

  a:hover {
    color: rgba($color: #ffffff, $alpha: 0.7);
  }

  p {
    margin-bottom: 8px;
    font-size: 0.9375rem;
  }
}
</style>
