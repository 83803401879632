import Vue from "vue";
import { naytaChatBotIlmoitus } from "@/utils/misc/";
import "sweetalert2/dist/sweetalert2.min.css";
import VueSweetalert2 from "vue-sweetalert2";
import BaseModule from "@/store/modules/baseModule";
Vue.use(VueSweetalert2, {
  confirmButtonColor: "success",
  reverseButtons: true
});

export default class Yleinen extends BaseModule {
  state() {
    return {
      ...super.state(),
      chatBotIlmoitusNaytetty: false,
      liikasuorituksetNaytetty: false,
      liikasuoritustilaTiedotPuuttuu: "TIEDOT_PUUTTUU",
      liikasuoritustilaKasittelyssa: "KASITTELYSSA",
      liikasuorituspalautustiedotAnnettu: "liikasuorituspalautustiedot_annettu",
      kirjeellaKirjauduttu: false,
      naytaSivupalkkiNav: false,
      tunnistettuKayttajaNimi: "",
      velallistiedot: null,
      omatTiedotLomakeData: {
        puhelin: { numero: "" },
        emailosoite: { email: "" }
      },
      avoinAsia: {
        laskuVaiToimeksianto: "",
        numero: null
      },
      liikasuoritusYhteenveto: {}
    };
  }

  getters() {
    return {
      ...super.getters(),
      kirjauduttuKirjeella(state) {
        // Palautetaan funktio, jotta getteriä kutsuttaessa evaluoidaan localStoragessa oleva arvo. localStoragen
        // arvo ei ole reaktiivinen, joten tavallinen getter saattaisi palauttaa vanhan arvon. Huom. tämäkään ei ole
        // reaktiivinen, vaan saatu arvo on kutsumishetken arvo.
        return () => {
          return (
            state.kirjeellaKirjauduttu ||
            JSON.parse(localStorage.getItem("kirjauduttuKirjeella")) ||
            false
          );
        };
      }
    };
  }

  mutations() {
    return {
      ...super.mutations(),
      asetaChatBotIlmoitusNaytetty(state, payload) {
        state.chatBotIlmoitusNaytetty = payload;
      },
      asetaLiikasuorituksetNaytetty(state, payload) {
        state.liikasuorituksetNaytetty = payload;
      },
      naytaSivupalkkiNav(state, payload) {
        state.naytaSivupalkkiNav = payload;
      },
      asetaTunnistettuKayttajaNimi(state, payload) {
        state.tunnistettuKayttajaNimi = payload;
      },
      asetaVelallistiedot(state, payload) {
        state.velallistiedot = payload;
      },
      asetaOmatTiedotLomakeData(state, payload) {
        state.omatTiedotLomakeData = payload;
      },
      asetaLiikasuoritusYhteenveto(state, payload) {
        state.liikasuoritusYhteenveto = payload;
      },
      asetaAvoinAsia(state, payload) {
        state.avoinAsia = payload;
      },
      tyhjennaAvoinAsia(state, payload) {
        state.avoinAsia = {
          laskuVaiToimeksianto: "",
          id: null,
          nro: null
        };
      },
      palautaOmatTiedotLomakeData(state) {
        state.omatTiedotLomakeData = {
          puhelin: { numero: "" },
          emailosoite: { email: "" }
        };
      },
      asetaKirjauduttuKirjeella(state, payload) {
        state.kirjeellaKirjauduttu = payload;
        localStorage.setItem("kirjauduttuKirjeella", payload);
      }
    };
  }

  actions() {
    return {
      ...super.actions(),
      async haeTunnistettuKayttajaNimi({ commit }) {
        try {
          const { data } = await this._vm.$api.withAuth.request({
            method: "GET",
            url: "tunnistettu_kayttaja_nimi/"
          });

          commit("asetaTunnistettuKayttajaNimi", data.nimi);
        } catch (error) {
          this._vm.$sentryLog(error);
        }
      },
      async haeVelallistiedot({ commit }) {
        try {
          const { data } = await this._vm.$api.withAuth.request({
            method: "GET",
            url: "velallinen/omat_tiedot/"
          });

          commit("asetaVelallistiedot", data);
        } catch (error) {
          this._vm.$sentryLog(error);
        }
      },
      async paivitaVelallistiedot({ commit, state }) {
        const postData = {
          emailosoite: state.omatTiedotLomakeData.emailosoite
        };
        if (state.omatTiedotLomakeData.puhelin?.numero)
          postData.puhelin = state.omatTiedotLomakeData.puhelin;

        const { data } = await this._vm.$api.withAuth.request({
          method: "PUT",
          url: "velallinen/omat_tiedot/x/",
          data: postData
        });

        commit("asetaVelallistiedot", data);
        commit("palautaOmatTiedotLomakeData");
      },
      kasitteleLiikasuorituksiinHyppays({ commit, state }, router) {
        if (
          !state.liikasuorituksetNaytetty &&
          state.liikasuoritusYhteenveto.yhteensa_liikasuoritus_kpl > 0
        ) {
          // laita tämä kommentteihin kehityksessä
          commit("asetaLiikasuorituksetNaytetty", true);
          router.replace({ path: "/liikasuoritukset" });
        } else if (
          !state.liikasuorituksetNaytetty &&
          state.liikasuoritusYhteenveto.yhteensa_liikasuoritus_kpl == 0
        ) {
          // laita tämä kommentteihin kehityksessä
          commit("asetaLiikasuorituksetNaytetty", true);
        }
      },
      async haeLiikasuoritusYhteenveto({
        getters,
        state,
        commit,
        rootState,
        rootGetters
      }) {
        const { data } = await this._vm.$api.withAuth.request({
          method: "GET",
          url: getters.maaritaUrl("liikasuoritus_yhteenveto/")
        });
        commit("asetaLiikasuoritusYhteenveto", data);
      },
      async chatBotIlmoitus({ state, commit }) {
        try {
          if (!process.env.VUE_APP_EXTRA_SCRIPTS) {
            return;
          }
          if (!state.chatBotIlmoitusNaytetty) {
            naytaChatBotIlmoitus(
              "Jos jokin asia askarruttaa, kysy lisää Touko-chatbotilta. Löydät Toukon oikeasta alakulmasta."
            );
            commit("asetaChatBotIlmoitusNaytetty", true);
          }
        } catch (error) {
          this._vm.$sentryLog(error);
        }
      },
      async kysyEmailJaPaivita({ commit, state, dispatch }, emailherate) {
        const velallistiedotEmail =
          state.velallistiedot?.emailosoite?.email || "";
        if (
          state.velallistiedot?.id &&
          velallistiedotEmail === "" &&
          emailherate !== ""
        ) {
          const result = await Vue.swal({
            title:
              "Haluatko tallettaa tämän sähköpostiosoitteen omiin tietoihisi samalla?",
            showCancelButton: true,
            focusCancel: true,
            confirmButtonText: "Talleta sähköpostiosoite",
            cancelButtonText: "Ei talleta"
          });
          if (result.value) {
            if (state.velallistiedot.id) {
              state.omatTiedotLomakeData.emailosoite = { email: emailherate };
              await commit(
                "asetaOmatTiedotLomakeData",
                state.omatTiedotLomakeData
              );
              await dispatch("paivitaVelallistiedot");
            }
          }
        } else if (
          velallistiedotEmail !== "" &&
          emailherate !== velallistiedotEmail
        ) {
          const result = await Vue.swal({
            title:
              "Sähköpostiosoite " +
              emailherate +
              " eroaa omissa tiedoissasi olevasta sähköpostiosoitteesta " +
              velallistiedotEmail +
              ". Lähetetäänkö viesti kuitenkin " +
              emailherate +
              " osoitteeseen?",
            showCancelButton: true,
            focusCancel: true,
            confirmButtonText: "Lähetä viesti osoitteeseen " + emailherate,
            cancelButtonText: "Peruuta"
          });
          if (!result.value) return false;
        }
        return true;
      }
    };
  }
}
