<template>
  <h1 :class="titleClass">
    {{ teksti }}
  </h1>
</template>

<script>
export default {
  props: {
    titleClass: {
      type: String,
      required: false,
      default: "view-title"
    },
    teksti: {
      type: String,
      required: true
    }
  }
};
</script>

<style>
.view-title {
  /* Mukailee v-tabsin tyylejä */
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.075em;
  text-transform: uppercase;
}
</style>
