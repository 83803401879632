export default {
  computed: {
    naytaSivupalkkiNav: {
      get() {
        return this.$store.state.yleinen.naytaSivupalkkiNav;
      },
      set(arvo) {
        this.$store.commit("yleinen/naytaSivupalkkiNav", arvo);
      }
    }
  }
};
