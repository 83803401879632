import Vue from "vue";
import Vuex from "vuex";
import Arkisto from "./modules/arkisto";
import AvoimetAsiat from "./modules/avoimetAsiat";
import ilmoitukset from "./modules/ilmoitukset";
import kirjauduKirjeella from "./modules/kirjauduKirjeella";
import Paytrail from "./modules/paytrail";
import saldotodistus from "./modules/saldotodistus";
import Viestit from "./modules/viestit";
import yhteenveto from "./modules/yhteenveto";
import Yleinen from "./modules/yleinen";
import Liikasuoritukset from "./modules/liikasuoritukset";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage
    })
  ],
  modules: {
    arkisto: new Arkisto().getModule(),
    avoimetAsiat: new AvoimetAsiat().getModule(),
    paytrail: new Paytrail().getModule(),
    ilmoitukset,
    kirjauduKirjeella,
    saldotodistus,
    viestit: new Viestit().getModule(),
    yhteenveto,
    yleinen: new Yleinen().getModule(),
    liikasuoritukset: new Liikasuoritukset().getModule()
  }
});

Vue.prototype.$store = store;

export default store;
