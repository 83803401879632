<template>
  <v-card
    :min-width="$vuetify.breakpoint.xs ? '85vw' : 562"
    height="128"
    class="viesti-kortti rounded-lg"
    elevation="2">
    <v-skeleton-loader
      :width="$vuetify.breakpoint.xs ? '85vw' : 562"
      height="128"
      type="card"
      elevation="0" />
    <v-list-item-subtitle
      class="mt-8 text-body-1 text-center app-animoitu-gradientti">
      Haetaan tietoja...
    </v-list-item-subtitle>
  </v-card>
</template>

<script>
export default {
  name: "ViestiKorttiSkeletonLoader"
};
</script>

<style></style>
