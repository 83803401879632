var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-navigation-drawer",
    {
      ref: "sivupalkki",
      attrs: {
        width: _vm.$vuetify.breakpoint.xs ? "70vw" : "336px",
        app: "",
        right: "",
        temporary: "",
      },
      model: {
        value: _vm.naytaSivupalkkiNav,
        callback: function ($$v) {
          _vm.naytaSivupalkkiNav = $$v
        },
        expression: "naytaSivupalkkiNav",
      },
    },
    [
      _c(
        "div",
        { staticClass: "pa-4" },
        [_c("NavTabs", { attrs: { centered: false, vertical: "" } })],
        1
      ),
      _c(
        "div",
        {
          staticClass: "pa-4 app-bg--primary d-flex justify-center flex-column",
        },
        [
          _c("div", { staticClass: "mb-4 justify-center d-flex" }, [
            _c(
              "span",
              {
                staticClass:
                  "v-navigation-drawer__teksti v-navigation-drawer__teksti--otsikko",
              },
              [_vm._v(" Kirjautuneena: ")]
            ),
          ]),
          _c("div", { staticClass: "mb-4 justify-center d-flex" }, [
            _c("span", { staticClass: "v-navigation-drawer__teksti" }, [
              !_vm.$store.getters["yleinen/kirjauduttuKirjeella"]()
                ? _c("span", [
                    _vm._v(" " + _vm._s(_vm.tunnistettuKayttajaNimi) + " "),
                  ])
                : _c("span", [_vm._v("Olet kirjautunut kirjeellä")]),
            ]),
          ]),
          !_vm.$store.getters["yleinen/kirjauduttuKirjeella"]()
            ? _c(
                "v-btn",
                {
                  staticClass: "my-1",
                  attrs: {
                    disabled:
                      !_vm.$store.state.yleinen.velallistiedot ||
                      !_vm.$store.state.yleinen.velallistiedot.id,
                    href: "",
                    target: "_blank",
                    text: "",
                    color: "white",
                  },
                  on: { click: _vm.avaaOmatTiedot },
                },
                [
                  _c("span", { staticClass: "mr-2" }, [_vm._v("Omat tiedot")]),
                  _c("v-icon", { staticClass: "material-icons-outlined" }, [
                    _vm._v("info"),
                  ]),
                ],
                1
              )
            : _vm.envMaaritysInstanceClass !== "ijcapital"
            ? _c(
                "v-btn",
                {
                  staticClass: "my-1",
                  attrs: {
                    href: "",
                    target: "_blank",
                    text: "",
                    color: "white",
                  },
                  on: { click: _vm.siirryTunnistautumaan },
                },
                [
                  _c("span", { staticClass: "mr-2" }, [_vm._v("Tunnistaudu")]),
                  _c("v-icon", { staticClass: "material-icons-outlined" }, [
                    _vm._v("login"),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-btn",
            {
              staticClass: "my-1",
              attrs: { href: "", target: "_blank", text: "", color: "white" },
              on: { click: _vm.kirjauduUlos },
            },
            [
              _c("span", { staticClass: "mr-2" }, [_vm._v("Kirjaudu ulos")]),
              _c("v-icon", [_vm._v("logout")]),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "v-navigation-drawer__taytto app-bg--primary" }),
      _c(
        "CardDialog",
        {
          attrs: {
            otsikko: "Omat tiedot",
            auki: _vm.omatTiedotDialogAuki,
            width: _vm.$vuetify.breakpoint.xs ? "85vw" : "320px",
          },
          on: {
            sulje: function ($event) {
              _vm.omatTiedotDialogAuki = false
            },
          },
        },
        [
          _c("OmatTiedotLomake", {
            attrs: { auki: _vm.omatTiedotDialogAuki },
            on: {
              sulje: function ($event) {
                _vm.omatTiedotDialogAuki = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }