import * as Sentry from "@sentry/vue";
import { naytaVirheilmoitus } from "@/utils/misc/";

export const oletusVirheenkasittely = (e, extraData = OLETUSVIRHEVIESTI) => {
  if (typeof extraData === "string") {
    const virheviesti = extraData;
    sentryLog(e, { virheviesti });
    naytaVirheilmoitus(virheviesti);
  } else if (typeof extraData === "object") {
    sentryLog(e, extraData);
    if (extraData.virheviesti) naytaVirheilmoitus(extraData.virheviesti);
    if (extraData.responseData && extraData.responseData instanceof String)
      naytaVirheilmoitus(extraData.responseData);
  } else {
    throw new Error(
      'Virheellinen oletusVirheenkasittely-funktion käyttö: syötä toiseksi argumentiksi arvo, joka on joko tyyppiä "string" tai "object"'
    );
  }
};

export const OLETUSVIRHEVIESTI =
  "Toiminto epäonnistui! Yritä hetken kuluttua uudelleen.";

/** Mukautettu poikkeus, jolla saadaan lisäinfoa poikkeuksiin. Koska extra-objektin maksimikoko
 * on Sentryssä 200kB, on objektiin lisättävän datan määrää rajoitettu.
 * */
export const sentryLog = function (e, extraData = {}, tags = {}) {
  let virheenkaappausPeruutettu = false;

  try {
    if (e.response) {
      if (e.response.istuntoVanhentunut) {
        virheenkaappausPeruutettu = true;
        return;
      }

      [extraData, tags] = kasitteleHttpError(extraData, tags, e);
    }

    for (const key of Object.keys(tags)) {
      tags[key] = String(tags[key]).substr(0, 200);
    }

    Sentry.captureException(e, {
      extra: extraData,
      tags
    });
  } catch (err) {
    console.log("!!! sentryLog: virhe poikkeuksen luonnissa");

    const alkuperainenVirhe = korvaaErrorObjektilla(e);
    e = err;
    extraData = {
      huom: "sentryLog epäonnistui",
      alkuperainenVirhe
    };

    Sentry.captureException(e, {
      extra: extraData
    });
  } finally {
    if (virheenkaappausPeruutettu) {
      console.log("Sentry-virheenkaappaus peruutettu");
    } else {
      console.log(
        "SentryException:",
        e,
        "extraData:",
        extraData,
        "tags:",
        tags
      );
      if (
        extraData.responseData &&
        extraData.responseData.length > 10 &&
        extraData.responseData.length < 180
      )
        naytaVirheilmoitus(extraData.responseData);
    }
  }
};

const kasitteleHttpError = (extraData, tags, e) => {
  tags.requestUrl = e.config.url;
  tags.responseStatus = e.response.status;

  let responseDataSize = 0;
  let requestContentSize = 0;

  try {
    const { data } = e.response;
    responseDataSize = roughSizeOfObject(data);
    // extra-objektin maksimikoko Sentryssä on 200kB
    if (responseDataSize < 195000) {
      extraData.responseData = data;
    }
  } catch (error) {
    console.log("!!! sentryLog: responseDatan käsittely epäonnistui");
    console.log(error);
  }

  try {
    const { data } = e.response.config;
    if (data) {
      requestContentSize = roughSizeOfObject(data);
      if (responseDataSize + requestContentSize < 195000) {
        extraData.requestContent = data;
      }
    }
  } catch (error) {
    console.log("!!! sentryLog: requestContentin käsittely epäonnistui");
    console.log(error);
  }

  return [extraData, tags];
};

// https://stackoverflow.com/questions/1248302/how-to-get-the-size-of-a-javascript-object
export const roughSizeOfObject = (object) => {
  var objectList = [];
  var stack = [object];
  var bytes = 0;

  while (stack.length) {
    var value = stack.pop();

    if (typeof value === "boolean") {
      bytes += 4;
    } else if (typeof value === "string") {
      bytes += value.length * 2;
    } else if (typeof value === "number") {
      bytes += 8;
    } else if (typeof value === "object" && objectList.indexOf(value) === -1) {
      objectList.push(value);

      for (var i in value) {
        stack.push(value[i]);
      }
    }
  }
  return bytes;
};

export const korvaaErrorObjektilla = function (arvo) {
  if (arvo instanceof Error) {
    var errorObj = {};

    Object.getOwnPropertyNames(arvo).forEach(function (avain) {
      errorObj[avain] = arvo[avain];
    });

    // Pudotetaan funktiot pois propertyista
    return JSON.parse(JSON.stringify(errorObj));
  }

  return arvo;
};
