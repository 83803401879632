import BaseAsiat from "@/store/modules/baseAsiat";
import Lasku from "@/classes/Lasku";
import Toimeksianto from "@/classes/Toimeksianto";

export default class AvoimetAsiat extends BaseAsiat {
  state() {
    return {
      ...super.state(),
      maksusopimusTiedotCancelToken: null
    };
  }

  getters() {
    return {
      ...super.getters(),
      laskutUrlParametrit(state, getters) {
        const urlParametrit = new URLSearchParams();
        urlParametrit.append("sivun_pituus", 2);
        urlParametrit.append("page", state.laskutNykyinenSivu);
        urlParametrit.append("tila", Lasku.TILA_AVOINNA);
        urlParametrit.append("tila", Lasku.TILA_KESKEYTETTY);
        urlParametrit.append("tila", Lasku.TILA_KUITTAUKSESSA);
        urlParametrit.append("ordering", "erapaiva");
        return urlParametrit;
      },
      toimeksiannotUrlParametrit(state) {
        const urlParametrit = new URLSearchParams();
        urlParametrit.append("sivun_pituus", 2);
        urlParametrit.append("page", state.toimeksiannotNykyinenSivu);
        urlParametrit.append("tila", Toimeksianto.TILA_VAPAAEHTOINEN_PERINTA);
        urlParametrit.append("tila", Toimeksianto.TILA_OIKEUDELLINEN_PERINTA);
        urlParametrit.append("tila", Toimeksianto.TILA_ULOSOTTOPERINTA);
        urlParametrit.append("ordering", "luotu");
        return urlParametrit;
      },
      laskutMaksutiedotLomakkeelle(state) {
        let avoinna = 0;
        const laskuIdt = [];
        state.laskutMaksutiedot.map((maksutieto) => {
          avoinna += maksutieto.avoinna;
          laskuIdt.push(maksutieto.id);
        });
        return {
          avoinna,
          laskuIdt
        };
      },
      toimeksiannotMaksutiedotLomakkeelle(state) {
        let avoinna = 0;
        const toimeksiantoIdt = [];
        state.toimeksiannotMaksutiedot.map((maksutieto) => {
          avoinna += maksutieto.avoinna;
          toimeksiantoIdt.push(maksutieto.id);
        });
        return {
          avoinna,
          toimeksiantoIdt
        };
      },
      kirjeenTapauksenMaksutiedot(state, getters, rootState, rootGetters) {
        console.log(
          "!rootGetters['yleinen/kirjauduttuKirjeella'] :>> ",
          !rootGetters["yleinen/kirjauduttuKirjeella"]
        );
        if (!rootGetters["yleinen/kirjauduttuKirjeella"]) {
          throw new Error(
            'Getteriä "kirjeenTapauksenMaksutiedot" kutsuttu vaikka ei olla kirjauduttu kirjeellä'
          );
        } else {
          const data = {};
          let maksutiedot;
          if (state.toimeksiannotMaksutiedot.length) {
            maksutiedot = state.toimeksiannotMaksutiedot[0];
            data.toimeksiannot = [{ toimeksianto_id: maksutiedot.id }];
          } else if (state.laskutMaksutiedot.length) {
            maksutiedot = state.laskutMaksutiedot[0];
            data.laskut = [{ lasku_id: maksutiedot.id }];
          } else {
            // Tapausta ei löytynyt
            return null;
          }

          return data;
        }
      }
    };
  }

  mutations() {
    return {
      ...super.mutations(),
      toimeksiannotLuoMaksusopimusTiedotCancelToken(state, payload) {
        state.maksusopimusTiedotCancelToken = payload;
      },
      laskutAsetaMaksutiedot(state, payload) {
        state.laskutMaksutiedot = payload;
      },
      toimeksiannotAsetaMaksutiedot(state, payload) {
        state.toimeksiannotMaksutiedot = payload;
      }
    };
  }

  actions() {
    return {
      ...super.actions(),
      async laskutLahetaErapaivanSiirtopyynto({ getters }, lomakeData) {
        await this._vm.$api.withAuth.request({
          method: "POST",
          url: getters.maaritaUrl(
            `laskut/${lomakeData.laskuId}/siirra_erapaivaa/`
          ),
          data: {
            paivat: lomakeData.paivat
          }
        });
      },
      async laskutLaskeErapaivanSiirtopyyntoKulu({ getters }, lomakeData) {
        const { laskuId, paivat } = lomakeData;

        return await this._vm.$api.withAuth.request({
          url: getters.maaritaUrl(
            `laskut/${laskuId}/erapaivan_siirto_kulu/${paivat}/`
          )
        });
      },
      async haeMaksutiedot({ commit, dispatch, getters, state }) {
        const promises = [dispatch("toimeksiannotHaeMaksutiedot")];
        if (!(process.env.VUE_APP_INSTANCE_CLASS === "creditvisor")) {
          promises.push(dispatch("laskutHaeMaksutiedot"));
        }
        return await Promise.all(promises);
      },
      async laskutHaeMaksutiedot({ getters, commit }) {
        const { data } = await this._vm.$api.withAuth.request({
          method: "GET",
          url: getters.maaritaUrl("laskut/maksutiedot/"),
          params: {
            sivun_pituus: 0
          }
        });
        commit("laskutAsetaMaksutiedot", data);
        return data;
      },
      async toimeksiannotHaeMaksutiedot({ getters, commit }) {
        const { data } = await this._vm.$api.withAuth.request({
          method: "GET",
          url: getters.maaritaUrl("toimeksiannot/maksutiedot/"),
          params: {
            sivun_pituus: 0
          }
        });
        commit("toimeksiannotAsetaMaksutiedot", data);
        return data;
      },
      async toimeksiannotLahetaErapaivanSiirtopyynto({ getters }, lomakeData) {
        await this._vm.$api.withAuth.request({
          method: "POST",
          url: getters.maaritaUrl(
            `toimeksiannot/${lomakeData.toimeksiantoId}/siirra_erapaivaa/`
          ),
          data: {
            paivat: lomakeData.paivat
          }
        });
      },
      async toimeksiannotLaskeErapaivanSiirtopyyntoKulu(
        { getters },
        lomakeData
      ) {
        const { toimeksiantoId, paivat } = lomakeData;

        return await this._vm.$api.withAuth.request({
          url: getters.maaritaUrl(
            `toimeksiannot/${toimeksiantoId}/erapaivan_siirto_kulu/${paivat}/`
          )
        });
      },
      async toimeksiannotLaskeMaksusopimuksenErat(
        { getters, dispatch, state },
        lomakeData
      ) {
        await dispatch("toimeksiannotLuoMaksusopimusTiedotCancelToken");
        const { toimeksiantoId, erat, erapaiva } = lomakeData;

        return await this._vm.$api.withAuth.request({
          url: getters.maaritaUrl(
            `toimeksiannot/${toimeksiantoId}/luo_maksusopimus/${erat}/${erapaiva}/`
          ),
          cancelToken: state.maksusopimusTiedotCancelToken.token
        });
      },
      async toimeksiannotLuoMaksusopimusTiedotCancelToken({ commit }) {
        const token = this._vm.$api.luoCancelTokenSource();
        commit("toimeksiannotLuoMaksusopimusTiedotCancelToken", token);
      },
      async toimeksiannotLahetaMaksusopimus({ getters }, lomakeData) {
        const { toimeksiantoId, erat, erapaiva } = lomakeData;

        return await this._vm.$api.withAuth.request({
          method: "POST",
          url: getters.maaritaUrl(
            `toimeksiannot/${toimeksiantoId}/luo_maksusopimus/${erat}/${erapaiva}/`
          )
        });
      }
    };
  }
}
