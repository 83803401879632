<template>
  <v-card
    :min-width="$vuetify.breakpoint.xs ? '85vw' : 562"
    min-height="420"
    class="toimeksianto-kortti rounded-lg rounded-t-xl pa-1"
    elevation="5">
    <v-skeleton-loader
      :width="$vuetify.breakpoint.xs ? '85vw' : 562"
      type="card" />
    <v-list-item-subtitle
      class="mt-8 text-body-1 text-center app-animoitu-gradientti">
      Haetaan tietoja...
    </v-list-item-subtitle>
  </v-card>
</template>

<script>
export default {
  name: "ToimeksiantoKorttiSkeletonLoader"
};
</script>

<style></style>
