<template>
  <v-simple-table dense class="flex-grow-1">
    <thead>
      <tr>
        <th class="text-left">Eräpäivä</th>
        <th class="text-left">Summa</th>
        <th v-if="!luonnos" class="text-left">Maksupäivä</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="era in maksuerat"
        :key="era.erapaiva"
        :class="maaritaErarivinLuokka(era)">
        <td>{{ $date(new Date(era.erapaiva)) }}</td>
        <td>{{ $n(Number(era.summa), "currency") }}</td>
        <td v-if="!luonnos">
          {{
            era.maksupaiva
              ? $date(new Date(era.maksupaiva))
              : maaritaErarivinLuokka(era) === "erarivi--myohassa"
              ? $vuetify.breakpoint.smAndDown
                ? "Myöh."
                : "Myöhässä"
              : "-"
          }}
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
import { differenceInDays } from "date-fns";

export default {
  name: "ToimeksiantoMaksusopimusEraTaulukko",
  props: {
    maksuerat: {
      type: Array,
      required: true
    },
    luonnos: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    maaritaErarivinLuokka(era) {
      if (era.maksupaiva) {
        return "erarivi--maksettu";
      }

      const tanaan = new Date();
      const erapaiva = new Date(era.erapaiva);
      const myohassa =
        tanaan > erapaiva && differenceInDays(tanaan, erapaiva) >= 1;

      if (myohassa) {
        return "erarivi--myohassa";
      }
    }
  }
};
</script>

<style lang="scss">
.erarivi {
  &--maksettu {
    background-color: var(--success);
  }

  &--myohassa {
    background-color: var(--warning);
  }
}
</style>
