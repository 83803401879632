var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-radio-group",
        {
          staticClass: "mt-0",
          attrs: { label: "Siirron pituus" },
          model: {
            value: _vm.lomakeData.paivat,
            callback: function ($$v) {
              _vm.$set(_vm.lomakeData, "paivat", $$v)
            },
            expression: "lomakeData.paivat",
          },
        },
        _vm._l(_vm.siirtoVaihtoehdot, function (vaihtoehto) {
          return _c("v-radio", {
            key: vaihtoehto.index,
            attrs: { label: vaihtoehto.selite, value: vaihtoehto.arvo },
          })
        }),
        1
      ),
      _vm.laskemassaKulua
        ? _c("v-row", { staticClass: "my-2 mx-0 app-color--grey" }, [
            _c("p", { staticClass: "app-animoitu-gradientti" }, [
              _vm._v("Lasketaan kulua..."),
            ]),
          ])
        : _vm._e(),
      !_vm.laskemassaKulua && _vm.kuluteksti
        ? _c("v-row", { staticClass: "my-2 mx-0 app-color--grey" }, [
            _c("p", [_vm._v(_vm._s(_vm.kuluteksti))]),
          ])
        : _vm._e(),
      _c(
        "v-row",
        { staticClass: "ma-0 mt-0 py-4", attrs: { justify: "end" } },
        [
          _c(
            "v-btn",
            {
              attrs: {
                type: "submit",
                color: "primary",
                rounded: "",
                disabled: !_vm.valid || _vm.lataamassa,
                loading: _vm.lataamassa,
              },
            },
            [_vm._v(" Hyväksy ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }