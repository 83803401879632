import BaseAsiat from "@/store/modules/baseAsiat";
import { Decimal } from "decimal.js";
import Vue from "vue";
import "sweetalert2/dist/sweetalert2.min.css";
import VueSweetalert2 from "vue-sweetalert2";
Vue.use(VueSweetalert2, {
  confirmButtonColor: "success",
  //cancelButtonColor: '',
  reverseButtons: true
});

function reverseSortObjectByNumberKeys(a, b) {
  var keyA = Number(a[0]),
    keyB = Number(b[0]);
  if (keyA < keyB) return 1;
  if (keyA > keyB) return -1;
  return 0;
}
export default class Liikasuoritukset extends BaseAsiat {
  state() {
    return {
      ...super.state(),
      liikasuoritustilaOK: "OK",
      bic: ""
    };
  }

  getters() {
    return {
      ...super.getters(),
      laskutUrlParametrit(state, getters) {
        const urlParametrit = new URLSearchParams();
        urlParametrit.append("sivun_pituus", 2);
        urlParametrit.append("page", state.laskutNykyinenSivu);
        urlParametrit.append("ordering", "erapaiva");
        return urlParametrit;
      },
      toimeksiannotUrlParametrit(state) {
        const urlParametrit = new URLSearchParams();
        urlParametrit.append("sivun_pituus", 2);
        urlParametrit.append("page", state.toimeksiannotNykyinenSivu);
        urlParametrit.append("ordering", "luotu");
        return urlParametrit;
      }
    };
  }

  mutations() {
    return {
      ...super.mutations(),
      setBic(state, payload) {
        state.bic = payload;
      },
      pushTapaukset(state, payload) {
        for (const tapaus of payload) {
          state.tapaukset.push(tapaus);
        }
      }
    };
  }

  actions() {
    return {
      ...super.actions(),
      async haeTapauksia(
        { commit, dispatch, state },
        suodatus = ["laskut", "toimeksiannot"]
      ) {
        try {
          if (state.lataamassaTapauksia) return;

          commit("lataamassaTapauksia", true);
          const tapauksia = state.tapaukset?.length || 0;
          let uudetTapaukset = 0;
          while (
            (state.laskutSeuraavaSivu || state.toimeksiannotSeuraavaSivu) &&
            uudetTapaukset < 4
          ) {
            if (suodatus.includes("toimeksiannot"))
              await dispatch("haeLiikasuoritusToimeksiantoja");
            if (suodatus.includes("laskut"))
              await dispatch("haeLiikasuoritusLaskuja");
            uudetTapaukset = (state.tapaukset?.length || 0) - tapauksia;
          }
        } catch (error) {
          this._vm.$sentryLog(error);
        } finally {
          commit("lataamassaTapauksia", false);
        }
      },
      async haeLiikasuoritusToimeksiantoja({
        commit,
        getters,
        state,
        dispatch
      }) {
        if (!state.toimeksiannotSeuraavaSivu) return;
        commit(
          "toimeksiannotAsetaNykyinenSivu",
          state.toimeksiannotSeuraavaSivu
        );

        const { data } = await this._vm.$api.withAuth.request({
          method: "GET",
          url: getters.maaritaUrl("liikasuoritustoimeksiannot/"),
          params: getters.toimeksiannotUrlParametrit
        });

        if (data.next) {
          commit(
            "toimeksiannotAsetaSeuraavaSivu",
            state.toimeksiannotNykyinenSivu + 1
          );
        } else {
          commit("toimeksiannotAsetaSeuraavaSivu", null);
        }
        let tapaukset = [];
        for (let tapaus of data.results) {
          tapaus.menuContextLiikasuoritukset = await dispatch(
            "menuContextLiikasuoritukset",
            tapaus
          );
          tapaukset.push(tapaus);
        }
        commit("pushTapaukset", tapaukset);
      },
      async haeLiikasuoritusLaskuja({ commit, getters, state, dispatch }) {
        if (!state.laskutSeuraavaSivu) return;
        commit("laskutAsetaNykyinenSivu", state.laskutSeuraavaSivu);

        const { data } = await this._vm.$api.withAuth.request({
          method: "GET",
          url: getters.maaritaUrl("liikasuorituslaskut/"),
          params: getters.laskutUrlParametrit
        });

        if (data.next) {
          commit("laskutAsetaSeuraavaSivu", state.laskutNykyinenSivu + 1);
        } else {
          commit("laskutAsetaSeuraavaSivu", null);
        }
        let tapaukset = [];
        for (let tapaus of data.results) {
          tapaus.menuContextLiikasuoritukset = await dispatch(
            "menuContextLiikasuoritukset",
            tapaus
          );
          tapaukset.push(tapaus);
        }
        commit("pushTapaukset", data.results);
      },
      async menuContextLiikasuoritukset(
        { dispatch, state, rootState },
        tapaus
      ) {
        let liikasuoritus = {};
        liikasuoritus.kirjeId = 0;
        liikasuoritus.liikasuoritustila =
          rootState.yleinen.liikasuoritustilaTiedotPuuttuu;
        liikasuoritus.disabled = false;
        let liikasuoritusSet = [];

        tapaus.liikasuoritustila = state.liikasuoritustilaOK;
        tapaus.disabled = true;
        tapaus.liikasuoritus_set.map((liikasuoritusItem) => {
          if (
            liikasuoritusItem.kirje_id != liikasuoritus.kirjeId &&
            liikasuoritus.kirjeId > 0 &&
            liikasuoritus.liikasuoritus
          ) {
            liikasuoritusSet.push(liikasuoritus);
            if (
              tapaus.liikasuoritustila === state.liikasuoritustilaOK ||
              liikasuoritus.liikasuoritustila ===
                rootState.yleinen.liikasuoritustilaTiedotPuuttuu
            ) {
              tapaus.liikasuoritustila = liikasuoritus.liikasuoritustila;
            } else if (
              tapaus.liikasuoritustila !==
              rootState.yleinen.liikasuoritustilaTiedotPuuttuu
            ) {
              tapaus.liikasuoritustila = liikasuoritus.liikasuoritustila;
            }
            if (liikasuoritus.disabled === false) {
              tapaus.disabled = false;
            }
            liikasuoritus = {};
          }
          if (liikasuoritusItem.key === "liikasuoritus") {
            liikasuoritus.liikasuoritus = liikasuoritusItem.value;
            liikasuoritus.kirjeId = liikasuoritusItem.kirje_id;
            liikasuoritus.kohdeasia_id = "0";
            liikasuoritus.event = "avaaLiikasuoritus";
            liikasuoritus.nimi = "Liikasuoritus " + liikasuoritusItem.value;
            liikasuoritus.liikasuoritustila =
              rootState.yleinen.liikasuoritustilaTiedotPuuttuu;
          } else if (liikasuoritusItem.key === "vapaateksti") {
            liikasuoritus.vapaateksti = liikasuoritusItem.value;
          } else if (
            liikasuoritusItem.key === "kohdelasku_id" ||
            liikasuoritusItem.key === "kohdetoimeksianto_id"
          ) {
            liikasuoritus.kohdeasia_id = liikasuoritusItem.value;
          } else if (liikasuoritusItem.key === "kulu") {
            liikasuoritus.kulu = new Decimal(liikasuoritusItem.value);
            liikasuoritus.veroton = liikasuoritus.kulu.div(
              new Decimal(100).plus(24).div(new Decimal(100))
            );
            liikasuoritus.vero = liikasuoritus.kulu.minus(
              liikasuoritus.veroton
            );
          } else if (
            liikasuoritusItem.key ===
            rootState.yleinen.liikasuorituspalautustiedotAnnettu
          ) {
            liikasuoritus.liikasuoritustila =
              rootState.yleinen.liikasuoritustilaKasittelyssa;
            liikasuoritus.disabled = true;
          }
        });
        if (liikasuoritus.liikasuoritus && liikasuoritus.kulu) {
          liikasuoritusSet.push(liikasuoritus);
          if (
            tapaus.liikasuoritustila === state.liikasuoritustilaOK ||
            liikasuoritus.liikasuoritustila ===
              rootState.yleinen.liikasuoritustilaTiedotPuuttuu
          ) {
            tapaus.liikasuoritustila = liikasuoritus.liikasuoritustila;
          } else if (
            tapaus.liikasuoritustila !==
            rootState.yleinen.liikasuoritustilaTiedotPuuttuu
          ) {
            tapaus.liikasuoritustila = liikasuoritus.liikasuoritustila;
          }
          if (liikasuoritus.disabled === false) {
            tapaus.disabled = false;
          }
        }
        if (liikasuoritusSet.length) {
          if (liikasuoritusSet.length > 1) {
            return await dispatch(
              "menuContextRakennaMoniLiikasuoritusSet",
              liikasuoritusSet
            );
          } else {
            return liikasuoritusSet;
          }
        } else {
          return [
            {
              nimi: "Ei liikasuorituksia"
            }
          ];
        }
      },
      async menuContextRakennaMoniLiikasuoritusSet(
        { state },
        liikasuoritusSet
      ) {
        const moniLiikasuoritusSet = [
          {
            nimi: "Viimeisin liikasuoritus",
            onOtsikko: true,
            event: ""
          }
        ];
        moniLiikasuoritusSet.push(liikasuoritusSet[0]);
        moniLiikasuoritusSet.push({
          nimi: "Aiemmat liikasuoritukset",
          onOtsikko: true,
          event: ""
        });
        moniLiikasuoritusSet.push(...liikasuoritusSet.slice(1));

        return moniLiikasuoritusSet;
      },
      async lahetaLiikasuoritus({ state, dispatch, commit }, lomakeData) {
        if (lomakeData.tilinumero && lomakeData.tilinumero.length > 10) {
          const bic = await dispatch(
            "selvitaIBANistaBIC",
            lomakeData.tilinumero
          );
          if (bic && bic !== lomakeData.bic) {
            const result = await Vue.swal({
              title:
                "BIC " +
                lomakeData.bic +
                " eroaa IBAN-tilinumeroon " +
                lomakeData.tilinumero +
                " liittyvästä BIC:istä " +
                bic +
                ". Käytetäänkö IBAN-tilinumeron BIC:iä " +
                bic +
                " vai ilmoittamaasi BIC:iä " +
                lomakeData.bic +
                "?",
              showCancelButton: true,
              focusCancel: true,
              confirmButtonText: "IBAN-tilinumeron BIC:iä " + bic,
              cancelButtonText: "Ilmoittamaani BIC:iä " + lomakeData.bic
            });
            if (result.value) {
              lomakeData.bic = bic;
              state.bic = bic;
              commit("setBic", state.bic);
            }
          }
        }
        const lahetetaanko = await Vue.swal({
          title:
            "Oletko varma, että haluat lähettää nämä tilitiedot liikasuorituksen saajaksi?",
          showCancelButton: true,
          focusCancel: true,
          confirmButtonText: "Lähetetään",
          cancelButtonText: "Peruuta"
        });
        if (!lahetetaanko.value) {
          return false;
        }
        await dispatch("lahetaLiikasuoritusTiedot", lomakeData);
        return true;
      },
      async lahetaLiikasuoritusTiedot({ getters, dispatch }, lomakeData) {
        let url = "";
        if (lomakeData.toimeksianto && lomakeData.toimeksianto.id > 0) {
          url = `toimeksiannot/${lomakeData.toimeksianto.id}/liikasuoritus_tiedot/`;
        } else if (lomakeData.lasku && lomakeData.lasku.id > 0) {
          url = `laskut/${lomakeData.lasku.id}/liikasuoritus_tiedot/`;
        }
        if (lomakeData.kohdistusvaihtoehto > 0) {
          await this._vm.$api.withAuth.request({
            method: "POST",
            url: getters.maaritaUrl(url),
            data: {
              kohdistusvaihtoehto: true
            }
          });
        } else {
          await this._vm.$api.withAuth.request({
            method: "POST",
            url: getters.maaritaUrl(url),
            data: {
              iban: lomakeData.tilinumero,
              bic: lomakeData.bic,
              nimi: lomakeData.tilinOmistajanNimi
            }
          });
        }
        await dispatch("yleinen/haeLiikasuoritusYhteenveto", null, {
          root: true
        });
      },
      async lahetaKohdistusLiikasuoritus({ dispatch }, lomakeData) {
        const lahetetaanko = await Vue.swal({
          title:
            "Oletko varma, että haluat kohdistaa liikasuorituksen saatavalle " +
            lomakeData.liikasuoritus.kohdeasia_id +
            "?",
          showCancelButton: true,
          focusCancel: true,
          confirmButtonText: "Kohdistetaan",
          cancelButtonText: "Peruuta"
        });
        if (!lahetetaanko.value) {
          return false;
        }
        await dispatch("lahetaLiikasuoritusTiedot", lomakeData);
        return true;
      },
      async selvitaIBANistaBIC({ state }, IBANtilinumero) {
        const BICMap = {
          FI: {
            405: "HELSFIHH",
            497: "HELSFIHH",
            717: "BIGKFIH1",
            470: "POPFFI22",
            471: "POPFFI22",
            472: "POPFFI22",
            473: "POPFFI22",
            474: "POPFFI22",
            475: "POPFFI22",
            476: "POPFFI22",
            477: "POPFFI22",
            478: "POPFFI22",
            479: "POPFFI22",
            713: "CITIFIHX",
            715: "ITELFIHH",
            400: "ITELFIHH",
            402: "ITELFIHH",
            403: "ITELFIHH",
            406: "ITELFIHH",
            407: "ITELFIHH",
            408: "ITELFIHH",
            410: "ITELFIHH",
            411: "ITELFIHH",
            412: "ITELFIHH",
            414: "ITELFIHH",
            415: "ITELFIHH",
            416: "ITELFIHH",
            417: "ITELFIHH",
            418: "ITELFIHH",
            419: "ITELFIHH",
            420: "ITELFIHH",
            421: "ITELFIHH",
            423: "ITELFIHH",
            424: "ITELFIHH",
            425: "ITELFIHH",
            426: "ITELFIHH",
            427: "ITELFIHH",
            428: "ITELFIHH",
            429: "ITELFIHH",
            430: "ITELFIHH",
            431: "ITELFIHH",
            432: "ITELFIHH",
            435: "ITELFIHH",
            436: "ITELFIHH",
            437: "ITELFIHH",
            438: "ITELFIHH",
            439: "ITELFIHH",
            440: "ITELFIHH",
            441: "ITELFIHH",
            442: "ITELFIHH",
            443: "ITELFIHH",
            444: "ITELFIHH",
            445: "ITELFIHH",
            446: "ITELFIHH",
            447: "ITELFIHH",
            448: "ITELFIHH",
            449: "ITELFIHH",
            450: "ITELFIHH",
            451: "ITELFIHH",
            452: "ITELFIHH",
            454: "ITELFIHH",
            455: "ITELFIHH",
            456: "ITELFIHH",
            457: "ITELFIHH",
            458: "ITELFIHH",
            459: "ITELFIHH",
            460: "ITELFIHH",
            461: "ITELFIHH",
            462: "ITELFIHH",
            463: "ITELFIHH",
            464: "ITELFIHH",
            483: "ITELFIHH",
            484: "ITELFIHH",
            485: "ITELFIHH",
            486: "ITELFIHH",
            487: "ITELFIHH",
            488: "ITELFIHH",
            489: "ITELFIHH",
            490: "ITELFIHH",
            491: "ITELFIHH",
            492: "ITELFIHH",
            493: "ITELFIHH",
            495: "ITELFIHH",
            496: "ITELFIHH",
            798: "VPAYFIH2",
            711: "BSUIFIHH",
            799: "HOLVFIHH",
            34: "DABAFIHX",
            37: "DNBAFIHX",
            31: "HANDFIHH",
            33: "ESSEFIHH",
            36: "SBANFIHH",
            39: "SBANFIHH",
            38: "SWEDFIHH",
            6: "AABAFI22",
            4: "HELSFIHH",
            8: "DABAFIHH",
            1: "NDEAFIHH",
            2: "NDEAFIHH",
            5: "OKOYFIHH"
          }
        };
        if (!IBANtilinumero) return false;
        IBANtilinumero = IBANtilinumero.trim().replace(" ", "");
        const country = IBANtilinumero.substring(0, 2);
        const nro = IBANtilinumero.substring(4);
        let BICMapCountry = BICMap;
        if (country in BICMapCountry) {
          let BICMapArray = Object.entries(BICMapCountry[country]).sort(
            reverseSortObjectByNumberKeys
          );
          for (const [tunnus, bic] of BICMapArray) {
            if (nro.startsWith(tunnus)) {
              return bic;
            }
          }
        }
        return "";
      }
    };
  }
}
