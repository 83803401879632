<template>
  <v-container class="view-layout pt-6">
    <v-row>
      <v-col cols="12" sm="0" md="2" class="pa-0"></v-col>
      <v-col cols="12" sm="12" md="8">
        <v-row class="mb-4"></v-row>
        <v-row justify="center">
          <v-col>
            <slot name="default" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="0" md="2" class="pa-0"></v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ViewLayout"
};
</script>

<style></style>
