var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "omatTiedotLomake",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-row",
        { staticClass: "my-2 mx-0 app-color--grey" },
        [
          _c("div", { staticClass: "mb-4" }, [
            _vm._v(" " + _vm._s(_vm.kayttajaNimi) + " "),
          ]),
          _c("v-text-field", {
            attrs: {
              label: "Puhelin",
              rules: [_vm.$validointiSaannot.puhelinnumero],
              required: "false",
            },
            model: {
              value: _vm.omatTiedotLomakeData.puhelin.numero,
              callback: function ($$v) {
                _vm.$set(_vm.omatTiedotLomakeData.puhelin, "numero", $$v)
              },
              expression: "omatTiedotLomakeData.puhelin.numero",
            },
          }),
          _c("v-text-field", {
            attrs: {
              label: "Sähköpostiosoite",
              rules: [
                _vm.$validointiSaannot.pakollinen,
                _vm.$validointiSaannot.email,
              ],
            },
            model: {
              value: _vm.omatTiedotLomakeData.emailosoite.email,
              callback: function ($$v) {
                _vm.$set(_vm.omatTiedotLomakeData.emailosoite, "email", $$v)
              },
              expression: "omatTiedotLomakeData.emailosoite.email",
            },
          }),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "ma-0 mt-0 py-4", attrs: { justify: "space-between" } },
        [
          _c(
            "v-btn",
            {
              attrs: {
                color: "secondary",
                rounded: "",
                loading: _vm.lataamassa,
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("sulje")
                },
              },
            },
            [_vm._v(" Peruuta ")]
          ),
          _c(
            "v-btn",
            {
              attrs: {
                type: "submit",
                color: "primary",
                rounded: "",
                disabled: !_vm.valid || _vm.lataamassa,
                loading: _vm.lataamassa,
              },
            },
            [_vm._v(" Tallenna ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }