var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.envMaaritysInstanceClass !== "ijcapital"
    ? _c(
        "v-form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submit.apply(null, arguments)
            },
          },
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          !_vm.onkoAvoinnaRajoissa
            ? _c(
                "v-tooltip",
                {
                  attrs: {
                    color: "transparent",
                    bottom: "",
                    "open-on-click": "",
                    "open-on-focus": "",
                    activator: `#activator${_vm.activatorRandomId}`,
                  },
                },
                [
                  _c(
                    "v-card",
                    {
                      staticClass:
                        "elevation-5 pa-5 ma-0 rounded-lg primary info",
                      attrs: {
                        width: _vm.$vuetify.breakpoint.xs ? "85vw" : 562,
                      },
                    },
                    [
                      _c(
                        "p",
                        { staticClass: "text-subtitle-1 mb-0" },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "mr-2", attrs: { color: "white" } },
                            [_vm._v("info")]
                          ),
                          _c("span", { staticClass: "white--text" }, [
                            _vm._v(
                              " Maksua ei voida suorittaa. Maksettavaa on " +
                                _vm._s(
                                  _vm.$n(Number(_vm.avoinna), "currency")
                                ) +
                                ". "
                            ),
                            _vm.avoinna < _vm.minSumma
                              ? _c("span", [
                                  _vm._v(
                                    " Pienin mahdollinen suoritus on " +
                                      _vm._s(
                                        _vm.$n(Number(_vm.minSumma), "currency")
                                      ) +
                                      ". "
                                  ),
                                ])
                              : _vm.avoinna > _vm.maxSumma
                              ? _c("span", [
                                  _vm._v(
                                    " Suurin mahdollinen suoritus on " +
                                      _vm._s(
                                        _vm.$n(Number(_vm.maxSumma), "currency")
                                      ) +
                                      ". "
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.toimeksiantoIdt.length ||
          _vm.laskuIdt.length ||
          (_vm.toimeksiantoId === null && _vm.laskuId === null)
            ? _c(
                "div",
                {
                  attrs: {
                    id: !_vm.onkoAvoinnaRajoissa
                      ? `activator${_vm.activatorRandomId}`
                      : "passiivinen-1",
                  },
                },
                [
                  _c("TapausKorttiBtn", {
                    attrs: {
                      teksti: "Maksa kaikki",
                      ikoni: "payment",
                      disabled: !_vm.onkoAvoinnaRajoissa,
                    },
                    on: { click: _vm.maksaKaikki },
                  }),
                ],
                1
              )
            : _c(
                "div",
                {
                  attrs: {
                    id:
                      !_vm.onkoAvoinnaRajoissa && !_vm.onkoAvoinnaEraRajoissa
                        ? `activator${_vm.activatorRandomId}`
                        : "passiivinen-2",
                  },
                },
                [
                  _c("v-menu", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on }) {
                            return [
                              _c(
                                "TapausKorttiBtn",
                                _vm._g(
                                  {
                                    ref: "button",
                                    attrs: {
                                      teksti: "Maksa",
                                      ikoni: "payment",
                                      disabled:
                                        !_vm.onkoAvoinnaRajoissa &&
                                        !_vm.onkoAvoinnaEraRajoissa,
                                    },
                                  },
                                  _vm.avoinnaEra > 0 ? on : { click: _vm.maksa }
                                )
                              ),
                            ]
                          },
                        },
                        {
                          key: "default",
                          fn: function () {
                            return [
                              _c(
                                "v-list",
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      attrs: {
                                        disabled: !_vm.onkoAvoinnaRajoissa,
                                      },
                                      on: { click: _vm.maksa },
                                    },
                                    [
                                      _c(
                                        "v-list-item-avatar",
                                        [_c("v-icon", [_vm._v("reorder")])],
                                        1
                                      ),
                                      _c("v-list-item-title", [
                                        _vm._v("Maksa kokonaan"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm.avoinnaEra > 0
                                    ? _c(
                                        "v-list-item",
                                        {
                                          attrs: {
                                            disabled:
                                              !_vm.onkoAvoinnaEraRajoissa,
                                          },
                                          on: { click: _vm.maksaEra },
                                        },
                                        [
                                          _c(
                                            "v-list-item-avatar",
                                            [_c("v-icon", [_vm._v("segment")])],
                                            1
                                          ),
                                          _c("v-list-item-title", [
                                            _vm._v("Maksa erä"),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      915172005
                    ),
                  }),
                ],
                1
              ),
        ],
        1
      )
    : _c("div")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }