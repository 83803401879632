import Vue from "vue";
import VueRouter from "vue-router";
import Avoimet from "../views/Avoimet.vue";
import Kirjaudu from "../views/Kirjaudu.vue";
import KirjauduKirjeella from "../views/KirjauduKirjeella.vue";
import KirjauduUudelleenohjaus from "../views/KirjauduUudelleenohjaus.vue";
import Yhteenveto from "../views/Yhteenveto.vue";
import Liikasuoritukset from "../views/Liikasuoritukset.vue";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/kirjaudu",
    name: "Kirjaudu",
    component: Kirjaudu,
    meta: {
      piilotaNavBar: true,
      requiresAuth: false
    }
  },
  {
    path: "/",
    name: "Kirjaudutaan",
    component: KirjauduUudelleenohjaus,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/yhteenveto",
    name: "Yhteenveto",
    component: Yhteenveto
  },
  {
    path: "/avoimet",
    name: "Avoimet asiat",
    component: Avoimet,
    meta: {
      kirjeAuthSallittu: true
    }
  },
  {
    path: "/liikasuoritukset",
    name: "Liikasuoritukset",
    component: Liikasuoritukset,
    meta: {
      kirjeAuthSallittu: true
    }
  },
  {
    path: "/viestit",
    name: "Viestit",
    component: () =>
      import(/* webpackChunkName: "viestit" */ "../views/Viestit.vue"),
    meta: {
      kirjeAuthSallittu: true
    }
  },
  {
    path: "/saldotodistus",
    name: "Saldotodistus",
    component: () =>
      import(
        /* webpackChunkName: "saldotodistus" */ "../views/Saldotodistus.vue"
      )
  },
  {
    path: "/arkisto",
    name: "Arkisto",
    component: () =>
      import(/* webpackChunkName: "arkisto" */ "../views/Arkisto.vue"),
    meta: {
      kirjeAuthSallittu: true
    }
  },
  {
    path: "/paytrail_success",
    name: "Maksu onnistui",
    component: () =>
      import(/* webpackChunkName: "paytrail" */ "../views/PaytrailSuccess.vue"),
    meta: {
      kirjeAuthSallittu: true
    }
  },
  {
    path: "/paytrail_cancel",
    name: "Maksu epäonnistui",
    component: () =>
      import(/* webpackChunkName: "paytrail" */ "../views/PaytrailCancel.vue"),
    meta: {
      kirjeAuthSallittu: true
    }
  }
];

const kirjeRoutet = [
  {
    path: "/kirje",
    name: "Kirjaudu kirjeellä",
    component: KirjauduKirjeella,
    meta: {
      piilotaNavBar: true,
      requiresAuth: false
    }
  },
  {
    path: "/k",
    name: "Kirjaudu kirjeellä",
    component: KirjauduKirjeella,
    meta: {
      piilotaNavBar: true,
      requiresAuth: false
    }
  }
];

routes.push(...kirjeRoutet);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  asetaDokumentinOtsake(to.name);

  if (
    typeof to.meta.requiresAuth === "undefined" ||
    to.meta.requiresAuth === true
  ) {
    // Kirjeellä kirjautuminen
    if (
      to.meta.kirjeAuthSallittu &&
      store.getters["yleinen/kirjauduttuKirjeella"]()
    ) {
      next();
      return;
    }

    // Ohjataan käyttäjä kirjautumissivulle, jos ei ole tokenia
    if (
      !localStorage.getItem("accessToken") ||
      !localStorage.getItem("refreshToken")
    ) {
      if (process.env.VUE_APP_ENV === "development") {
        next();
      } else {
        next({
          name: "Kirjaudu",
          replace: true
        });
      }
      // Onnistunut valtuutettu pyyntö
    } else {
      if (!store.state.yleinen.tunnistettuKayttajaNimi)
        store.dispatch("yleinen/haeTunnistettuKayttajaNimi");
      if (store.state.yleinen.velallistiedot === null)
        store.dispatch("yleinen/haeVelallistiedot");
      store.dispatch("viestit/haeLukemattomatKpl");

      next();
    }
  } else {
    next();
  }
});

const asetaDokumentinOtsake = (reitinOtsake) => {
  let title = typeof reitinOtsake !== "undefined" ? reitinOtsake + " - " : "";
  title += process.env.VUE_APP_INSTANCE_TITLE;
  document.title = title;
};

export default router;
