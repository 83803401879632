import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const numberFormats = {
  // 'en-US': {
  //   currency: {
  //     style: 'currency',
  //     currency: 'EUR'
  //   }
  // },
  "fi-FI": {
    currency: {
      style: "currency",
      currency: "EUR"
    }
  }
};

const i18n = new VueI18n({
  numberFormats,
  fallbackLocale: "fi-FI"
});

export default i18n;
