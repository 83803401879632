<template>
  <CardDialog
    :sisalto-class="sisaltoClass"
    class="px-0 py-0 app-height-100-pct"
    :auki="auki"
    width="1200"
    :max-width="'98vw'"
    @sulje="$emit('sulje')">
    <div class="pdf-modal">
      <pdf
        :annotation="true"
        :src="paikallinenUrl"
        style="width: 100%"
        :scale.sync="scale"
        :page="nykyinenSivu"
        @numpages="kasitteleNumpages"
        @loading="asetaLataamassa"
        @error="kasitteleError"></pdf>
      <p
        v-if="pdfLataamassa"
        class="mt-n12 text-body-1 text-center app-animoitu-gradientti">
        Haetaan tietoja...
      </p>
    </div>
    <v-row
      v-if="!pdfLataamassa"
      justify="center"
      class="px-0 mt-2 mx-auto mb-0">
      <v-btn icon class="mt-1" @click="pienennaSivunumeroa">
        <v-icon color="primary">remove_circle</v-icon>
      </v-btn>
      <v-text-field
        v-model.number="nykyinenSivu"
        class="pdf-modal__input pa-0"
        type="number"
        :suffix="`/ ${sivujaYhtTeksti}`"
        hide-details />
      <v-btn icon class="mt-1" @click="kasvataSivunumeroa">
        <v-icon color="primary">add_circle</v-icon>
      </v-btn>
    </v-row>
    <v-row
      :justify="$vuetify.breakpoint.smAndDown ? 'space-between' : 'end'"
      class="px-0 pb-4 mx-auto mb-0"
      :class="$vuetify.breakpoint.smAndDown ? 'mt-n8' : 'mt-n9'">
      <v-btn
        class="mr-4"
        color="primary"
        :small="$vuetify.breakpoint.smAndDown"
        rounded
        @click="lataaPdf">
        Lataa
      </v-btn>
      <v-btn
        color="primary"
        :small="$vuetify.breakpoint.smAndDown"
        rounded
        @click="$emit('sulje')">
        Sulje
      </v-btn>
    </v-row>
  </CardDialog>
</template>

<script>
import pdfvuer from "pdfvuer";
import "pdfjs-dist/build/pdf.worker.entry";

export default {
  name: "PdfDialog",
  components: {
    pdf: pdfvuer
  },
  props: {
    auki: {
      type: Boolean,
      required: true
    },
    paikallinenUrl: {
      type: String,
      required: true
    },
    tiedostonNimi: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      nykyinenSivu: 1,
      sivujaYht: 1,
      pdfLataamassa: true,
      scale: "page-width"
    };
  },
  computed: {
    sivujaYhtTeksti() {
      return this.sivujaYht === undefined || this.sivujaYht === "undefined"
        ? "?"
        : this.sivujaYht;
    },
    sisaltoClass() {
      return ["pb-0", this.$vuetify.breakpoint.smAndDown ? "px-2" : "px-8"];
    }
  },
  methods: {
    kasitteleError(e) {
      this.$sentryLog(e);
    },
    kasvataSivunumeroa() {
      if (this.nykyinenSivu < this.sivujaYht) this.nykyinenSivu++;
    },
    pienennaSivunumeroa() {
      if (this.nykyinenSivu > 1) this.nykyinenSivu--;
    },
    asetaLataamassa(arvo) {
      this.pdfLataamassa = arvo;
    },
    kasitteleNumpages(arvo) {
      this.sivujaYht = arvo;
    },
    lataaPdf() {
      let blob = null;
      const xhr = new XMLHttpRequest();
      xhr.open("GET", this.paikallinenUrl);
      xhr.responseType = "blob";
      xhr.onload = () => {
        blob = xhr.response;
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = this.tiedostonNimi;
        link.click();
        URL.revokeObjectURL(link.href);
      };
      xhr.send();
    }
  }
};
</script>

<style src="pdfvuer/dist/pdfvuer.css"></style>
<style lang="scss">
.pdf-modal {
  height: calc(let(--vh, 1vh) * 90 - 140px);

  &__input {
    width: 36px;
    flex: 0 1 auto !important;
  }
}
</style>
