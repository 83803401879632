const TILA_VAPAAEHTOINEN_PERINTA = "VP";
const TILA_OIKEUDELLINEN_PERINTA = "OP";
const TILA_ULOSOTTOPERINTA = "UO";
const TILA_LOPPUUN_PERITTY = "LO";
const TILA_MYYTY = "MY";
const TILA_LOPETETTU = "XX";
const TILA_PERUTTU = "PE";

const Toimeksianto = class {
  static get TILA_VAPAAEHTOINEN_PERINTA() {
    return TILA_VAPAAEHTOINEN_PERINTA;
  }

  static get TILA_OIKEUDELLINEN_PERINTA() {
    return TILA_OIKEUDELLINEN_PERINTA;
  }

  static get TILA_ULOSOTTOPERINTA() {
    return TILA_ULOSOTTOPERINTA;
  }

  static get TILA_LOPPUUN_PERITTY() {
    return TILA_LOPPUUN_PERITTY;
  }

  static get TILA_MYYTY() {
    return TILA_MYYTY;
  }

  static get TILA_LOPETETTU() {
    return TILA_LOPETETTU;
  }

  static get TILA_PERUTTU() {
    return TILA_PERUTTU;
  }

  static get TILAT_PERINNASSA() {
    return [
      Toimeksianto.TILA_VAPAAEHTOINEN_PERINTA,
      Toimeksianto.TILA_OIKEUDELLINEN_PERINTA
    ];
  }

  static get TILAT_MAKSETTU() {
    return [Toimeksianto.TILA_LOPPUUN_PERITTY, Toimeksianto.TILA_MYYTY];
  }

  static get TILAT_PERUTTU() {
    return [Toimeksianto.TILA_LOPETETTU, Toimeksianto.TILA_PERUTTU];
  }
};

export default Toimeksianto;
