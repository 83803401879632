<template>
  <v-card
    :width="$vuetify.breakpoint.xs ? '85vw' : 562"
    class="tapaus-kortti tapaus-kortti--lasku rounded-lg rounded-t-xl pa-1"
    elevation="5">
    <!-- Dialogit  -->
    <div v-if="vuexModuuli !== 'liikasuoritukset'">
      <CardDialog
        otsikko="Eräpäivän siirtopyyntö"
        :auki="erapaivanSiirtoLomakeAuki"
        :width="$vuetify.breakpoint.xs ? '85vw' : '320px'"
        @sulje="erapaivanSiirtoLomakeAuki = false">
        <ErapaivanSiirtoLomake
          :lomake-data="erapaivanSiirtoLomakeData"
          :lataamassa="erapaivanSiirtoLomakeLataamassa"
          tapaustyyppi="laskut"
          @submit="laskutLahetaErapaivanSiirtopyynto" />
      </CardDialog>

      <MenuContext
        :onko-nakyvissa="menuContextNakyvissa"
        :position-x="menuContextX"
        :position-y="menuContextY"
        :vaihtoehdot="menuContextKirjeet"
        @update:return-value="suljeMenuContext"
        @lataaKirje="haeLaskuliite" />
    </div>
    <CardDialog
      v-else-if="vuexModuuli === 'liikasuoritukset'"
      otsikko="Liikasuoritus"
      :auki="laskuLiikasuoritusLomakeAuki"
      :width="$vuetify.breakpoint.xs ? '85vw' : '320px'"
      @sulje="laskuLiikasuoritusLomakeAuki = false">
      <LiikasuoritusLomake
        v-if="
          !laskuLiikasuoritusLomakeData.liikasuoritus ||
          !laskuLiikasuoritusLomakeData.liikasuoritus.kohdeasia_id ||
          laskuLiikasuoritusLomakeData.liikasuoritus.kohdeasia_id === '0'
        "
        :lomake-data="laskuLiikasuoritusLomakeData"
        :lataamassa="laskuLiikasuoritusLomakeLataamassa"
        tapaustyyppi="laskut"
        @lahetaLiikasuoritus="lahetaLiikasuoritus" />
      <LiikasuorituskohdistusLomake
        v-else
        :lomake-data="laskuLiikasuoritusLomakeData"
        :lataamassa="laskuLiikasuoritusLomakeLataamassa"
        tapaustyyppi="laskut"
        @lahetaLiikasuoritus="lahetaLiikasuoritus"
        @lahetaKohdistusLiikasuoritus="lahetaKohdistusLiikasuoritus" />
    </CardDialog>

    <!-- Kortin sisältö -->
    <v-row class="justify-space-between">
      <v-col cols="9" class="pb-0">
        <v-card-title class="pb-0">
          <h5>
            Lasku:
            <span class="app-overflow-break-word">{{ lasku.nro }}</span>
          </h5>
        </v-card-title>
      </v-col>
      <v-col cols="3" class="pb-0">
        <v-tooltip top open-on-click open-on-focus>
          <template #activator="{ on }">
            <v-avatar
              class="mx-4 mt-4 float-right"
              :color="ikoni.variAvatar"
              size="52"
              v-on="on">
              <v-icon :color="ikoni.vari" large>
                {{ ikoni.nimi }}
              </v-icon>
            </v-avatar>
          </template>
          <template #default>
            {{ tilaUi }} {{ myohassa ? " (myöhässä)" : "" }}
          </template>
        </v-tooltip>
      </v-col>
      <v-col cols="12" class="py-0 my-0">
        <v-card-subtitle class="pt-0">
          Laskuttaja: {{ lasku.paamies.nimi }}
        </v-card-subtitle>
      </v-col>
    </v-row>
    <v-divider class="mx-4 my-2" />
    <v-list>
      <v-list-item>
        <v-list-item-title class="tapaus-kortti__list-item__title">
          Eräpäivä
        </v-list-item-title>
        <v-list-item-subtitle
          class="tapaus-kortti__list-item__subtitle text-right">
          {{ $date(new Date(lasku.erapaiva)) }}
        </v-list-item-subtitle>
      </v-list-item>
      <v-list-item>
        <v-list-item-title class="tapaus-kortti__list-item__title">
          Asian tila
        </v-list-item-title>
        <v-list-item-subtitle
          class="tapaus-kortti__list-item__subtitle text-right">
          {{ tilaUi }} {{ myohassa ? " (myöhässä)" : "" }}
        </v-list-item-subtitle>
      </v-list-item>
      <v-list-item>
        <v-list-item-title class="tapaus-kortti__list-item__title">
          Avoinna
        </v-list-item-title>
        <v-list-item-subtitle
          class="tapaus-kortti__list-item__subtitle text-right">
          {{ $n(Number(lasku.avoinna), "currency") }}
        </v-list-item-subtitle>
      </v-list-item>

      <v-list-item>
        <v-divider />
      </v-list-item>
      <v-list-item
        v-if="vuexModuuli === 'liikasuoritukset'"
        class="my-4"
        :class="$vuetify.breakpoint.xs ? 'justify-center' : 'justify-end'">
        <TapausKorttiTooltipBtn
          v-if="
            lasku.liikasuoritustila === yleinen.liikasuoritustilaTiedotPuuttuu
          "
          teksti="Palauta tilitiedot"
          ikoni="send"
          :disabled="lasku.disabled"
          tooltip-teksti="Tästä avautuu lomake, jolla voit antaa palautukseen tarvittavat tiedot"
          @click="avaaLiikasuoritus(lasku.menuContextLiikasuoritukset[0])" />
        <span
          v-if="
            lasku.liikasuoritustila === yleinen.liikasuoritustilaKasittelyssa
          ">
          Tilitiedot palautettu. Rahat palautetaan 1-2 viikon kuluttua.
        </span>
      </v-list-item>
      <template v-if="tilaUi === AVOINNA && vuexModuuli !== 'liikasuoritukset'">
        <v-list-item
          class="my-4"
          :class="$vuetify.breakpoint.xs ? 'justify-center' : 'justify-end'">
          <TapausKorttiBtn
            teksti="Liitteet"
            ikoni="download"
            :disabled="!onLaskuLiitteita"
            @click="avaaMenuContext" />
        </v-list-item>
        <v-list-item
          class="my-4"
          :class="$vuetify.breakpoint.xs ? 'justify-center' : 'justify-end'">
          <TapausKorttiBtn
            :teksti="
              erapaivaSiirrettavissa
                ? 'Siirrä eräpäivää'
                : 'Eräpäivää ei voi siirtää'
            "
            :ikoni="erapaivaSiirrettavissa ? 'arrow_right_alt' : ''"
            :disabled="!erapaivaSiirrettavissa"
            @click="erapaivanSiirtoLomakeAuki = true" />
        </v-list-item>
        <v-list-item
          class="my-4"
          :class="$vuetify.breakpoint.xs ? 'justify-center' : 'justify-end'">
          <PaytrailLomake
            v-if="!(envMaaritysInstanceClass === 'creditvisor')"
            :lasku-id="lasku.id"
            :avoinna="Number(lasku.avoinna)" />
        </v-list-item>
        <v-list-item
          class="my-4"
          :class="$vuetify.breakpoint.xs ? 'justify-center' : 'justify-end'">
          <TapausKorttiBtn
            teksti="Uusi viesti"
            ikoni="message"
            @click="siirryViestitValilehdelle" />
        </v-list-item>
      </template>
    </v-list>
    <div
      class="tapaus-kortti__vari-ilmaisin mt-4"
      :class="variIlmaisinLuokka"></div>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import Lasku from "@/classes/Lasku";
import ErapaivanSiirtoLomake from "@/components/ErapaivanSiirtoLomake";
import LiikasuoritusLomake from "@/components/LiikasuoritusLomake";
import LiikasuorituskohdistusLomake from "@/components/LiikasuorituskohdistusLomake";
import PaytrailLomake from "@/components/PaytrailLomake";
import TapausKorttiBtn from "@/components/TapausKorttiBtn";
import TapausKorttiTooltipBtn from "@/components/TapausKorttiTooltipBtn";
import { differenceInDays } from "date-fns";
import MenuContext from "@/components/MenuContext";
import MenuContextMixin from "@/mixins/MenuContextMixin";

const AVOINNA = "Avoinna";
const MAKSETTU = "Maksettu";
const PERUTTU = "Peruttu";

export default {
  name: "LaskuKortti",
  components: {
    ErapaivanSiirtoLomake,
    LiikasuoritusLomake,
    LiikasuorituskohdistusLomake,
    MenuContext,
    PaytrailLomake,
    TapausKorttiBtn,
    TapausKorttiTooltipBtn
  },
  mixins: [MenuContextMixin],
  props: {
    lasku: {
      type: Object,
      required: true
    },
    vuexModuuli: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      AVOINNA,
      MAKSETTU,
      PERUTTU,
      laskuLiikasuoritusLomakeAuki: false,
      laskuLiikasuoritusLomakeData: { lasku: this.lasku },
      laskuLiikasuoritusLomakeLataamassa: false,
      erapaivanSiirtoLomakeAuki: false,
      erapaivanSiirtoLomakeData: { laskuId: this.lasku.id },
      erapaivanSiirtoLomakeLataamassa: false
    };
  },
  computed: {
    ...mapState(["yleinen", "liikasuoritukset"]),
    envMaaritysInstanceClass() {
      return process.env.VUE_APP_INSTANCE_CLASS || "";
    },
    ikoni() {
      const ikoni = {
        vari: "white",
        variAvatar: "primary",
        nimi: "article"
      };

      if (this.tilaUi === AVOINNA) {
        ikoni.nimi = "article";
        ikoni.variAvatar = "primary";
      } else if (this.tilaUi === MAKSETTU) {
        ikoni.nimi = "check_circle";
        ikoni.variAvatar = "success";
      } else if (this.tilaUi === PERUTTU) {
        ikoni.nimi = "cancel";
        ikoni.variAvatar = "grey darken-3";
      }

      return ikoni;
    },
    tilaUi() {
      const tila = this.lasku.tila;
      if (Lasku.TILAT_AVOINNA.includes(tila)) {
        return AVOINNA;
      } else if (Lasku.TILAT_MAKSETTU.includes(tila)) {
        return MAKSETTU;
      } else if (tila === Lasku.TILA_PERUTTU) {
        return PERUTTU;
      } else {
        return AVOINNA;
      }
    },
    variIlmaisinLuokka() {
      return this.myohassa
        ? "tapaus-kortti__vari-ilmaisin--lasku--myohassa"
        : "";
    },
    menuContextKirjeet() {
      if (!this.lasku || !this.lasku.laskuliite_set) {
        return [
          {
            nimi: "Ei laskuliitteitä"
          }
        ];
      }
      const liiteSet = this.lasku.laskuliite_set.map((liite) => {
        return {
          icon: "download",
          id: liite.id,
          luotu: liite.luotu,
          nimi: liite.id + liite.nimi,
          liiteNimi: liite.nimi,
          event: "lataaKirje"
        };
      });
      if (liiteSet.length) {
        if (liiteSet.length > 1) {
          return this.menuContextRakennaMoniKirjeSet(liiteSet);
        } else {
          return liiteSet;
        }
      } else {
        return [
          {
            nimi: "Ei laskuliitteitä"
          }
        ];
      }
    },
    myohassa() {
      const tanaan = new Date();
      const erapaiva = new Date(this.lasku.erapaiva);
      const tilaUi = this.tilaUi;
      try {
        return (
          tilaUi === AVOINNA &&
          tanaan > erapaiva &&
          differenceInDays(tanaan, erapaiva) >= 1
        );
      } catch (e) {
        return false;
      }
    },
    onLaskuLiitteita() {
      return (
        this.lasku &&
        this.lasku.laskuliite_set &&
        this.lasku.laskuliite_set.length > 0
      );
    },
    erapaivaSiirrettavissa() {
      return (
        this.lasku.onko_erapaivan_siirto_mahdollinen &&
        !this.lasku.paamies.esta_laskun_erapaivan_siirto_portaali
      );
    }
  },
  methods: {
    siirryViestitValilehdelle() {
      const avoinAsia = {
        laskuVaiToimeksianto: "L",
        id: this.lasku.id,
        nro: this.lasku.nro
      };
      this.$store.commit("yleinen/asetaAvoinAsia", avoinAsia);
      this.$emit("uusiViesti");
    },
    async lahetaLiikasuoritus() {
      this.laskuLiikasuoritusLomakeLataamassa = true;
      try {
        const lahettiinko = await this.$store.dispatch(
          "liikasuoritukset/lahetaLiikasuoritus",
          this.laskuLiikasuoritusLomakeData
        );
        this.laskuLiikasuoritusLomakeData.bic = this.liikasuoritukset.bic;
        if (!lahettiinko) {
          return;
        }
        this.laskuLiikasuoritusLomakeAuki = false;
        this.$naytaOnnistumisilmoitus("Liikasuorituksen tiedot lähetetty.");
        this.$emit("alustaListaus");
      } catch (e) {
        this.$oletusVirheenkasittely(e);
      } finally {
        this.laskuLiikasuoritusLomakeLataamassa = false;
      }
    },
    async lahetaKohdistusLiikasuoritus() {
      this.laskuLiikasuoritusLomakeLataamassa = true;
      try {
        this.laskuLiikasuoritusLomakeData.kohdistusvaihtoehto =
          this.laskuLiikasuoritusLomakeData.liikasuoritus.kohdeasia_id;
        const lahettiinko = await this.$store.dispatch(
          "liikasuoritukset/lahetaKohdistusLiikasuoritus",
          this.laskuLiikasuoritusLomakeData
        );
        if (!lahettiinko) {
          return;
        }
        this.laskuLiikasuoritusLomakeAuki = false;
        this.$naytaOnnistumisilmoitus("Liikasuorituksen tiedot lähetetty.");
        this.$emit("alustaListaus");
      } catch (e) {
        this.$oletusVirheenkasittely(e);
      } finally {
        this.laskuLiikasuoritusLomakeLataamassa = false;
      }
    },
    avaaLiikasuoritus(liikasuoritus) {
      try {
        this.laskuLiikasuoritusLomakeData.liikasuoritus = liikasuoritus;
        this.laskuLiikasuoritusLomakeAuki = true;
      } catch (e) {
        this.$oletusVirheenkasittely(e);
      }
    },
    menuContextRakennaMoniKirjeSet(kirjeSet) {
      kirjeSet.sort((a, b) => {
        let tulos = 0;
        const dateA = new Date(a.luotu);
        const dateB = new Date(b.luotu);
        if (dateA < dateB) tulos = 1;
        else if (dateA > dateB) tulos = -1;
        return tulos;
      });
      return kirjeSet;
    },
    async haeLaskuliite(kirje) {
      try {
        const response = await this.$store.dispatch(
          "avoimetAsiat/haeLaskuliite",
          kirje.id
        );
        this.$naytaTiedostonlataamisdialog(response, `${kirje.liiteNimi}.pdf`);
      } catch (e) {
        this.$oletusVirheenkasittely(e);
      }
    },
    async laskutLahetaErapaivanSiirtopyynto() {
      this.erapaivanSiirtoLomakeLataamassa = true;
      try {
        await this.$store.dispatch(
          "avoimetAsiat/laskutLahetaErapaivanSiirtopyynto",
          this.erapaivanSiirtoLomakeData
        );
        this.erapaivanSiirtoLomakeAuki = false;
        this.$naytaOnnistumisilmoitus("Laskun eräpäivää siirretty.");
        this.$emit("alustaListaus");
      } catch (e) {
        this.$oletusVirheenkasittely(e);
      } finally {
        this.erapaivanSiirtoLomakeLataamassa = false;
      }
    }
  }
};
</script>

<style lang="scss">
.swal2-title {
  font-family: Georgia, serif;
  font-size: 0.9375rem !important;
}
.swal2-popup {
  font-family: Georgia, serif;
  font-size: 0.9375rem !important;
}
</style>
